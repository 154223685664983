/* eslint-disable no-alert */
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import React, { useState } from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import ReactSelect, { ValueType } from 'react-select';
import { Button, Checkbox, MenuItem, Select, SwipeableDrawer } from '@material-ui/core';
import { Sync } from '@material-ui/icons';
import { Spinner } from 'react-activity';
import { toast } from 'react-hot-toast';
import { FineDetailDto, FinePaymentStatus, Guid, ProfileType } from '../../../types';

import { adminFineService, adminInvoiceService } from '../../../services';
import {
  useRemoveFineFromProfile,
  useResponsibleOffenderIdentifiers,
} from '../../../hooks/profile-fines';
import { useSyncProfileFines } from '../../../react-queries/admin-fines-data/admin-sync-fines.queries';
import useProfileFinesTable from '../../../hooks/profile-fines/use-profile-fines-table';

import { EditFineFormWithFetch } from '../edit-fine-form-with-fetch/edit-fine-form-with-fetch';
import { ActivityLoader } from '../../atoms/activity-loader/activity-loader';
import { SearchField } from '../../atoms';

export const ProfileFines = ({
  userId,
  profileType,
  businessEntityId,
}: {
  userId: Guid;
  profileType: ProfileType;
  businessEntityId: Guid;
}) => {
  const history = useHistory();

  const queryClient = useQueryClient();

  const createInvoice = (individualId?: string, fleetEntityId?: string, fineIds?: string[]) => {
    adminInvoiceService.createInvoice(individualId, fleetEntityId, fineIds).then((result) => {
      history.push(`/admin/individual/invoice?Id=${result}&userId=${userId}`);
    });
  };

  const [editFineOpen, setEditFineOpen] = useState(false);
  const [activeFine, setActiveFine] = useState<Row<FineDetailDto>>();

  const _editFine = (formData) => {
    return adminFineService.editFine(activeFine?.id, formData);
  };
  const toggleFineTableData = (open: boolean, selectedFine) => {
    setEditFineOpen(!editFineOpen);
    setActiveFine(selectedFine);
  };

  const removeFineFromProfile = useRemoveFineFromProfile();
  const deleteFine = async (id: Guid) => {
    if (window.confirm('Are you sure you wish to delete this item?')) {
      await removeFineFromProfile.mutateAsync([id]);
    }
  };
  const deleteFines = async (ids: Guid[]) => {
    if (window.confirm('Are you sure you wish to delete the selected items?')) {
      await removeFineFromProfile.mutateAsync(ids);
    }
  };

  const syncProfileFines = useSyncProfileFines(userId);

  const {
    finesQuery,
    table,
    filter: {
      offenderIdentifiers,
      finePaymentStatus,
      vehicleRegistrations,
      noticeNumberSearch,
      isArchived,
    },
  } = useProfileFinesTable({
    onEdit: (row) => toggleFineTableData(true, row),
    onDelete: (row) => deleteFine(row.id),
    useOffenderIdentifiers: () =>
      useResponsibleOffenderIdentifiers(userId, profileType, businessEntityId),
    profileId: profileType === ProfileType.Individual ? userId : businessEntityId,
  });

  const onChangeNoticeNumberSearch = (value: string) => {
    table.setPageIndex(0);
    noticeNumberSearch.setNoticeNumberSearch(value);
  };

  return (
    <div className="flex flex-col flex-auto rounded-md shadow-md">
      <div className="flex bg-secondary rounded-t-md h-16 p-4 flex-row items-center font-bold text-white">
        <p className="text-white font-sans text-base font-bold">Fines</p>
        <SearchField
          placeholder="Notice Number"
          className="flex ml-5 h-9"
          debounceTime={1000}
          value={noticeNumberSearch.noticeNumberSearch}
          onChange={onChangeNoticeNumberSearch}
          isLoading={finesQuery.isFetching}
        />
        <div className="flex flex-row flex-end items-center gap-x-2 ml-auto">
          <ReactSelect
            isMulti
            placeholder={<div className="text-sm text-gray-600">Offender Identifier</div>}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            value={offenderIdentifiers.selected}
            className="text-black rounded-md w-52 h-9 no-underline"
            options={offenderIdentifiers.all}
            onChange={(selectedItems: ValueType<{ label: string; value: string }, true>) => {
              offenderIdentifiers.setSelected(selectedItems);
            }}
            styles={{
              valueContainer: (base) => ({
                ...base,
                maxHeight: 36,
                overflowY: 'auto',
              }),
            }}
          />
          <ReactSelect
            isMulti
            placeholder={<div className="text-sm text-gray-600">Vehicle Registration</div>}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            value={vehicleRegistrations.selected}
            className="text-black rounded-md w-52 h-9 no-underline"
            options={vehicleRegistrations.all}
            onChange={(selectedItems: ValueType<{ label: string; value: string }, true>) => {
              vehicleRegistrations.setSelected(selectedItems);
            }}
            styles={{
              valueContainer: (base) => ({
                ...base,
                maxHeight: 36,
                overflowY: 'auto',
              }),
            }}
          />

          <Select
            value={finePaymentStatus.status}
            onChange={(event) => {
              finePaymentStatus.setStatus(event.target.value as FinePaymentStatus);
            }}
            className="bg-white rounded-md h-9 no-underline md:mb-0"
            variant="outlined"
          >
            <MenuItem value={FinePaymentStatus.Unpaid}>Unpaid</MenuItem>
            <MenuItem value={FinePaymentStatus.Paid}>Paid</MenuItem>
            <MenuItem value={FinePaymentStatus.Other}>Other</MenuItem>
          </Select>
          <Button
            className="bg-tertiary text-white hover:bg-tertiary-dark"
            disabled={syncProfileFines.isLoading}
            startIcon={!syncProfileFines.isLoading && <Sync />}
            onClick={() => syncProfileFines.mutate()}
          >
            {syncProfileFines.isLoading ? <Spinner /> : 'Sync Fines'}
          </Button>
          <Button
            className="bg-tertiary text-white hover:bg-tertiary-dark"
            disabled={Object.keys(table.getState().rowSelection).length === 0}
            onClick={() =>
              createInvoice(
                profileType === 0 ? userId : null,
                profileType === 1 ? businessEntityId : null,
                Object.keys(table.getState().rowSelection),
              )
            }
          >
            Create Invoice
          </Button>
          <Button
            className="bg-tertiary text-white hover:bg-tertiary-dark"
            disabled={Object.keys(table.getState().rowSelection).length === 0}
            onClick={async () => {
              await deleteFines(Object.keys(table.getState().rowSelection));
              table.resetRowSelection();
            }}
          >
            Delete
          </Button>
        </div>
      </div>

      <div className="overflow-y-auto flex-overflow">
        <div className="flex items-center gap-2 sticky top-0 bg-white p-2">
          <button
            type="button"
            className="border rounded p-1"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            type="button"
            className="border rounded p-1"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            type="button"
            className="border rounded p-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            type="button"
            className="border rounded p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </strong>
          </span>
          <span className="flex items-center gap-1">
            | Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className="border p-1 rounded w-16"
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50, 100, 1000].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
          <ActivityLoader isLoading={finesQuery.isLoading || finesQuery.isFetching} />
          <Checkbox
            className="ml-auto -mr-2"
            checked={isArchived.isArchived}
            name="showIsArchived"
            id="showIsArchived"
            color="secondary"
            onChange={() => isArchived.setIsArchived(!isArchived.isArchived)}
          />
          <span className="mr-2">Show Archived</span>
        </div>

        {/* // Todo: Add bulk operations here.  */}
        {/* {table.getIsAllPageRowsSelected() && */}
        {/*  Object.keys(table.getState().rowSelection).length !== 0 && ( */}
        {/*    <div>Do you want to select all n fines?</div> */}
        {/*  )} */}
        <table>
          <thead className="text-sm">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="sticky top-12 bg-white p-2">
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>{flexRender(header.column.columnDef.header, header.getContext())}</>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="text-xs">
            {table.getRowModel().rows.map((row, rowIndex) => {
              return (
                <tr className="individualTableTr" key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={`max-w-xs overflow-x-auto whitespace-nowrap 
                        ${
                          cell.column.columnDef.header === 'Amnt Paid'
                            ? rowIndex % 2 === 0
                              ? 'bg-yellow'
                              : 'bg-yellow-dark'
                            : ''
                        }`}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <SwipeableDrawer
        anchor="right"
        open={editFineOpen}
        onClose={() => setEditFineOpen(false)}
        onOpen={() => setEditFineOpen(true)}
        className="w-96"
      >
        <EditFineFormWithFetch
          submitForm={_editFine}
          onSuccess={() => {
            setEditFineOpen(false);
            queryClient.invalidateQueries('paged-profile-fines');
          }}
          onFailure={() => {
            toast.error('error editing fine');
          }}
          fineId={activeFine?.id}
          isArchived={isArchived.isArchived}
        />
      </SwipeableDrawer>
    </div>
  );
};
