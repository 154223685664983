import ax, { AxiosResponse } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import formUrlEncoded from 'form-urlencoded';
import userAuthUrls from '../user-auth/user-auth.urls';
import userAuthUtils from '../user-auth/user-auth.utils';
import {
  createAttachTokenInterceptor,
  createNetworkErrorHandlerInterceptor,
} from '../../interceptors';
import { accessTokenOperations } from '../../token/token.service';
import userAuthService from '../user-auth/user-auth.service';

const authNetworkService = ax.create({
  timeout: 25000,
  headers: {
    Accept: 'application/json',
    'content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  responseType: 'json',
});

const refreshConfig = {
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

export const refreshTokenLogic = () => {
  const _tryToRefreshToken = (refreshOAuthData: Record<string, unknown>) => {
    const tokenUrl = userAuthUrls.tokenUrl();
    return ax.post(tokenUrl, formUrlEncoded(refreshOAuthData), refreshConfig);
  };

  const _storeNewTokens = (apiResponse: AxiosResponse<any>) =>
    userAuthUtils.storeAccessAndRefreshTokens(apiResponse);

  return Promise.resolve()
    .then(userAuthUtils.constructOAuthTokenRefreshData)
    .then(_tryToRefreshToken)
    .then(_storeNewTokens)
    .catch(async (error) => {
      // eslint-disable-next-line no-console
      console.warn(error.message);
      const result = userAuthService.logout();
      window.location.href = '/login';
      await result;
    });
};

createAttachTokenInterceptor(authNetworkService, accessTokenOperations.get);
createAuthRefreshInterceptor(authNetworkService, refreshTokenLogic);
createNetworkErrorHandlerInterceptor(authNetworkService);

export default authNetworkService;
