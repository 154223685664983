import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import { Button, FormContainer } from '../../../components';
import { useOnboardStatus } from '../../../react-queries';

const ReferralSuccessScreen: React.FC = () => {
  const history = useHistory();
  const onboardStatus = useOnboardStatus(true);
  const [utmSource] = useQueryParam<string>('utmSource');

  const navigateToHome = (queryParams = ''): void => {
    if (onboardStatus.data.fleet && onboardStatus.data.individual) {
      history.push(`/individual${queryParams}`);
    } else if (onboardStatus.data.fleet) {
      history.replace(`/business${queryParams}`);
    } else {
      history.push(`/individual${queryParams}`);
    }
  };

  const onClickViewFines = () => {
    const queryParams = `${utmSource ? `?utmSource=${utmSource}` : ''}`;
    navigateToHome(queryParams);
  };

  const onClickAddMoreReferrals = () => {
    const queryParams = `?dialog=referrals${utmSource ? `&utmSource=${utmSource}` : ''}`;
    navigateToHome(queryParams);
  };

  return (
    <FormContainer className="p-5 flex flex-col items-center">
      <span>Thank you for your submission. The referrals have been successfully added.</span>
      <i className="text-sm mt-5 text-center">
        * Referrals are accepted only when the referred user makes their first payment
        <br />
        through Fines SA.
      </i>
      <div className="flex justify-center space-x-5 mt-5">
        <Button
          onClick={onClickViewFines}
          className="bg-primary hover:bg-primary-dark text-white text-base self-center"
          size="medium"
        >
          View fines
        </Button>
        <Button
          onClick={onClickAddMoreReferrals}
          className="bg-primary hover:bg-primary-dark text-white text-base self-center"
          size="medium"
        >
          Add more referrals
        </Button>
      </div>
    </FormContainer>
  );
};

export default ReferralSuccessScreen;
