import { AxiosResponse } from 'axios';

import authNetworkService from '../auth-network/auth-network.service';
import operationsUrls from './operations.urls';

const uploadCapeTownImportFile = (file: File, email: string) => {
  const url = operationsUrls.uploadCapeTownImportFile();
  const form = new FormData();
  form.append('File', file);
  form.append('NotifyEmail', email);
  return authNetworkService.post(url, form, {
    timeout: 0,
  });
};

const uploadPayFastImportFile = (file: File, email: string) => {
  const url = operationsUrls.uploadPayFastImportFile();
  const form = new FormData();
  form.append('File', file);
  form.append('NotifyEmail', email);
  return authNetworkService.post(url, form, {
    timeout: 0,
  });
};

const exportCapeTownWeeklyOffenderIdentifiers = (): Promise<AxiosResponse<Blob>> => {
  const url = operationsUrls.exportWeeklyTmtCapeTown();
  return authNetworkService.get(url, {
    responseType: 'blob',
    timeout: 0,
  });
};

export default {
  uploadPayFastImportFile,
  uploadCapeTownImportFile,
  exportCapeTownWeeklyOffenderIdentifiers,
};
