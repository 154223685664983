import config from '../../../config';
import { Guid } from '../../../types';

const baseUrlV1 = `${config.apiUrl()}/BackOffice/Profiles`;
const baseUrlV2 = `${config.apiUrl(2)}/BackOffice/Profiles`;

export default {
  update: (id: Guid): string => `${baseUrlV1}/${id}`,
  FindProfileUrl: (): string => `${baseUrlV1}`,
  editProfileUrl: (userId): string => `${baseUrlV1}/${userId}`,
  setNoteUrl: () => `${baseUrlV1}/set-note`,
  getProfileFinesPaginated: (profileId: Guid): string => `${baseUrlV2}/${profileId}/Fines`,

  exportProfileFinesToCsvUrl: (): string => `${config.apiUrl()}/BackOffice/ProfileFines/export-csv`,
};
