import { useQuery } from 'react-query';

import { profilesService } from '../../services';

export const useOnboardStatus = (enabled = false) => {
  return useQuery('onboardStatus', profilesService.onboardStatus, {
    enabled,
  });
};

export const useGetOnboardStatus = () => {
  return useQuery('onboardStatus', profilesService.onboardStatus);
};
