import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { InvoiceDetailDto } from '../../../types';
import { InternalHookFormInput } from '../../../components/atoms/internal-hook-form-input/internal-hook-form-input';
import { StringHelper, dateFormatter } from '../../../helpers';
import { InternalHookFormTextArea } from '../../../components/atoms/internal-hook-form-text-area/internal-hook-form-text-area';
import { adminInvoiceService } from '../../../services';

type AdminInvoiceFormFields = {
  poNumber: string;
  additionalInformationKey: string;
  additionalInformationValue: string;
};

type AdminInvoiceFormProps = {
  defaultValues: AdminInvoiceFormFields;
  displayValues: InvoiceDetailDto;
};

export const AdminInvoiceForm: React.FC<AdminInvoiceFormProps> = ({
  defaultValues,
  displayValues,
}) => {
  const { register, getValues } = useForm({ defaultValues, mode: 'onTouched' });

  const useMutationWithToast = (mutationFn, successMessage, errorMessage) => {
    return useMutation(mutationFn, {
      onSuccess: () => {
        toast.success(successMessage);
      },
      onError: () => {
        toast.error(errorMessage);
      },
    });
  };

  const poNumberMutation = useMutationWithToast(
    () => adminInvoiceService.setPoNumber(displayValues.id, getValues('poNumber')),
    'Successfully set po number',
    'Error setting po number',
  );

  const additionalInformationKeyMutation = useMutationWithToast(
    () =>
      adminInvoiceService.updateAdditionalInformationKey(
        displayValues.id,
        getValues('additionalInformationKey'),
      ),
    'Successfully set additional information key',
    'Error setting additional information key',
  );

  const setAdditionalInformationValueMutation = useMutationWithToast(
    () =>
      adminInvoiceService.updateAdditionalInformationValue(
        displayValues.id,
        getValues('additionalInformationValue'),
      ),
    'Successfully set additional information value',
    'Error setting additional information value',
  );
  return (
    <>
      <div className="flex flex-col w-80">
        <div className="flex flex-row justify-between mt-2 ">
          <p>Invoice to </p>
          <p>{displayValues.recipientName}</p>
        </div>
        <div className="flex flex-row justify-between mt-2">
          <p>Vat Reg: </p>
          <p>{displayValues.vatNumber} </p>
        </div>
        <div className="flex flex-row justify-between mt-2">
          <p>Comany Reg: </p>
          <p>{displayValues.companyRegistrationNumber} </p>
        </div>
        <div className="flex flex-row justify-between mt-2">
          <p>Contact: </p>
          <p>{displayValues.recipientName}</p>
        </div>
        <div className="flex flex-row justify-between mt-2">
          <p>Status: </p>
          <p>{displayValues.invoiceStatus}</p>
        </div>
        <div className="flex flex-row justify-between mt-2">
          <p>PoNumber: </p>
          {displayValues.invoiceStatus === 'Closed' ? (
            <p>{displayValues.poNumber}</p>
          ) : (
            <InternalHookFormInput
              className="text-right"
              variableName="poNumber"
              register={register}
              onBlur={() => poNumberMutation.mutate()}
            />
          )}
        </div>
      </div>
      <div className="ml-32 w-80 ">
        <div className="flex flex-row justify-between mt-2">
          <p>Ref No: </p>
          <p>{displayValues.referenceNumber}</p>
        </div>
        <div className="flex flex-row justify-between mt-2">
          <p>Created Date: </p>
          <p>{dateFormatter(displayValues.dateCreated)}</p>
        </div>
        <div className="flex flex-row justify-between mt-2">
          <p>Due Date: </p>
          <p>{dateFormatter(displayValues.dueDate)}</p>
        </div>
        <div className="flex flex-col mt-2">
          <div className="flex flex-grow justify-center">
            <p>Additional Information</p>
          </div>
          {displayValues.invoiceStatus === 'Closed' ? (
            <div className="flex justify-between">
              <p className="min-w-14">
                {StringHelper.isNullOrEmpty(displayValues.additionalInformationKey)
                  ? 'Not Set'
                  : displayValues.additionalInformationKey}
                :
              </p>
              <p>
                {StringHelper.isNullOrEmpty(displayValues.additionalInformationValue)
                  ? 'Not Set'
                  : displayValues.additionalInformationValue}
              </p>
            </div>
          ) : (
            <div className="flex justify-between">
              <InternalHookFormInput
                id="additional-information-key"
                className="w-28 h-6"
                placeholder="Key"
                variableName="additionalInformationKey"
                register={register}
                onBlur={() => additionalInformationKeyMutation.mutate()}
              />

              <InternalHookFormTextArea
                id="additional-information-value"
                placeholder="Value"
                className="h-6"
                variableName="additionalInformationValue"
                register={register}
                onBlur={() => setAdditionalInformationValueMutation.mutate()}
                cols={20}
              />
            </div>
          )}
        </div>
        {displayValues.address ? (
          <div className="flex flex-row justify-between mt-2">
            <p>Address: </p>
            <p>{displayValues.address}</p>
          </div>
        ) : (
          <></>
        )}
        {displayValues.invoiceStatus === 'Closed' ? (
          <>
            <div className="flex flex-row justify-between mt-2">
              <p>Payment Received Date: </p>
              <p>{dateFormatter(displayValues.paymentReceivedDate)}</p>
            </div>
            <div className="flex flex-row justify-between mt-2">
              <p>Total Amount Received: </p>
              <p>R {displayValues.amountReceived}</p>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
