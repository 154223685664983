import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useQueryParam } from 'use-query-params';

import { FormContainer } from '../../../components';
import { ActivityLoader } from '../../../components/atoms/activity-loader/activity-loader';
import correspondenceService from '../../../services/api/correspondence/correspondence.service';

const WhatsAppOptOutScreen: React.FC = () => {
  const [phoneNumber] = useQueryParam<string>('PhoneNumber');
  phoneNumber.replace('+', '');

  const optUserOut = useMutation(() => correspondenceService.optOutWhatsApp(phoneNumber));
  const [message, setMessage] = useState<string>(null);

  const _optUserOut = () => {
    optUserOut
      .mutateAsync()
      .then(() => {
        setMessage('You have successfully been opted out of receiving WhatsApp messages');
      })
      .catch(() => {
        setMessage('An error has occurred. Please try again later');
      });
  };

  useEffect(() => {
    _optUserOut();
  }, []);

  return (
    <FormContainer className="p-5 flex justify-center">
      {optUserOut.isLoading ? (
        <ActivityLoader isLoading={optUserOut.isLoading} />
      ) : (
        <p className="text-center">{message}</p>
      )}
    </FormContainer>
  );
};
export default WhatsAppOptOutScreen;
