import { AxiosResponse } from 'axios';
import authNetworkService from '../auth-network/auth-network.service';
import {
  AccountProfiles,
  EditAccountUpdateDto,
  OnboardStatus,
  UserInfo,
} from '../../../types/profile.types';
import profileUrls from './profile.urls';
import {
  EditCallCentreProfileFormFields,
  EditBusinessProps,
  EditIndividualProps,
  OnboardBusinessProps,
  OnboardIndividualProps,
  ServiceResult,
} from '../../../types';
import { LinkDto, OnboardBusinessEntityDto } from '../../../types/data-transfer-objects/dto.types';

const onboardStatus = (): Promise<OnboardStatus> => {
  const url = profileUrls.onboardStatusUrl();
  return authNetworkService
    .get(url)
    .then((apiResponse: AxiosResponse<ServiceResult<OnboardStatus>>) => apiResponse.data.data);
};

const userInfo = (): Promise<UserInfo> => {
  const url = profileUrls.userInfoUrl();
  return authNetworkService
    .get(url)
    .then((apiResponse: AxiosResponse<ServiceResult<UserInfo>>) => apiResponse.data.data);
};

const onboardIndividual = (formData: OnboardIndividualProps) => {
  const url = profileUrls.onboardIndividualUrl();
  return authNetworkService.post(url, formData).catch((error) => {
    return Promise.reject(error);
  });
};

const onboardBusiness = (formData: OnboardBusinessProps) => {
  const url = profileUrls.onboardBusinessUrl();
  const dto = {
    fleets: formData.businessEntities.map<OnboardBusinessEntityDto>((businessEntity) => {
      return {
        ...businessEntity,
        links: businessEntity.links.map<LinkDto>((link) => {
          return { type: link.identifierType, value: link.value };
        }),
      };
    }),
  };
  return authNetworkService.post(url, dto).catch((error) => {
    return Promise.reject(error);
  });
};

const profileUserDetail = (): Promise<AccountProfiles> => {
  const url = profileUrls.profileUserDetail();
  return authNetworkService
    .get(url)
    .then((apiResponse: AxiosResponse<ServiceResult<AccountProfiles>>) => apiResponse.data.data);
};

const editAccount = (formData: EditAccountUpdateDto) => {
  const url = profileUrls.editUserProfileUrl();
  return authNetworkService.put(url, formData).catch((error) => {
    return Promise.reject(error);
  });
};

const editCallCenterAccount = (formData: EditCallCentreProfileFormFields) => {
  const url = profileUrls.editUserProfileV2Url();
  return authNetworkService.put(url, formData).catch((error) => {
    return Promise.reject(error);
  });
};

const editFamilyAndFriends = (formData: EditIndividualProps) => {
  const url = profileUrls.editFriendsAndFamilyUrl();

  return authNetworkService.put(url, formData).catch((error) => {
    return Promise.reject(error);
  });
};

const editBusinesses = (formData: EditBusinessProps) => {
  const url = profileUrls.editBusinessesUrl();

  return authNetworkService.put(url, formData).catch((error) => {
    return Promise.reject(error);
  });
};

const syncFines = () => {
  const url = profileUrls.syncFinesUrl();

  return authNetworkService.get(url).catch((error) => {
    return Promise.reject(error);
  });
};

export default {
  onboardStatus,
  userInfo,
  onboardIndividual,
  onboardBusiness,
  profileUserDetail,
  editAccount,
  editCallCenterAccount,
  editFamilyAndFriends,
  editBusinesses,
  syncFines,
};
