import React from 'react';
import { decodedToken } from '../../../services';

import { CallCentreManangementOrganism } from '../../../components/organisms/management-call-centre-organism/management-call-centre-organism';
import { CallCentreOrganism } from '../../../components/organisms/call-centre-organism/call-centre-organism';

const CallCentreScreen: React.FC = () => {
  const userRole = decodedToken.getRole();

  return userRole === 'SuperAdministrator' || userRole === 'CallCenterManager' ? (
    <CallCentreManangementOrganism />
  ) : userRole === 'CallCenterAgent' ? (
    <CallCentreOrganism />
  ) : null;
};

export default CallCentreScreen;
