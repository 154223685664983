import ax from 'axios';
import { createNetworkErrorHandlerInterceptor } from '../../interceptors';

const axios = ax.create({
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    responseType: 'json',
  },
});

createNetworkErrorHandlerInterceptor(axios);
export default axios;
