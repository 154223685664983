export class StringHelper {
  public static isLettersOrDigits(value: string | undefined): boolean {
    if (value === undefined) return true;
    return /^[a-zA-Z0-9]*$/.test(value);
  }

  public static isNullOrEmpty(value: string | undefined): boolean {
    if (value === undefined || value === null) return true;
    if (value.length === 0) return true;
    return false;
  }
}
