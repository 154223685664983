import { useEffect, useState } from 'react';
import { ValueType } from 'react-select';
import { useQuery } from 'react-query';
import { Guid, ProfileType } from '../../types';

import { DropdownSelection } from './types';
import { getAccountProfiles } from '../../services/api/admin-profiles/admin-profiles.service';

export const useResponsibleOffenderIdentifiers = (
  userId: Guid,
  profileType: ProfileType,
  activeFleetEntityId: Guid,
): DropdownSelection => {
  const [uid, setUid] = useState<Guid>(userId);
  const [selected, setSelected] = useState<ValueType<{ label: string; value: string }, true>>([]);

  useEffect(() => {
    setUid(uid);
  }, [userId]);

  const accountProfilesQuery = useQuery(
    ['AdminAccountProfileData', uid],
    () => getAccountProfiles(uid),
    {
      enabled: !!uid,
      select: (data) => {
        if (profileType === ProfileType.Individual) {
          const offenderIdentifierValue = data?.offenderIdentifier?.value ?? '';
          const familyMembersOffenderIdentification =
            data?.individual?.familyMembers?.flatMap((fm) => fm.offenderIdentifier.value) ?? [];
          return familyMembersOffenderIdentification.concat(offenderIdentifierValue);
        }
        return data?.business?.businesses
          ?.filter((s) => s.id === activeFleetEntityId)
          .flatMap((business) => business.links.map((link) => link.value));
      },
    },
  );

  return {
    isReady: !accountProfilesQuery.isLoading,
    all: accountProfilesQuery?.data?.map((s) => new Option(s, s)) ?? [],
    allValues: accountProfilesQuery?.data || [],
    selected,
    setSelected,
    selectedValues: selected.map((s) => s.value),
    selectedForQuery:
      selected.length === 0 ? accountProfilesQuery?.data ?? [] : selected.map((s) => s.value),
  };
};
