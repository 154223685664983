import React from 'react';

import { Spinner } from 'react-activity';
import { ButtonProps } from './types';

export const TailwindButton: React.FC<ButtonProps> = ({
  children,
  type = 'button',
  variant = 'contained',
  isLoading = false,
  isDisabled = false,
  onClick,
  className,
}) => {
  let buttonStyle = className;

  // eslint-disable-next-line default-case
  switch (variant) {
    case 'contained':
      buttonStyle = `inline-block px-4 py-2 xs:mb-0 text-white font-semibold text-sm uppercase rounded shadow-md hover:bg-gray-100 hover:shadow-lg focus:bg-gray-200 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ${className}`;
      break;
    case 'outlined':
      buttonStyle = `inline-block px-4 py-2 border-2 border-blue-600 text-blue-600 font-medium text-sm uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out ${className}`;
      break;
    case 'text':
      buttonStyle = `inline-block py-1 text-blue-600 font-medium text-sm uppercase rounded focus:outline-none focus:ring-0 transition duration-150 ease-in-out ${className}`;
  }

  if (isDisabled) {
    buttonStyle = `${className} inline-block px-4 py-2 bg-disabled text-white font-semibold text-sm uppercase rounded shadow-md opacity-90`;
  }

  return (
    <button
      /* eslint-disable-next-line react/button-has-type */
      type={type}
      disabled={isLoading || isDisabled}
      onClick={onClick}
      className={`flex justify-center rounded-md ${buttonStyle}`}
    >
      {isLoading ? <Spinner /> : children}
    </button>
  );
};
