import { AxiosResponse } from 'axios';
import { AddFleetEntitiesProps, EditFleetEntitiesProps, Guid, ServiceResult } from '../../../types';
import authNetworkService from '../auth-network/auth-network.service';
import adminFleetEntitiesUrls from './admin-fleet-entities.urls';

const deleteFleetEntity = (fleetEntityId) => {
  const url = adminFleetEntitiesUrls.deleteFleetEntity(fleetEntityId);
  return authNetworkService.delete(url).catch((error) => {
    return Promise.reject(error);
  });
};

const editFleetEntities = (fleetEntityId, formData: EditFleetEntitiesProps) => {
  const url = adminFleetEntitiesUrls.editFleetEntities(fleetEntityId);
  return authNetworkService.put(url, formData).catch((error) => {
    return Promise.reject(error);
  });
};

const addFleetEntities = (formData: AddFleetEntitiesProps): Promise<Guid> => {
  const url = adminFleetEntitiesUrls.addFleetEntitiesUrl();
  return authNetworkService
    .post(url, formData)
    .then((apiResponse: AxiosResponse<ServiceResult<Guid>>): Guid => apiResponse.data.data);
};

export default { addFleetEntities, deleteFleetEntity, editFleetEntities };
