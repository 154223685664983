import { useMediaQuery } from 'react-responsive';

const useResponsive = () => {
  const isExstraSmall = useMediaQuery({
    maxWidth: 330,
  });

  const isMobile = useMediaQuery({
    maxWidth: 479,
  });

  const isSmall = useMediaQuery({
    maxWidth: 599,
  });

  const isMedium = useMediaQuery({
    maxWidth: 767,
  });

  const isLarge = useMediaQuery({
    maxWidth: 1319,
  });

  const isExtraLarge = useMediaQuery({
    maxWidth: 1920,
  });

  const isSmallWindowHeightThreshold = useMediaQuery({
    maxHeight: 880,
  });

  return {
    isExstraSmall,
    isMobile,
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge,
    isSmallWindowHeightThreshold,
  };
};

export default useResponsive;
