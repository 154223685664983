import { AxiosResponse } from 'axios';

import { Guid, PaginatedList, User } from '../../../types';
import authNetworkService from '../auth-network/auth-network.service';
import adminConsultantsUrls from './admin-consultants.urls';

const getConsultant = (
  pageNum: string,
  PageEntries: string,
  Search: string,
): Promise<PaginatedList<User>> => {
  const url = adminConsultantsUrls.getConsultantUrl(pageNum, PageEntries, Search);

  return authNetworkService.get(url).then((apiResponse: AxiosResponse<PaginatedList<User>>) => {
    return apiResponse.data;
  });
};

const deleteConsultant = (consultantId: Guid) => {
  const url = adminConsultantsUrls.deleteConsultantUrl(consultantId);
  return authNetworkService.delete(url).catch((error) => {
    return Promise.reject(error);
  });
};

export default { getConsultant, deleteConsultant };
