import { useQuery, UseQueryResult } from 'react-query';

import { getUsers } from '../../services/api/admin-users/admin-users.service';
import { PaginatedList, User } from '../../types';

export const useAdminUsersData = (
  pageNum: number,
  pageEntries: number,
  fullName: string,
  entityName: string,
  mobileNumber: string,
  email: string,
  businessOffenderIdentifier: string,
  individualOffenderIdentifier: string,
  communicationEmail: string,
): UseQueryResult<PaginatedList<User>, unknown> => {
  return useQuery(
    [
      'userData',
      pageNum,
      pageEntries,
      email,
      fullName,
      entityName,
      mobileNumber,
      businessOffenderIdentifier,
      individualOffenderIdentifier,
      communicationEmail,
    ],
    ({ signal }) =>
      getUsers(
        pageNum,
        pageEntries,
        fullName,
        entityName,
        mobileNumber,
        email,
        businessOffenderIdentifier,
        individualOffenderIdentifier,
        communicationEmail,
        signal,
      ),
    {
      // enabled: !!search,
      refetchOnWindowFocus: false,
    },
  );
};
