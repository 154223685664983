import config from '../../../config';

const baseUrl = `${config.apiUrl()}/DailyCalls`;

export default {
  dailyCallsUrl: (): string => baseUrl,
  getBaseContentUrl: (): string => `${baseUrl}/content`,
  repopulateDailyCallsUrl: (): string => `${baseUrl}/repopulate`,
  doNotContact: (): string => `${baseUrl}/do-not-contact`,
};
