import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import _ from 'lodash';

import { FormContainer, LoginForm } from '../../../components';
import { decodedToken, localStorageService, userAuthService } from '../../../services';
import { LoginProps } from '../../../types';
import config from '../../../config';
import { setUserRole } from '../../../reducers/userRole-reducer/userRole.actions';
import { useAppDispatch } from '../../../reducers/store';

const LoginScreen: React.FC = () => {
  const [rememberEmail, setRememberEmail] = useState('');
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const history = useHistory();

  const _onLoginSubmit = (formData: LoginProps) => {
    localStorageService.setItem(
      config.rememberMeKey,
      _.get(formData, 'remember', false) ? _.get(formData, 'username') : '',
    );
    return userAuthService.login(formData);
  };

  const _onSuccess = async () => {
    const role = decodedToken.getRole();
    await dispatch(setUserRole(role));
    if (role === 'PortalUser') {
      history.replace(`/${history.location.search}`);
    } else if (
      role === 'SuperAdministrator' ||
      role === 'Consultant' ||
      role === 'CallCenterAgent' ||
      role === 'CallCenterManager'
    ) {
      history.replace('/admin');
    }
  };

  const reset = new URLSearchParams(search).get('reset');
  const unlock = new URLSearchParams(search).get('unlock');
  const rememberMe = localStorageService.getItem(config.rememberMeKey) ?? '';

  useEffect(() => {
    if (reset === 'successful') {
      toast.success('Password successfully reset', { duration: 5000 });
    } else if (unlock === 'successful') {
      toast.success('Account successfully unlocked', { duration: 5000 });
    } else if (reset === 'initialized') {
      toast.success('Password successfully set', { duration: 5000 });
    }
    setRememberEmail(`${rememberMe}`);
  }, [reset, unlock, rememberMe]);

  const loginFormInitialValues: LoginProps = {
    username: rememberEmail,
    password: '',
    remember: !_.isEmpty(rememberEmail),
  };

  return (
    <FormContainer>
      <Toaster position="top-center" />
      <LoginForm
        initialValues={loginFormInitialValues}
        submitForm={_onLoginSubmit}
        onSuccess={_onSuccess}
        onFailure={() => null}
      />
    </FormContainer>
  );
};

export default LoginScreen;
