import _ from 'lodash';
import { ServiceResultError } from '../../types';

class ClientNetworkError extends Error {
  errors: any;
  statusCode: number;
  detail: string;
  serviceResultError: ServiceResultError;

  constructor(statusCode: number, error: any) {
    super(error);
    this.message = _.get(error, 'error', `Request failed with status code ${statusCode}`);
    this.errors = _.get(error, 'errors');
    this.statusCode = statusCode;
    this.name = 'ClientNetworkError';
    this.detail = error.detail;
    this.serviceResultError = error.error;
  }
}
export default ClientNetworkError;
