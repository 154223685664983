import { Dispatch, SetStateAction, useState } from 'react';

export const useIsArchived = (): {
  isArchived: boolean;
  setIsArchived: Dispatch<SetStateAction<boolean>>;
} => {
  const [isArchived, setIsArchived] = useState(false);

  return {
    isArchived,
    setIsArchived,
  };
};
