import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-hot-toast';

import { userAuthService } from '../../../services';
import { VerifyAccountProps } from '../../../types';

const VerifyAccountScreen: React.FC = () => {
  const { search } = useLocation();
  const [verifyAccountError, setVerifyAccountError] = useState<string | null>(null);
  const history = useHistory();

  useEffect(() => {
    const token = new URLSearchParams(search).get('token') || '';
    const email = new URLSearchParams(search).get('email') || '';
    const requestData: VerifyAccountProps = {
      email,
      token,
    };
    userAuthService
      .verifyAccount(requestData)
      .then(() => {
        toast.success('Successfully verified account');
        history.replace('/login');
      })
      .catch(() => {
        setVerifyAccountError('An error occurred on account verification');
      });
  }, []);
  return (
    <div className="flex flex-1 flex-col">
      <p className="text-danger">{verifyAccountError}</p>
    </div>
  );
};
export default VerifyAccountScreen;
