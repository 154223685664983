import { AxiosResponse } from 'axios';

import {
  AddConsultantProps,
  GeneratePasswordLinkDto,
  PaginatedList,
  ServiceResult,
  SignUpConsultantProps,
  User,
} from '../../../types';
import authNetworkService from '../auth-network/auth-network.service';
import adminUsersUrls from './admin-users.urls';

export const getUsers = (
  pageNum: number,
  pageEntries: number,
  fullName: string,
  entityName: string,
  mobileNumber: string,
  email: string,
  businessOffenderIdentifier: string,
  individualOffenderIdentifier: string,
  communicationEmail: string,
  signal: AbortSignal,
): Promise<PaginatedList<User>> => {
  const url = adminUsersUrls.getUsersUrl();

  return authNetworkService
    .get(url, {
      signal,
      params: {
        pageEntries,
        pageNumber: pageNum,
        mobileNumber,
        fullName,
        entityName,
        email,
        businessOffenderIdentifier,
        communicationEmail,
        individualOffenderIdentifier,
      },
    })
    .then((apiResponse: AxiosResponse<PaginatedList<User>>) => {
      return apiResponse.data;
    });
};

const generateResetPasswordLink = (id: string): Promise<GeneratePasswordLinkDto> => {
  const url = adminUsersUrls.generatePasswordLinkUrl();
  return authNetworkService
    .post(url, { userId: id })
    .then(
      (apiResponse: AxiosResponse<ServiceResult<GeneratePasswordLinkDto>>) => apiResponse.data.data,
    )
    .catch((error) => {
      return Promise.reject(error);
    });
};

const addConsultant = (formData: AddConsultantProps) => {
  const addConsultantUrl = adminUsersUrls.addRoleToUserUrl();
  return authNetworkService.post(addConsultantUrl, formData);
};

const signUpConsultant = (formData: SignUpConsultantProps) => {
  const signUpConsultantUrl = adminUsersUrls.signUpConsultantUrl();
  return authNetworkService.post(signUpConsultantUrl, formData).then((res) => res.data);
};

export default {
  generateResetPasswordLink,
  addConsultant,
  signUpConsultant,
};
