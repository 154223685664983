import React, { ReactElement, ChangeEvent } from 'react';
import { InputAdornment, SelectProps, TextField } from '@material-ui/core';
import _ from 'lodash';

type Props = {
  variableName: string;
  placeholder?: string;
  type?: string;
  values: Record<string, unknown>;
  onChange: {
    (e: ChangeEvent<any>): void;
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  onBlur: (
    fieldName: string,
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  touched: Record<string, unknown>;
  errors: Record<string, unknown>;
  onPaste?: {
    (e: ClipboardEvent): void;
    <T = string | ClipboardEvent>(field: T): T extends Event
      ? void
      : (e: string | ClipboardEvent) => void;
  };
  className?: string;
  icon?: ReactElement<any, any>;
  endIcon?: ReactElement<any, any>;
  required?: boolean;
  inputClassName?: string;
  select?: boolean;
  selectProps?: SelectProps;
  children?: React.ReactNode;
  disabled?: any;
  autoComplete?:
    | 'name'
    | 'honorific-prefix'
    | 'given-name'
    | 'additional-name'
    | 'family-name'
    | 'honorific-suffix'
    | 'nickname'
    | 'username'
    | 'new-password'
    | 'current-password'
    | 'one-time-code'
    | 'organization-title'
    | 'organization'
    | 'street-address'
    | 'address-line1'
    | 'address-line2'
    | 'address-line3'
    | 'address-level4'
    | 'address-level3'
    | 'address-level2'
    | 'address-level1'
    | 'country'
    | 'country-name'
    | 'postal-code'
    | 'cc-name'
    | 'cc-given-name'
    | 'cc-additional-name'
    | 'cc-family-name'
    | 'cc-number'
    | 'cc-exp'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-csc'
    | 'cc-type'
    | 'transaction-currency'
    | 'transaction-amount'
    | 'language'
    | 'bday'
    | 'bday-day'
    | 'bday-month'
    | 'bday-year'
    | 'sex'
    | 'url'
    | 'photo';
};

export const FormTextField: React.FC<Props> = ({
  variableName = '',
  placeholder = '',
  type,
  values = {},
  onChange,
  onBlur,
  touched,
  errors,
  onPaste,
  className,
  icon,
  endIcon,
  required = false,
  inputClassName = '',
  select = false,
  selectProps = {},
  children,
  disabled,
  autoComplete = '',
}) => {
  return (
    <TextField
      id={variableName}
      placeholder={`${placeholder}`}
      required={required}
      type={type}
      value={_.get(values, variableName)}
      error={_.get(touched, variableName) && !!_.get(errors, variableName)}
      helperText={_.get(touched, variableName) ? _.get(errors, variableName) : ''}
      onChange={onChange(variableName)}
      onBlur={(event) => onBlur(variableName, event)}
      onPaste={onPaste}
      variant="outlined"
      size="small"
      className={`${className} shadow-lg`}
      InputProps={{
        startAdornment: !_.isEmpty(icon) ? (
          <InputAdornment position="start">{icon}</InputAdornment>
        ) : (
          <></>
        ),
        className: `${inputClassName} bg-white`,
        endAdornment: !_.isEmpty(endIcon) ? (
          <InputAdornment position="end">{endIcon}</InputAdornment>
        ) : (
          <></>
        ),
      }}
      select={select}
      SelectProps={selectProps}
      disabled={disabled}
      autoComplete={autoComplete}
    >
      {children}
    </TextField>
  );
};
