import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface syncFines {
  isLoading: boolean;
  syncSuccessful: boolean;
  isError: boolean;
}

const initialState: syncFines = {
  isLoading: false,
  syncSuccessful: false,
  isError: false,
};

export const syncFinesSlice = createSlice({
  name: 'syncFinesSlice',
  initialState,
  reducers: {
    setLoadingAction: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSyncSuccessful: (state, action: PayloadAction<boolean>) => {
      state.syncSuccessful = action.payload;
    },
    setErrorAction: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
    },
  },
});

export const { setLoadingAction, setSyncSuccessful, setErrorAction } = syncFinesSlice.actions;
export const syncFineSelector = (state: RootState) => state.syncFineReducer;

export default syncFinesSlice.reducer;
