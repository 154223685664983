import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Toggle from 'react-toggle';
import { Button } from '../../atoms';
import { FineSyncConfigurationUpdateRequest } from '../../../types';

type FineSyncConfigurationFormProps = {
  fineSyncConfiguration: FineSyncConfigurationUpdateRequest;
  onSubmit: (values: FineSyncConfigurationUpdateRequest) => void;
  isSubmitLoading: boolean;
};

export const FineSyncConfigurationForm: React.FC<FineSyncConfigurationFormProps> = ({
  fineSyncConfiguration,
  onSubmit,
  isSubmitLoading,
}) => {
  const { handleSubmit, control, getValues } = useForm({ defaultValues: fineSyncConfiguration });
  return (
    <div className="flex flex-grow">
      <form className="flex flex-col flex-grow" onSubmit={handleSubmit(onSubmit)}>
        <label className="flex items-center gap-2 mt-4">
          <Controller
            control={control}
            name="isAartoMoveToPaidThroughOtherAvenueEnabled"
            render={({ field: { value, onChange } }) => (
              <Toggle icons={false} checked={value} onChange={onChange} />
            )}
          />
          <span className="text-base">AARTO Move to Paid through Other Avenues Enabled</span>
        </label>
      </form>
      <div className="flex self-end justify-self-end">
        <Button
          isLoading={isSubmitLoading}
          onClick={() => onSubmit(getValues())}
          className="bg-secondary hover:bg-secondary-dark text-white text-base self-center"
        >
          Save
        </Button>
      </div>
    </div>
  );
};
