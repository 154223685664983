import config from '../../../config';
import { Guid, InvoiceFilterOptions } from '../../../types';

const baseUrl = `${config.apiUrl()}/BackOffice/Invoices`;

export default {
  getInvoicesUrl: (filter: InvoiceFilterOptions) =>
    `${baseUrl}?PageNumber=${filter.pageNum}&PageEntries=${filter.pageEntries}&ReferenceNumber=${filter.referenceNumber}&OffenderIdentifierValue=${filter.offenderIdentifierValue}&RecipientName=${filter.recipientName}&VatNumber=${filter.vatNumber}&Address=${filter.address}&CompanyRegistrationNumber=${filter.companyRegistrationNumber}`,
  getInvoiceUrl: (invoiceId) => `${baseUrl}/${invoiceId}`,
  getInvoiceContentUrl: (invoiceId, contentType) =>
    `${baseUrl}/${invoiceId}/Content?contentType=${contentType}`,
  getIndividualInvoiceUrl: (invoiceId): string => `${baseUrl}/${invoiceId}`,
  createInvoiceUrl: (): string => `${baseUrl}/create-invoice`,
  invoiceCorrespondenceUrl: (): string => `${baseUrl}/send-correspondence`,
  setPoNumberUrl: (id: Guid): string => `${baseUrl}/${id}/set-po-number`,
};
