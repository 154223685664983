import React from 'react';
import { Button, Card, CardContent } from '@material-ui/core';

import { toast } from 'react-hot-toast';
import { useAdminIndividualFinesData } from '../../../react-queries/admin-fines-data/admin-individual-data.queries';

const IndividualFineScreen: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('Id');

  const individualFineData = useAdminIndividualFinesData(id);
  if (individualFineData.isLoading) {
    return <div> Loading</div>;
  }
  const individualFineCard = () => (
    <Card className=" flex flex-col border rounded-lg mr-16 font-sans">
      <CardContent className="flex flex-row justify-between font-bold">
        <div className="font-sans mt-2 ">
          <p>Fine Information</p>
        </div>
      </CardContent>
      <div className="border-b border-black flex justify-start mr-4 ml-4" />
      <CardContent className="flex pt-1 px-0 text-base">
        <div className="flex flex-col rounded-md ">
          <div className="flex">
            <div className="ml-4">
              <p>Notice No: {individualFineData.data.noticeNumber}</p>
              <p className="mt-2">Offence Date: {individualFineData.data.date}</p>
              <p className="mt-2">Summons Number: {individualFineData.data.enforcementOrder}</p>
            </div>
            <div className="ml-28">
              <p>Municipality: {individualFineData.data.area}</p>
              <p className="mt-2">Amount Due: R{individualFineData.data.amountDue}</p>
            </div>
            <div className="ml-36">
              <p>Location: {individualFineData.data.area} </p>
              <p className="mt-2">Warrant/AARTO Fee: R{individualFineData.data.aartoFee} </p>
            </div>
            <div className="ml-32">
              <p>Enforcement Order: R{individualFineData.data.enforcementOrder} </p>
              <p className="mt-2">Service Fee: R{individualFineData.data.serviceFee} </p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );

  return (
    <>
      <div className=" mt-8 mr-16 ml-16 text-2xl">
        <Button
          variant="outlined"
          className="text-secondary cursor-default rounded-md border border-secondary mr-5"
          onClick={() =>
            navigator.clipboard.writeText(individualFineData.data.offenderIdentifier).then(() => {
              toast.success('Successfully copied to clipboard');
            })
          }
        >
          {individualFineData.data.offenderIdentifier}
        </Button>
      </div>
      <div className="border-b border-black mt-3  flex justify-start mr-16 ml-16" />
      <div className="ml-16 mt-4"> {individualFineCard()}</div>
    </>
  );
};

export default IndividualFineScreen;
