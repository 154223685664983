import { AppDispatch, AppThunk } from '../store';
import { profilesService } from '../../services';
import { setErrorAction, setLoadingAction, setSyncSuccessful } from './sync-fines.reducer';

export const syncFinesAction = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(setLoadingAction(true));
  dispatch(setErrorAction(false));
  return profilesService
    .syncFines()
    .then(() => {
      dispatch(setSyncSuccessful(true));
    })
    .catch(() => {
      dispatch(setErrorAction(true));
      dispatch(setSyncSuccessful(false));
    })
    .finally(() => {
      dispatch(setLoadingAction(false));
    });
};
