import { useState } from 'react';
import { ValueType } from 'react-select';
import { useQuery } from 'react-query';
import adminFinesService from '../../services/api/admin-fines/admin-fines.service';
import { DropdownSelection } from './types';

export const useVehicleRegistrations = (
  offenderIdentifiers: string[],
  enabled: boolean,
): DropdownSelection => {
  const [selected, setSelected] = useState<ValueType<{ label: string; value: string }, true>>([]);

  const registrations = useQuery(
    ['vehicleRegistrations', ...offenderIdentifiers],
    () => adminFinesService.getVehicleRegistrations(offenderIdentifiers),
    {
      enabled,
    },
  );
  return {
    isReady: !registrations.isLoading,
    all: registrations?.data?.map((s) => new Option(s, s)) ?? [],
    allValues: registrations?.data || [],
    selected,
    setSelected,
    selectedValues: selected.map((s) => s.value),
    selectedForQuery: selected.map((s) => s.value),
  };
};
