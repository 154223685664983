import { EditFamilyMemberProps } from '../../../types';
import authNetworkService from '../auth-network/auth-network.service';
import adminFamilyMembersUrls from './admin-family-members.urls';

const deleteFamilyMembers = (familyMemberId: string) => {
  const url = adminFamilyMembersUrls.deleteFamilyMember(familyMemberId);

  return authNetworkService.delete(url).catch((error) => {
    return Promise.reject(error);
  });
};

const editFamilyMember = (familyMemberId: string, formData: EditFamilyMemberProps) => {
  const url = adminFamilyMembersUrls.editFamilyMemberUrl(familyMemberId);

  return authNetworkService.put(url, formData).catch((error) => {
    return Promise.reject(error);
  });
};

export default { deleteFamilyMembers, editFamilyMember };
