import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { FormContainer, ResetPasswordForm } from '../../../components';
import { ResetPasswordProps } from '../../../types';
import { userAuthService } from '../../../services';

const ResetPasswordScreen: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const onSubmit = (formData: ResetPasswordProps) => {
    return userAuthService.resetPassword(formData);
  };

  const onSuccess = () => {
    history.replace('/login?reset=successful');
  };

  const initialValues: ResetPasswordProps = {
    password: '',
    confirmPassword: '',
    token: new URLSearchParams(location.search).get('token') || '',
    email: new URLSearchParams(location.search).get('email') || '',
  };

  return (
    <FormContainer>
      <ResetPasswordForm
        initialValues={initialValues}
        submitForm={onSubmit}
        onSuccess={onSuccess}
        onFailure={() => null}
      />
    </FormContainer>
  );
};

export default ResetPasswordScreen;
