import config from '../../../config';

const baseUrl = `${config.apiUrl()}/Profiles`;
const baseV2Url = `${config.apiUrl(2)}/Profiles`;

export default {
  onboardStatusUrl: (): string => `${baseUrl}/onboard-status`,
  userInfoUrl: (): string => `${baseUrl}/user-info`,
  profileUserDetail: (): string => `${baseUrl}/user-detail`,
  onboardIndividualUrl: (): string => `${baseUrl}/onboard-individual`,
  onboardBusinessUrl: (): string => `${baseUrl}/onboard-fleet`,
  editUserProfileUrl: (): string => `${baseUrl}/edit-user`,
  editUserProfileV2Url: (): string => `${baseV2Url}/edit-user`,
  editFriendsAndFamilyUrl: (): string => `${baseUrl}/edit-individual`,
  editBusinessesUrl: (): string => `${baseUrl}/edit-fleet`,
  syncFinesUrl: (): string => `${baseUrl}/sync-fines`,
};
