import React, { ChangeEvent, ReactElement } from 'react';
import _ from 'lodash';

import { FormTextField } from '../..';

type Props = {
  index: number;
  variableName: string;
  names: string[];
  placeHolders: string[];
  icons: ReactElement<any, any>[];
  values: Record<string, unknown>;
  errors: Record<string, unknown>;
  touched: Record<string, unknown>;
  onChange: {
    (e: ChangeEvent<any>): void;
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  onBlur: {
    (e: ChangeEvent<any>): void;
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  containerClassName?: string;
  className?: string;
  required?: boolean;
};

export const TextFieldCollection: React.FC<Props> = ({
  index,
  variableName,
  names,
  placeHolders,
  icons,
  values,
  errors,
  touched,
  onChange,
  onBlur,
  containerClassName,
  className = '',
  required = false,
}) => {
  const _fields = _.map(names, (name, itemIndex) => {
    return (
      <FormTextField
        key={`${variableName}-${index}-${name}-${itemIndex}`}
        variableName={`${variableName}.${index}.${name}`}
        placeholder={placeHolders[itemIndex]}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        values={values}
        errors={errors}
        touched={touched}
        icon={icons[itemIndex]}
        className={`${className} w-full`}
      />
    );
  });
  return <div className={containerClassName}>{_fields}</div>;
};
