import config from '../../../config';

const apiV1Url = config.apiUrl();
const operationsUrl = `${apiV1Url}/BackOffice/Operations`;

export default {
  uploadCapeTownImportFile: (): string => `${operationsUrl}/tmt-capetown-upload`,
  uploadPayFastImportFile: (): string => `${operationsUrl}/payfast-email-upload`,
  exportWeeklyTmtCapeTown: (): string => `${operationsUrl}/export-coct`,
};
