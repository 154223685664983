import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';
import { Spinner } from 'react-activity';
import DateTimePicker from 'react-datetime-picker';
import { useDownloadFile } from '../../../hooks/global';
import { CallOutcome } from '../../../types';

import callsService from '../../../services/api/admin-calls/admin-calls.service';
import dailyCallsService from '../../../services/api/admin-daily-calls/admin-daily-calls.service';
import { Button } from '../../atoms';
import { InternalSelect } from '../../atoms/internal-select/internal-select';
import userService from '../../../services/api/user/user.service';
import { RoleEnum } from '../../../enums/role.enum';
import { KeyValuePair } from '../../../types/common.types';
import ConditionalRenderer from '../../atoms/conditional-renderer/conditional-renderer';
import { dateTimeFormatterGmtZoneOffset } from '../../../helpers';

export const CallCentreManangementOrganism: React.FC = () => {
  const dateNow = new Date();
  const startingFromDate = new Date(
    new Date(dateNow.setMonth(dateNow.getMonth() - 1)).setHours(0, 0, 0, 0),
  );
  const queryClient = useQueryClient();
  const callCenterAgentsQuery = useQuery('call-center-agents', () =>
    userService.get(1, 100, RoleEnum.CallCenterAgent),
  );
  const [isLoading, setIsLoading] = useState(false);

  const [filterCalls, setFilterCalls] = useState({
    fromDate: startingFromDate as any,
    toDate: null,
    callCenterAgentId: null,
    pageSize: 10,
    pageNumber: 1,
  });

  const { download: downloadCallLeftReport, loading: isLoadingCallLeftReport } = useDownloadFile({
    apiDefinition: () => dailyCallsService.getAllDailyCallsContent(),
    onError: () => toast.error('Could not download report'),
  });

  const { data: callsData, isFetching: isFetchingCallHistory } = useQuery(
    ['agentsCallHistory', filterCalls],
    () => {
      return callsService.getCalls(
        filterCalls.pageNumber,
        filterCalls.pageSize,
        filterCalls.callCenterAgentId,
        null,
        filterCalls.fromDate,
        filterCalls.toDate,
      );
    },
  );

  const _repopulateDailyCalls = () => {
    setIsLoading(true);

    dailyCallsService.repopulateDailyCalls().then(() => {
      queryClient.invalidateQueries('agentsCallHistory');
      setIsLoading(false);

      toast.success('Calls re-created successfully.');
    });
  };

  const onClickBack = () => {
    setFilterCalls({ ...filterCalls, pageNumber: filterCalls.pageNumber - 1 });
  };

  const onClickNext = () => {
    setFilterCalls({ ...filterCalls, pageNumber: filterCalls.pageNumber + 1 });
  };

  return (
    <div>
      <div className="flex flex-row justify-between bg-grey-brand-1 h-10">
        <div className="flex justify-start items-center text-base font-semibold pl-8">
          Call Centre
        </div>
        <div className="flex items-center mr-8">
          <Button
            isLoading={isLoading}
            onClick={_repopulateDailyCalls}
            className="bg-primary hover:bg-primary-dark text-white text-xs self-center w-auto mr-3 rounded-sm"
            spinnerSize={10.5}
          >
            Re-create Daily Calls
          </Button>
          <Button
            isLoading={isLoadingCallLeftReport}
            onClick={downloadCallLeftReport}
            className="bg-secondary hover:bg-secondary-dark text-white text-xs self-center w-auto rounded-sm"
            spinnerSize={10.5}
          >
            Call Left Report
          </Button>
        </div>
      </div>

      <div className="mx-8 mt-4">
        <div className="flex items-center gap-4">
          <div className="flex flex-col">
            <label className="text-sm font-bold mt-3">Call Center Agent</label>
            <InternalSelect
              variableName="statementRunId"
              className="border border-solid border-black h-8 w-60"
              options={[{ value: '', key: null }].concat(
                callCenterAgentsQuery.data?.items?.map(
                  (callCenterAgent) =>
                    ({
                      value: callCenterAgent.firstName,
                      key: callCenterAgent.id,
                    } as KeyValuePair<string>),
                ) ?? [],
              )}
              onChange={(event) =>
                setFilterCalls({
                  ...filterCalls,
                  callCenterAgentId: event.target.value,
                  pageNumber: 1,
                })
              }
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-bold mt-3">From Date</label>
            <DateTimePicker
              id="filter-date-from"
              className="w-60 bg-white shadow-lg p-1 h-10"
              format="y-MM-dd HH:mm"
              onChange={(date) =>
                setFilterCalls({
                  ...filterCalls,
                  fromDate: date,
                  pageNumber: 1,
                })
              }
              value={filterCalls.fromDate}
              disableClock
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-bold mt-3">To Date</label>
            <DateTimePicker
              id="filter-date-from"
              className="w-60 bg-white shadow-lg p-1 h-10"
              format="y-MM-dd HH:mm"
              onChange={(date) =>
                setFilterCalls({
                  ...filterCalls,
                  toDate: date,
                  pageNumber: 1,
                })
              }
              value={filterCalls.toDate}
              disableClock
            />
          </div>
        </div>

        <div className="w-full bg-secondary px-2 py-1 text-white text-sm font-medium">
          Call History
        </div>
        <div className="border-solid border-2 border-t-0">
          {!isFetchingCallHistory ? (
            <table className="table-fixed">
              <thead>
                <tr key="tr-th-call">
                  <th key="th-call-datetime" className="p-3 text-xs w-36 bg-gray-200">
                    Date & Time
                  </th>
                  <th key="th-call-agent" className="p-3 text-xs w-24 bg-gray-200">
                    Agent
                  </th>
                  <th key="th-call-contact" className="p-3 text-xs w-24 bg-gray-200">
                    Contact
                  </th>
                  <th key="th-call-duration" className="p-3 text-xs w-24 bg-gray-200">
                    Duration (hh:mm:ss)
                  </th>
                  <th key="th-call-outcome" className="p-3 text-xs w-28 bg-gray-200">
                    Call Outcome
                  </th>
                </tr>
              </thead>
              <tbody>
                {callsData?.items?.length > 0 ? (
                  callsData?.items?.map((call, index) => {
                    return (
                      <tr
                        // need to replace with call id
                        // eslint-disable-next-line react/no-array-index-key
                        key={`tr-td-call-${index}`}
                        className={`h-10 ${index % 2 !== 0 ? 'bg-grey-brand-2' : ''}`}
                      >
                        <td className="py-1 px-3 truncate text-xs">
                          {dateTimeFormatterGmtZoneOffset(call.callDate, '/', 2)}
                        </td>
                        <td className="py-1 px-3 truncate text-xs">{call.agentFirstName}</td>
                        <td className="py-1 px-3 truncate text-xs">{call.callContactFirstName}</td>
                        <td className="py-1 px-3 truncate text-xs">
                          {new Date(call.durationInSeconds * 1000).toISOString().slice(11, 19)}
                        </td>
                        <td className="py-1 px-3 text-xs">
                          {CallOutcome[call.callOutcome as CallOutcome]
                            .replace(/([A-Z])/g, ' $1')
                            .trim()}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="h-100 align-top">
                    <td className="text-xs ml-2">No calls found for the selected filters</td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <div className="flex flex-grow items-center justify-center min-h-110">
              <Spinner />
            </div>
          )}
        </div>
        <div className="flex justify-end items-center text-sm">
          <ConditionalRenderer condition={callsData?.totalPages > 0}>
            <Button
              type="text"
              className="ml-auto"
              onClick={onClickBack}
              disabled={filterCalls.pageNumber === 1}
            >
              Back
            </Button>
            <Button
              type="text"
              onClick={onClickNext}
              disabled={filterCalls.pageNumber >= callsData?.totalPages}
            >
              Next
            </Button>
            <span>
              Page {filterCalls.pageNumber}/{callsData?.totalPages}
            </span>
          </ConditionalRenderer>
        </div>
      </div>
    </div>
  );
};
