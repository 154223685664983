import React from 'react';
import { useHistory } from 'react-router';

import { FormContainer, IconCard } from '../../../components';

const OnboardScreen: React.FC = () => {
  const history = useHistory();

  const _goToIndividualSignUp = () => {
    history.push('/onboard-individual');
  };

  const _goToBusinessSignUp = () => {
    history.push('/onboard-business');
  };

  const _goToBothSignUp = () => {
    history.push('/onboard-individual?both=true');
  };

  return (
    <FormContainer>
      <div className="flex gap-4 my-4 w-full justify-center items-center flex-col flex-1">
        <p className="text-text-grey text-xl">Profile Setup</p>
        <IconCard
          icon={<img src="assets/icons/user-filled-icon.png" alt="user" />}
          text="Individual"
          onClick={_goToIndividualSignUp}
          className="bg-secondary w-3/5 hover:bg-secondary-dark"
        />
        <IconCard
          icon={<img src="assets/icons/truck-filled-icon.png" alt="business" />}
          text="Business"
          onClick={_goToBusinessSignUp}
          className="w-3/5 hover:bg-primary-dark"
        />

        <IconCard
          icon={<img src="assets/icons/truck-user-filled-icon.png" alt="business" />}
          text="Register Both"
          onClick={_goToBothSignUp}
          className="bg-tertiary w-3/5 hover:bg-tertiary-dark"
        />
      </div>
    </FormContainer>
  );
};

export default OnboardScreen;
