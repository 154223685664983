import React from 'react';
import { Button } from '../../atoms';
import { useResponsive } from '../../../hooks';

const AnonymousAccessLayout: React.FC = ({ children }) => {
  const responsive = useResponsive();
  const _aboutUs = (): void => {
    window.open('https://www.finessa.co.za/about-us.php', '_blank');
  };

  const _services = (): void => {
    window.open('https://www.finessa.co.za/services.php', '_blank');
  };
  return (
    <div className="h-screen flex flex-col">
      <header>
        <nav className="px-4 justify-between bg-secondary h-20">
          <ul className="h-full">
            <li className="py-3 h-full">
              <img
                src="assets/images/fines-sa-logo-white.png"
                alt="finesSA logo"
                className="nav-logo"
                width="170"
              />
            </li>
          </ul>
        </nav>
      </header>
      {!responsive.isMobile ? (
        <div className="bg-anon-background flex flex-col p-5">
          <section
            className="flex flex-col"
            style={{
              height: window.innerHeight - 140,
            }}
          >
            {children}
          </section>
          <footer className="flex justify-between">
            <p className="footer-text">Copyright © {new Date().getFullYear()} FinesSA</p>
            <div className="flex">
              <Button onClick={_aboutUs} type="text" className="footer-text place-self-end">
                About Us
              </Button>
              <Button onClick={_services} type="text" className="footer-text">
                Services
              </Button>
            </div>
          </footer>
        </div>
      ) : (
        <div className="py-4 inline-block">
          <div>{children}</div>
          <div className="border-t-2 border-text-grey flex flex-col px-6">
            <p className="footer-text">Copyright © {new Date().getFullYear()} FinesSA</p>
            <Button onClick={_aboutUs} type="text" className="footer-text">
              About Us
            </Button>
            <Button onClick={_services} type="text" className="footer-text">
              Services
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
export default AnonymousAccessLayout;
