import { useQuery } from 'react-query';
import { Guid } from '../../types';
import { getAccountProfiles } from '../../services/api/admin-profiles/admin-profiles.service';

export const useIndividual = (userId: Guid) => {
  const individualQuery = useQuery(
    ['AdminAccountProfileData', userId],
    () => getAccountProfiles(userId),
    {
      enabled: !!userId,
    },
  );
  return {
    hasIndividualProfile: individualQuery.data?.offenderIdentifier?.value !== null,
    familyMembers: individualQuery.data?.individual?.familyMembers ?? [],
    id: userId,
  };
};
