import { AxiosResponse } from 'axios';
import * as qs from 'qs';
import {
  AddFineProps,
  EditFineProps,
  FineDetailDto,
  FineFilterOptions,
  GetFineByIdDto,
  Guid,
  PaginatedList,
  PaginatedProfileFinesQuery,
  ServiceResult,
} from '../../../types';
import authNetworkService from '../auth-network/auth-network.service';
import adminFineUrls from './admin-fine.urls';

const getAdminFines = (filterOptions: FineFilterOptions): Promise<PaginatedList<FineDetailDto>> => {
  const url = adminFineUrls.getPaginatedFinesUrl(filterOptions);

  return authNetworkService
    .get(url)
    .then(
      (apiResponse: AxiosResponse<ServiceResult<PaginatedList<FineDetailDto>>>) =>
        apiResponse.data.data,
    );
};

const getVehicleRegistrations = (offenderIdentifierValues: string[]): Promise<string[]> => {
  const url = adminFineUrls.getVehicleRegistrations();
  return authNetworkService
    .get(url, {
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat', allowDots: true }),
      params: {
        offenderIdentifierValues,
      },
    })
    .then((response: AxiosResponse<ServiceResult<string[]>>) => {
      return response.data.data;
    });
};

const getPaginatedFines = (
  paginated: PaginatedProfileFinesQuery,
): Promise<PaginatedList<FineDetailDto>> => {
  const url = adminFineUrls.getPaginated();
  return authNetworkService
    .get(url, {
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat', allowDots: true }),
      params: {
        ...paginated,
      },
    })
    .then((response: AxiosResponse<ServiceResult<PaginatedList<FineDetailDto>>>) => {
      return response.data.data;
    });
};

const addFine = (formData: AddFineProps) => {
  const addFineUrl = adminFineUrls.addFineUrl();
  return authNetworkService.post(addFineUrl, formData);
};

const getIndividualFine = (fineId: string): Promise<FineDetailDto> => {
  const url = adminFineUrls.getIndividualFineUrl(fineId);
  return authNetworkService
    .get(url)
    .then((apiResponse: AxiosResponse<ServiceResult<FineDetailDto>>) => {
      return apiResponse.data.data;
    });
};

const getIndividualFines = (
  individualId: string,
  filter: string,
  regFilter: string[] = [],
): Promise<FineDetailDto[]> => {
  const url = adminFineUrls.getIndividualFinesUrl();
  return authNetworkService
    .get(url, {
      params: {
        registrationFilter: regFilter,
        individualId,
        filter,
      },
      paramsSerializer: (params) => qs.stringify(params),
    })
    .then((apiResponse: AxiosResponse<ServiceResult<FineDetailDto[]>>) => {
      return apiResponse.data.data;
    });
};

const getFleetEntityFines = (
  entityId: string,
  filter: string,
  regFilter: string[] = [],
): Promise<FineDetailDto[]> => {
  const url = adminFineUrls.getLinkedEntityFines();
  return authNetworkService
    .get(url, {
      params: {
        entityId,
        filter,
        registrationFilter: regFilter,
      },
      paramsSerializer: (params) => qs.stringify(params),
    })
    .then((apiResponse: AxiosResponse<ServiceResult<FineDetailDto[]>>) => {
      return apiResponse.data.data;
    });
};

const removeFinesFromProfile = (fineIds: Guid[]): Promise<void> => {
  const url = adminFineUrls.removeFinesFromProfileUrl();
  return authNetworkService.post(url, {
    fineIds,
  });
};

const getFineById = (fineId: Guid): Promise<GetFineByIdDto> => {
  const url = adminFineUrls.getFineById(fineId);
  return authNetworkService
    .get(url)
    .then((result: AxiosResponse<ServiceResult<GetFineByIdDto>>) => {
      return result.data.data;
    });
};

const moveToUnpaid = (fineId: Guid) => {
  const url = adminFineUrls.moveToUnpaidUrl(fineId);
  return authNetworkService.post(url);
};

const editFine = (fineId: string, formData: EditFineProps) => {
  const url = adminFineUrls.editFineUrl(fineId);
  return authNetworkService.put(url, formData).catch((error) => {
    throw error;
  });
};

const syncProfileFinesUrl = (userId: string) => {
  const url = adminFineUrls.syncProfileFinesUrl(userId);
  return authNetworkService.get(url, { timeout: 200000 }).catch((error) => {
    throw error;
  });
};

export default {
  getAdminFines,
  addFine,
  getIndividualFine,
  getIndividualFines,
  getFleetEntityFines,
  removeFinesFromProfile,
  getFineById,
  moveToUnpaid,
  getPaginatedFines,
  getVehicleRegistrations,
  editFine,
  syncProfileFinesUrl,
};
