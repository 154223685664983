import authNetworkService from '../auth-network/auth-network.service';
import correspondenceUrls from './correspondence.urls';

const optInWhatsApp = (phoneNumber: string): Promise<void> => {
  const url = `${correspondenceUrls.optInWhatsApp()}?PhoneNumber=${phoneNumber}`;
  return authNetworkService.post(url);
};

const optOutWhatsApp = (phoneNumber: string): Promise<void> => {
  const url = `${correspondenceUrls.optOutWhatsApp()}?PhoneNumber=${phoneNumber}`;
  return authNetworkService.post(url);
};

const optOutEmail = (email: string): Promise<void> => {
  const url = `${correspondenceUrls.optOutEmail(email)}`;
  return authNetworkService.post(url);
};

export default {
  optInWhatsApp,
  optOutWhatsApp,
  optOutEmail,
};
