import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { CircularProgress } from '@material-ui/core';
import fineSyncConfigurationService from '../../../services/api/fine-sync-configuration/fine-sync-configuration.service';
import { FineSyncConfigurationForm } from '../../forms/fine-sync-configuration-form/fine-sync-configuration-form';
import { QueryKeyMapping } from '../../../services/query-key-mapping';

export type FineSyncConfigurationOrganismProps = {
  closeModal: () => void;
};

export const FineSyncConfigurationOrganism: React.FC<FineSyncConfigurationOrganismProps> = ({
  closeModal,
}) => {
  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery(QueryKeyMapping.FineSyncConfiguration, () =>
    fineSyncConfigurationService.get(),
  );

  const { mutate, isLoading: isSubmitLoading } = useMutation(fineSyncConfigurationService.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeyMapping.FineSyncConfiguration);
      closeModal();
      toast.success('Successfully updated fine sync configuration');
    },
    onError: () => {
      toast.error('Failed to update fine sync configuration');
    },
  });

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between">
        <div className="text-xl">Fine Sync Configuration</div>
        <CloseIcon onClick={closeModal} className="text-text-grey cursor-pointer" />
      </div>
      <div className="border-b border-black w-full" />
      <div className="flex flex-grow">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <FineSyncConfigurationForm
            fineSyncConfiguration={{
              isAartoMoveToPaidThroughOtherAvenueEnabled:
                data.isAartoMoveToPaidThroughOtherAvenueEnabled,
            }}
            onSubmit={mutate}
            isSubmitLoading={isSubmitLoading}
          />
        )}
      </div>
    </div>
  );
};
