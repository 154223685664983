import React, { useState } from 'react';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { Button } from '../../../components/atoms';
import adminPaymentPartnersService from '../../../services/api/admin-payment-partners/admin-payment-partners.service';

const AdminPaymentPartnersPaymentsScreen: React.FC = () => {
  const [value, setValue] = useState<string>('');
  const [data, setData] = useState<number>(null);

  const settleFinesMutation = useMutation(() => adminPaymentPartnersService.settleFines(value), {
    onSuccess: () => {
      toast.success('Successfully settled invoice');
    },
    onError: () => {
      toast.error('Failed to settle invoice');
    },
  });

  const onChange = () => {
    setData(null);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onClickButtonGetTotal = async () => {
    await adminPaymentPartnersService
      .getPaymentPartnerReferenceTotal(value)
      .then((result) => setData(result))
      .catch(() => toast.error('Payment partner reference not found'));
  };

  const onClickSettleFines = () => {
    settleFinesMutation.mutate();
    setData(null);
  };

  return (
    <>
      <label className="flex flex-col gap-2 ml-4 mt-2" htmlFor="payment-partner-reference">
        Payment Reference:
        <input
          id="payment-partner-reference"
          className="border-2 border-solid w-96"
          onChange={onChange}
          onBlur={onBlur}
        />
        <Button
          onClick={onClickButtonGetTotal}
          type="text"
          className="text-white bg-secondary w-20"
        >
          Get Total
        </Button>
      </label>
      {data ? (
        <div className="flex flex-col ml-4 mt-5">
          <span>
            Total is: <span className="font-bold">{data}</span>
          </span>
          <span>Would you like to settle the amount?</span>
          <Button onClick={onClickSettleFines} type="text" className="text-white bg-secondary w-32">
            Settle Fines
          </Button>
        </div>
      ) : null}
    </>
  );
};
export default AdminPaymentPartnersPaymentsScreen;
