import React, { useState } from 'react';
import { Field, Formik, FormikHelpers } from 'formik';

import { FormHelperText } from '@material-ui/core';
import { Button, FormTextField } from '../../atoms';
import { ErrorObject, BaseFormProps, AddFleetEntityLinkProps } from '../../../types';
import { OffenderIdentifierType } from '../../../enums';
import { editFleetEntityLinkValidationSchema } from '../../../validators';

export const AddFleetEntityLinkForm: React.FC<BaseFormProps<AddFleetEntityLinkProps>> = ({
  initialValues,
  submitForm,
  onSuccess,
}) => {
  const [generalError, setGeneralError] = useState(null);
  const _handleFormSubmitError = (
    error: ErrorObject,
    actions: FormikHelpers<AddFleetEntityLinkProps>,
  ) => {
    actions.setSubmitting(false);
    setGeneralError(error.detail);
  };

  const _handleSubmission = (
    formData: AddFleetEntityLinkProps,
    actions: FormikHelpers<AddFleetEntityLinkProps>,
  ) => {
    submitForm(formData)
      .then(() => {
        onSuccess();
      })
      .catch((error: ErrorObject) => {
        _handleFormSubmitError(error, actions);
      })
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnBlur
        validateOnChange={false}
        onSubmit={_handleSubmission}
        enableReinitialize
        validationSchema={editFleetEntityLinkValidationSchema}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleReset,
          setFieldValue,
        }) => (
          <form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="flex flex-auto flex-col gap-3 py-1 mx-7"
          >
            <p className="flex justify-center auth-heading text-center">
              Add New Fleet Entity Link
            </p>
            {generalError && <FormHelperText error>{generalError}</FormHelperText>}
            <p className="flex justify-start text-left text-xs">Fleet Entity Link Type :</p>
            <Field
              as="select"
              name="type"
              value={values.identifierType}
              onChange={(e) => setFieldValue('identifierType', parseInt(e.target.value, 10))}
            >
              <option value={OffenderIdentifierType.Brn}>Brn</option>
              <option value={OffenderIdentifierType.ProxyId}>Proxy Id</option>
            </Field>
            <p className="flex justify-start text-left text-xs">Fleet Entity Link Id :</p>
            <FormTextField
              variableName="value"
              placeholder="ID"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />

            <Button
              isLoading={isSubmitting}
              onClick={handleSubmit}
              className="w-4/5 bg-secondary hover:bg-secondary-dark text-white text-base self-center my-7"
            >
              Add Fleet Entity Link
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};
