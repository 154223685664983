import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';

import { FormContainer, OnboardIndividualForm } from '../../../components';
import { OnboardIndividualProps } from '../../../types';
import { onboardIndividualAction } from '../../../reducers/user-reducer/user.actions';
import { useOnboardStatus } from '../../../react-queries';
import { OffenderIdentifierType } from '../../../enums';

const OnboardingIndividualScreen: React.FC = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const useOnboardStatusQuery = useOnboardStatus();
  const continueToBusiness = new URLSearchParams(window.location.search).get('both');
  const history = useHistory();
  const onboardIndividualFormInitialValues: OnboardIndividualProps = {
    idNumber: '',
    idType: 'ID/TRN',
    identifierType: OffenderIdentifierType.SaIdNumber,
    confirmIdNumber: '',
    familyMembers: [],
  };

  const _handleSubmit = (formData: OnboardIndividualProps) => {
    return dispatch(onboardIndividualAction(formData));
  };

  const _handleSuccess = () => {
    queryClient.invalidateQueries('filterData');
    if (continueToBusiness) {
      history.push('/onboard-business');
      return;
    }
    useOnboardStatusQuery.refetch();
    history.replace('/individual');
  };

  return (
    <>
      <FormContainer className="flex">
        <OnboardIndividualForm
          initialValues={onboardIndividualFormInitialValues}
          submitForm={_handleSubmit}
          onSuccess={_handleSuccess}
          onFailure={() => null}
        />
      </FormContainer>
    </>
  );
};

export default OnboardingIndividualScreen;
