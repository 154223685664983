import config from '../../../config';
import { FineFilterOptions, Guid } from '../../../types';

const backOfficeBaseV2Url = `${config.apiUrl(2)}/BackOffice/Fines`;
const backOfficeBaseV1Url = `${config.apiUrl(1)}/BackOffice/Fines`;

export default {
  getFineById: (fineId: Guid): string => `${backOfficeBaseV2Url}/${fineId}`,
  getPaginated: (): string => `${backOfficeBaseV2Url}`,
  getVehicleRegistrations: (): string => `${backOfficeBaseV1Url}/vehicle-registrations`,
  moveToUnpaidUrl: (fineId: Guid): string => `${backOfficeBaseV1Url}/${fineId}/move-to-unpaid`,
  getFinesUrl: (pageNum, pageEntries, search, filter, isNag) =>
    `${backOfficeBaseV1Url}?PageNumber=${pageNum}&PageEntries=${pageEntries}&filter.Search=${search}&filter.Filter=${filter}&filter.ByNag=${isNag}`,
  getPaginatedFinesUrl: (filterOptions: FineFilterOptions) =>
    `${backOfficeBaseV1Url}?PageNumber=${filterOptions.pageNum}&PageEntries=${filterOptions.pageEntries}&filter.NoticeNumber=${filterOptions.noticeNumber}&filter.VehicleRegistration=${filterOptions.vehicleRegistration}&filter.ByNag=${filterOptions.byNag}&filter.OffenderIdentifierValue=${filterOptions.offenderIdentifierValue}`,
  getIndividualFineUrl: (fineId): string => `${backOfficeBaseV1Url}/${fineId}`,
  addFineUrl: (): string => `${backOfficeBaseV1Url}`,
  editFineUrl: (fineId): string => `${backOfficeBaseV1Url}/${fineId}/update-fine`,
  removeFinesFromProfileUrl: (): string => `${backOfficeBaseV1Url}/remove-from-profile`,

  syncProfileFinesUrl: (userId): string =>
    `${config.apiUrl(1)}/BackOffice/FineSync/sync-fines/${userId}`,
  getIndividualFinesUrl: (): string => `${config.apiUrl(1)}/BackOffice/IndividualFines`,
  getLinkedEntityFines: (): string => `${config.apiUrl(1)}/BackOffice/FleetEntityFines`,
};
