import { useQuery, UseQueryResult } from 'react-query';

import { adminFineService } from '../../services';
import { PaginatedList, FineDetailDto, FineFilterOptions } from '../../types';

export const useAdminFinesData = (
  filterOptions: FineFilterOptions,
): UseQueryResult<PaginatedList<FineDetailDto>, unknown> => {
  return useQuery(['finesData', filterOptions], () =>
    adminFineService.getAdminFines(filterOptions),
  );
};
