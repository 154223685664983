export enum OffenderIdentifierType {
  SaIdNumber = 0,
  Brn = 1,
  Trn = 2,
  ProxyId = 3,
}

export enum OffenderIdentifierTypeDescription {
  'ID' = 0,
  'BRN' = 1,
  'TRN' = 2,
  'Proxy ID' = 3,
}
