import React from 'react';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { FormHelperText } from '@material-ui/core';

import { AddBlackListProps, BaseFormProps, ErrorObject } from '../../../types';
import { Button, FormTextField } from '../../atoms';

export const AddBlackListForm: React.FC<BaseFormProps<AddBlackListProps>> = ({
  initialValues,
  submitForm,
  onSuccess,
}) => {
  const _handleFormSubmitError = (
    error: ErrorObject,
    actions: FormikHelpers<AddBlackListProps>,
  ) => {
    actions.setSubmitting(false);
    actions.setStatus(error.detail);
  };

  const _handleSubmission = (
    formData: AddBlackListProps,
    actions: FormikHelpers<AddBlackListProps>,
  ) => {
    submitForm(formData)
      .then(() => {
        onSuccess();
      })
      .catch((error: ErrorObject) => {
        _handleFormSubmitError(error, actions);
      })
      .finally(() => actions.setSubmitting(false));
  };

  const AddBlackListSchema = Yup.object().shape({
    userId: Yup.string()
      .length(13, 'ID should be exactly 13 characters long')
      .required('ID Number is required'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      validateOnChange={false}
      onSubmit={_handleSubmission}
      validationSchema={AddBlackListSchema}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleReset,
        status,
      }) => {
        return (
          <form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="flex flex-auto flex-col gap-3 py-1 mx-7"
          >
            <p className="flex justify-center auth-heading text-center">User Details</p>
            {status && <FormHelperText error>{status}</FormHelperText>}
            <p className=" text-xs text-left">Identification Number :</p>
            <FormTextField
              variableName="userId"
              placeholder="Identification Number"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="self-center w-96"
            />
            <Button
              isLoading={isSubmitting}
              onClick={handleSubmit}
              className="w-4/5 bg-secondary hover:bg-secondary-dark text-white text-base self-center my-7"
            >
              Black List User
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
