import React, { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import _ from 'lodash';

import { Card, FormHelperText, IconButton } from '@material-ui/core';

import { Edit, HighlightOff } from '@material-ui/icons';
import { Button, FormTextField, OffenderTypeTextField } from '../../atoms';
import { editFamilyValidation } from '../../../validators';
import { ErrorObject, BaseFormProps, EditFamilyProps, FamilyMemberProps } from '../../../types';
import { OffenderIdentifierType, OffenderIdentifierTypeDescription } from '../../../enums';

export const EditFamilyForm: React.FC<BaseFormProps<EditFamilyProps>> = ({
  initialValues,
  submitForm,
  onSuccess,
  onFailure,
  editFamily,
}) => {
  const [generalError, setGeneralError] = useState(null);
  const [hasFriendsAndFamily, toggleFriendsAndFamily] = useState(!!editFamily);
  const names = ['firstName', 'lastName'];
  const placeholders = ['First Name', 'Last Name'];
  const icons = [
    <img src="assets/icons/user-icon.png" alt="userFirst" />,
    <img src="assets/icons/user-icon.png" alt="userLast" />,
  ];

  const _handleFormSubmitError = (error: ErrorObject, actions: FormikHelpers<EditFamilyProps>) => {
    actions.setSubmitting(false);
    setGeneralError(error.detail);
  };

  const _handleSubmission = (
    formData: EditFamilyProps,
    actions: FormikHelpers<EditFamilyProps>,
  ) => {
    submitForm(formData)
      .then(() => {
        actions.setSubmitting(false);
        onSuccess();
      })
      .catch((error: ErrorObject) => {
        _handleFormSubmitError(error, actions);
        onFailure();
      });
  };

  const individualProfileTypes = [OffenderIdentifierType.SaIdNumber, OffenderIdentifierType.Trn];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={editFamilyValidation}
      validateOnBlur
      validateOnChange
      onSubmit={_handleSubmission}
      enableReinitialize
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleReset,
        setFieldValue,
        validateField,
      }) => {
        const _addMember = () => {
          const familyMember = values.familyMembers[values.familyMembers.length - 1];

          const isValid = Object.keys(errors).length === 0;
          if (
            !_.isEmpty(familyMember?.firstName) &&
            !_.isEmpty(familyMember?.lastName) &&
            !_.isEmpty(familyMember?.idNumber) &&
            isValid
          ) {
            setFieldValue('familyMembers', [
              ...values.familyMembers,
              {
                firstName: '',
                lastName: '',
                idNumber: '',
                identifierType: OffenderIdentifierType.SaIdNumber,
              } as FamilyMemberProps,
            ]);
          }
        };

        const accountIdentifierInvalid = () => errors.idNumber || errors.confirmIdNumber;

        const _addFriendsAndFamily = () => {
          setFieldValue('familyMembers', [
            ...values.familyMembers,
            {
              firstName: '',
              lastName: '',
              idNumber: '',
              identifierType: OffenderIdentifierType.SaIdNumber,
            } as FamilyMemberProps,
          ]);
          toggleFriendsAndFamily(true);
        };

        const _editMember = (index: number) => () => {
          const familyMembers = _.cloneDeep(values.familyMembers);
          familyMembers.push(familyMembers.splice(index, 1)[0]);
          setFieldValue('familyMembers', familyMembers);
        };

        const _removeMember = (index: number) => () => {
          const myFamilyMembers = _.cloneDeep(values.familyMembers);
          myFamilyMembers.splice(index, 1);
          if (myFamilyMembers.length === 0) {
            myFamilyMembers.push({
              firstName: '',
              lastName: '',
              idNumber: '',
              identifierType: OffenderIdentifierType.SaIdNumber,
            });
          }
          setFieldValue('familyMembers', myFamilyMembers);
          setTimeout(() => validateField('familyMembers'), 200);
        };

        const lastMemberIndex =
          _.get(values.familyMembers, 'length', 1) === 1 ? 0 : values.familyMembers.length - 1;

        const _renderFamilyMembers = () => (
          <div className="grid gap-2">
            {_.map(names, (name, itemIndex) => {
              return (
                <FormTextField
                  key={`familyMember-${itemIndex}`}
                  variableName={`familyMembers.${lastMemberIndex}.${name}`}
                  placeholder={placeholders[itemIndex]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  icon={icons[itemIndex]}
                  className="w-full"
                />
              );
            })}
            <div className="flex w-full">
              <OffenderTypeTextField
                variableType={`familyMembers[${lastMemberIndex}].identifierType`}
                variableTypes={individualProfileTypes}
                variableName={`familyMembers.${lastMemberIndex}.idNumber`}
                values={values}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched}
                errors={errors}
                required
                className="mb-2 w-full"
                containerClassName="flex w-full"
                // Due to the form's flow, empty forms are removed at the end
                // which causes undefined error on placeholder. Need to revise at some point.
                placeholder={
                  OffenderIdentifierTypeDescription[
                    values.familyMembers[lastMemberIndex]?.identifierType ??
                      OffenderIdentifierType.SaIdNumber
                  ]
                }
              />
            </div>
          </div>
        );

        const renderErrorMessage = (index, type) => {
          if (_.isEmpty(errors?.familyMembers)) {
            return null;
          }
          if (_.get(errors.familyMembers[index], 'firstName', false) && type === 'name') {
            return (
              <p className="text-xs font-normal text-red-500">
                {_.get(errors.familyMembers[index], 'firstName', '')}
              </p>
            );
          }
          if (_.get(errors.familyMembers[index], 'lastName', false) && type === 'name') {
            return (
              <p className="text-xs font-normal text-red-500">
                {_.get(errors.familyMembers[index], 'lastName', '')}
              </p>
            );
          }
          if (_.get(errors.familyMembers[index], 'idNumber', false) && type === 'id') {
            return (
              <p className="text-xs font-normal text-red-500">
                {_.get(errors.familyMembers[index], 'idNumber', '')}
              </p>
            );
          }
          return null;
        };

        const _renderSubmittedFamilyMembers = () =>
          _.map(values.familyMembers, (familyMember, index) => {
            if (familyMember.firstName !== null && familyMember.firstName !== '') {
              return (
                <Card className="flex mt-1.5" key={crypto.randomUUID()}>
                  <div className="p-2">
                    <img src="assets/icons/id-card-icon.png" alt="companyReg" />
                  </div>
                  <div className="text-left flex flex-col justify-center">
                    <p className="text-xs font-bold text-text-grey">
                      {familyMember.firstName} {familyMember.lastName}
                    </p>
                    {renderErrorMessage(index, 'name')}
                    <p className="text-xs font-thin text-text-grey">
                      {`${OffenderIdentifierTypeDescription[familyMember.identifierType]}: ${
                        familyMember.idNumber
                      }`}
                    </p>
                    {renderErrorMessage(index, 'id')}
                  </div>
                  <div className="flex flex-1" />
                  <IconButton onClick={_editMember(index)} className="m-1">
                    <Edit className="h-4 w-4" />
                  </IconButton>
                  <IconButton onClick={_removeMember(index)} className="m-1">
                    <HighlightOff className="h-4 w-4" />
                  </IconButton>
                </Card>
              );
            }

            return <div key={crypto.randomUUID()} />;
          });

        const editFamilyAndFriendLayout = () => {
          return (
            <>
              <div className="grid gap-2 mt-2">
                {!editFamily && (
                  <div>
                    <FormTextField
                      inputClassName="rounded-l-none border-l-none"
                      placeholder={`${_.get(values, 'idType')} Number`}
                      values={values}
                      icon={<img src="assets/icons/id-card-icon.png" alt="idNumber" />}
                      variableName="idNumber"
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="w-full"
                    />
                  </div>
                )}
                <div className="flex justify-center ">
                  <p className="w-full text-2xl text-text-grey text-center ">Friends & Family</p>
                </div>

                <div className="overflow-y-auto max-h max-h-48">
                  {_renderSubmittedFamilyMembers()}
                </div>

                {_renderFamilyMembers()}
                <Button onClick={_addMember} className="text-secondary font-thin" type="text">
                  + Add another
                </Button>
                {errors.familyMembers && errors.familyMembers?.length > 4 && (
                  <p className="text-xs font-normal text-red-500">{errors.familyMembers}</p>
                )}
              </div>
            </>
          );
        };

        const _renderFriendsAndFamilyMembersForm = () => (
          <>
            <div className="overflow-y-auto">{editFamilyAndFriendLayout()}</div>
          </>
        );

        const removeEmptyForms = () => {
          if (values.familyMembers.length === 1) return;
          const validFamilyMembers = [];
          _.forEach(values.familyMembers, (member: Record<string, unknown>) => {
            if (
              !_.isEqual(Object.values(member), ['', '', '', OffenderIdentifierType.SaIdNumber])
            ) {
              validFamilyMembers.push(member);
            }
          });
          values.familyMembers = validFamilyMembers;
        };

        const cleanFamilyMembers = () => {
          const isValid = Object.keys(errors.familyMembers ?? {}).length === 0;
          if (!isValid) return;

          removeEmptyForms();
          handleSubmit();
        };

        return (
          <form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="auth-form-body w-full text-center h-full"
          >
            <div className="flex flex-col flex-1 w-4/5 overflow-y-auto">
              {generalError && <FormHelperText error>{generalError}</FormHelperText>}
              {!hasFriendsAndFamily ? (
                <div className="grid gap-4 w-full">
                  <FormTextField
                    inputClassName="rounded-l-none border-l-none"
                    placeholder={`${_.get(values, 'idType')} Number`}
                    values={values}
                    icon={<img src="assets/icons/id-card-icon.png" alt="idNumber" />}
                    variableName="idNumber"
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full"
                  />
                  <div className="flex flex-row">
                    <div className="flex flex-1" />
                    <Button
                      onClick={_addFriendsAndFamily}
                      className="text-secondary -mt-6"
                      type="text"
                    >
                      + Add Friends & Family
                    </Button>
                  </div>
                </div>
              ) : (
                <>{_renderFriendsAndFamilyMembersForm()}</>
              )}
              {accountIdentifierInvalid() && (
                <span className="text-xs text-red-500">
                  Please update your account details and provide a valid identification number
                </span>
              )}
              <div className="flex flex-1" />
              <Button
                isLoading={isSubmitting}
                onClick={cleanFamilyMembers}
                className="auth-form-submit-button mt-2"
              >
                Continue
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
