import { AxiosResponse } from 'axios';
import { ServiceResult } from '../../../types';
import authNetworkService from '../auth-network/auth-network.service';
import paymentPartnerUrls from './admin-payment-partners.urls';

const getPaymentPartnerReferenceTotal = (paymentPartnerReference: string): Promise<number> => {
  const url = paymentPartnerUrls.getTotal(paymentPartnerReference);

  return authNetworkService.get(url).then((apiResponse: AxiosResponse<ServiceResult<number>>) => {
    return apiResponse.data.data;
  });
};

const settleFines = (paymentPartnerReference: string) => {
  const url = paymentPartnerUrls.settleFines(paymentPartnerReference);

  return authNetworkService.post(url);
};

export default {
  getPaymentPartnerReferenceTotal,
  settleFines,
};
