import * as qs from 'qs';
import { AxiosResponse } from 'axios';
import statementRunUrls from './statement-run.urls';
import authNetworkService from '../auth-network/auth-network.service';
import { ServiceResult } from '../../../types';
import { StatementRunDto } from '../../../types/data-transfer-objects/dto.types';

export const get = async (): Promise<StatementRunDto[]> => {
  const url = statementRunUrls.baseUrl();

  const response = await authNetworkService.get<ServiceResult<StatementRunDto[]>>(url);
  return response.data.data;
};

export const downloadStatementRunStatementsCreated = async (
  statementRunId: string,
): Promise<AxiosResponse<Blob>> => {
  const url = statementRunUrls.baseStatementRunStatementsContentUrl(statementRunId);

  return authNetworkService.get(url, {
    responseType: 'blob',
    timeout: 0,
    headers: { Accept: 'application/csv' },
    params: {
      groupedBy: ['Date'],
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  });
};

export const downloadStatementRunReportEntriesGroupedByMunicipality = async (
  statementRunId: string,
  offenceDate: Date,
): Promise<AxiosResponse<Blob>> => {
  const url = statementRunUrls.baseStatementRunReportEntriesContentUrl(statementRunId);

  return authNetworkService.get(url, {
    responseType: 'blob',
    timeout: 0,
    headers: { Accept: 'application/csv' },
    params: {
      offenceDate,
      groupedBy: ['Municipality', 'ServiceProvider'],
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  });
};

export default {
  get,
  downloadStatementRunReportEntriesGroupedByMunicipality,
  downloadStatementRunStatementsCreated,
};
