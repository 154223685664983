import React, { ChangeEvent } from 'react';
import toast from 'react-hot-toast';

type FileUploadProps = {
  file: File;
  onChange: (event: File) => void;
  type?: 'csv' | 'xlsx'; // TODO: Convert to enum
};

export const FileUpload: React.FC<FileUploadProps> = ({ onChange, file, type = 'csv' }) => {
  const onChangeDropZoneFile = (changeEvent: ChangeEvent<HTMLInputElement>): void => {
    const inputFile = changeEvent.target.files[0];

    if (inputFile.name.slice(inputFile.name.lastIndexOf('.')) !== `.${type}`) {
      toast.error('Incorrect file type');
      return;
    }

    onChange(inputFile);
  };

  return (
    // label propogates event through to input click handler. Not sure what is causing this, but just replacing label with div breaks the click upload handling
    <label className="flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
      <div className="flex flex-col justify-center items-center pt-5 pb-6">
        <svg
          aria-hidden="true"
          className="mb-3 w-10 h-10 text-gray-400"
          fill="none"
          stroke={!file ? 'currentColor' : 'green'}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
          />
        </svg>
        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
          <span className="font-semibold">Click to upload</span> or drag and drop
        </p>
        <p className="text-xs text-gray-500 dark:text-gray-400">{type.toUpperCase()} (MAX. 2GB)</p>
      </div>
      <input id="dropzone-file" onChange={onChangeDropZoneFile} type="file" className="hidden" />
    </label>
  );
};
