import React from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useDownloadFile } from '../../../../hooks/global';
import statementRunService from '../../../../services/api/statement-run/statement-run.service';
import { InternalSelect } from '../../../atoms/internal-select/internal-select';
import { KeyValuePair } from '../../../../types/common.types';
import { Button, InternalHookFormDateTimePicker } from '../../../atoms';

type FinesPerStatementByMunicipalityReportFormProps = {
  defaultValues: {
    statementRunId: string;
    offenceDate: Date;
  };
  selectValues: KeyValuePair<string>[];
};

export const FinesPerStatementByMunicipalityReportForm: React.FC<FinesPerStatementByMunicipalityReportFormProps> =
  ({ defaultValues, selectValues }) => {
    const { offenceDate, statementRunId } = defaultValues;
    const {
      control,
      register,
      getValues,
      formState: { errors, touchedFields },
    } = useForm({
      defaultValues: { offenceDate, statementRunId },
    });

    const { download, loading } = useDownloadFile({
      apiDefinition: () => {
        const formValues = getValues();
        return statementRunService.downloadStatementRunReportEntriesGroupedByMunicipality(
          formValues.statementRunId,
          formValues.offenceDate,
        );
      },
      onError: () => toast.error('Could not download report'),
    });

    return (
      <form className="flex flex-col justify-start items-start bg-gray-100 shadow-lg p-4 gap-4">
        <span className="w-full text-xl mb-2">Download Statement - Fines by Municipality</span>

        <InternalSelect
          variableName="statementRunId"
          options={selectValues.map((item) => ({ value: item.value, key: item.key }))}
          register={register}
        />

        <label htmlFor="filter-date-from" className="w-4/5 flex flex-col ">
          Offence Date From
          <InternalHookFormDateTimePicker
            id="filter-date-from"
            control={control}
            variableName="offenceDate"
            errors={errors}
            touchedFields={touchedFields}
          />
        </label>
        <Button
          isLoading={loading}
          onClick={download}
          className="bg-tertiary hover:bg-tertiary-dark text-white text-base self-center w-auto"
        >
          Download
        </Button>
      </form>
    );
  };
