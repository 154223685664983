import React, { useMemo, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  SwipeableDrawer,
} from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

import { useImmerAtom } from 'jotai/immer';
import { Draft } from 'immer';
import { useImmer } from 'use-immer';
import { PaginationButtons, SearchField } from '../../../components';
import { BasicTable } from '../../../components/table/adminTable/basicTable';
import { useAdminFinesData } from '../../../react-queries/admin-fines-data/admin-fines-data.queries';
import { AddFineProps, FineDetailDto, FineFilterOptions } from '../../../types';
import { adminFineService } from '../../../services';
import { AddFinesForm } from '../../../components/forms/add-fines-form/add-fines-form';
import { QueryParamSearch, searchParamsAtom } from '../admin-users/users.screen';

Modal.setAppElement('#root');

const FinesScreen: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const history = useHistory();
  const _handleSuccess = () => {
    setTimeout(function waitForToast() {
      history.push('/admin/fines');
    }, 2500);
  };

  const _signUpSubmission = (formData: AddFineProps) => {
    return adminFineService.addFine(formData);
  };

  const _signUpFail = () => null;

  const [fineFilterState, setFineFilterState] = useImmer<FineFilterOptions>({
    byNag: false,
    noticeNumber: '',
    vehicleRegistration: '',
    offenderIdentifierValue: '',
    pageNum: 1,
    pageEntries: 10,
  });

  const setPage = (pageNumber: number) => {
    setFineFilterState({
      ...fineFilterState,
      pageNum: pageNumber,
    });
  };

  const finesQuery = useAdminFinesData(fineFilterState);
  const [, setSearchParams] = useImmerAtom(searchParamsAtom);
  const columns = useMemo(
    () => [
      {
        Header: 'Ref No',
        accessor: 'noticeNumber',
      },
      {
        Header: 'Reg No',
        accessor: 'vehicleRegistration',
      },
      {
        Header: 'Area',
        accessor: 'area',
      },
      {
        Header: 'Fine Amount',
        accessor: 'fineAmount',
      },
      {
        Header: 'Paid',
        accessor: (fine: FineDetailDto) => (fine.isPaid ? 'Yes' : 'No'),
      },
      {
        Header: 'Discount',
        accessor: 'discount',
      },
      {
        Header: 'Other Fees',
        accessor: 'otherFees',
      },
      {
        Header: 'Total',
        accessor: 'total',
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="mt-2 flex justify-start mx-8 text-xl">Fines</div>
        <Button
          className="mt-2 flex bg-tertiary text-white hover:bg-tertiary-dark mr-8"
          size="small"
          startIcon={<AddCircleOutline />}
          onClick={() => setDrawerOpen(true)}
        >
          Add New
        </Button>
      </div>
      <div className="border-b border-black mt-2 flex justify-start mx-8" />
      <div className="flex justify-start mx-8 mt-2 space-x-2">
        <SearchField
          placeholder="Notice Number"
          value={fineFilterState.noticeNumber}
          onChange={(value) => {
            setFineFilterState({
              ...fineFilterState,
              noticeNumber: value,
              pageNum: 1,
            });
          }}
          isLoading={finesQuery.isFetching}
        />
        <SearchField
          placeholder="OffenderIdentifier"
          value={fineFilterState.offenderIdentifierValue}
          onChange={(value) => {
            setFineFilterState({
              ...fineFilterState,
              offenderIdentifierValue: value,
              pageNum: 1,
            });
          }}
          isLoading={finesQuery.isFetching}
        />
        <SearchField
          placeholder="Vehicle Registration"
          value={fineFilterState.vehicleRegistration}
          onChange={(value) => {
            setFineFilterState({
              ...fineFilterState,
              vehicleRegistration: value,
              pageNum: 1,
            });
          }}
          isLoading={finesQuery.isFetching}
        />
        <FormControlLabel
          control={
            <Checkbox
              value={fineFilterState.byNag}
              onChange={() =>
                setFineFilterState({
                  ...fineFilterState,
                  byNag: !fineFilterState.byNag,
                  pageNum: 1,
                })
              }
            />
          }
          label="Only NAGs"
        />
      </div>
      {!finesQuery.isLoading && (
        <>
          <div className="flex flex-col flex-grow mx-8 mt-2">
            <Card className="flex flex-grow shadow-2xl">
              <CardContent className="flex flex-col flex-grow p">
                <BasicTable
                  tableData={finesQuery.data.items}
                  tableColumns={columns}
                  path="./individual/fine"
                  isViewable
                  linkToAccounts={{
                    action: (data) => {
                      setSearchParams((draft: Draft<QueryParamSearch>) => {
                        draft.individualOffenderIdentifier = data.offenderIdentifier;
                      });
                    },
                  }}
                />
              </CardContent>
            </Card>
          </div>
          <PaginationButtons
            page={fineFilterState.pageNum}
            setPage={setPage}
            pageIndex={finesQuery.data.pageIndex}
            totalPages={finesQuery.data.totalPages}
          />
        </>
      )}

      <SwipeableDrawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
      >
        <AddFinesForm
          initialValues={{
            identificationNumber: '',
            identificationType: 'SaIdNumber',
            vehicleRegistration: '',
            noticeNumber: '',
            offenceDate: new Date(),
            municipality: '',
            offenceLocation: '',
            offenceDescription: '',
            amountDue: 0,
            isNag: false,
            manualDiscount: null,
          }}
          submitForm={_signUpSubmission}
          onSuccess={() => {
            _handleSuccess();
            setDrawerOpen(false);
          }}
          onFailure={_signUpFail}
        />
      </SwipeableDrawer>
    </>
  );
};
export default FinesScreen;
