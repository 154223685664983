import config from '../../../config';
import { Guid } from '../../../types';

const baseUrl = `${config.apiUrl()}/StatementRun`;

export default {
  baseUrl: (): string => `${baseUrl}`,
  baseStatementRunStatementsContentUrl: (statementRunId: Guid): string =>
    `${baseUrl}/${statementRunId}/Statements/Content`,
  baseStatementRunReportEntriesContentUrl: (statementRunId: Guid): string =>
    `${baseUrl}/${statementRunId}/ReportEntries/Content`,
};
