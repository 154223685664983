import React, { ReactElement, ChangeEvent, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import _ from 'lodash';
import { FormTextField } from '..';
import { OffenderIdentifierTypeDescription } from '../../../enums';

type Props = {
  variableName: string;
  variableType: string;
  variableTypes: number[];
  placeholder?: string;
  type?: string;
  values: Record<string, unknown>;
  onChange: {
    (e: ChangeEvent<any>): void;
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  onBlur: {
    (e: ChangeEvent<any>): void;
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  touched: Record<string, unknown>;
  errors: Record<string, unknown>;
  className?: string;
  icon?: ReactElement<any, any>;
  endIcon?: ReactElement<any, any>;
  required?: boolean;
  openInitial?: boolean;
  containerClassName?: string;
};

export const OffenderTypeTextField: React.FC<Props> = ({
  variableTypes = [],
  variableType = '',
  placeholder = '',
  values,
  endIcon,
  icon,
  className,
  openInitial = false,
  containerClassName = '',
  ...rest
}) => {
  const offenderIdentifierDescription = (output): string => {
    return OffenderIdentifierTypeDescription[output];
  };

  const _selectItems = _.map(variableTypes, (variable, index) => (
    <MenuItem key={index} value={variable} className="font-thin">
      {offenderIdentifierDescription(variable)}
    </MenuItem>
  ));
  const [menuOpen, setMenuOpen] = useState(openInitial);

  return (
    <div className={containerClassName !== '' ? containerClassName : 'flex w-full mr-1'}>
      <span>{values.variableType}</span>
      <FormTextField
        {...rest}
        variableName={variableType}
        className="w-32 -mr-1 mb-2 "
        select
        selectProps={{
          onClick: () => setMenuOpen(!menuOpen),
          MenuProps: {
            open: menuOpen,
            onClose: () => setMenuOpen(false),
            onClick: () => setMenuOpen(!menuOpen),
          },
        }}
        values={values}
        inputClassName="rounded-r-none bg-gray-300 font-thin "
      >
        {_selectItems}
      </FormTextField>

      <FormTextField
        inputClassName="rounded-l-none border-l-none "
        placeholder={placeholder}
        values={values}
        endIcon={endIcon}
        icon={icon}
        className={`${className}`}
        {...rest}
      />
    </div>
  );
};
