import authNetworkService from '../auth-network/auth-network.service';
import whatsappWhitelistProcessUrls from './whatsapp-whitelist-process.urls';

const uploadFile = (file: File, email: string) => {
  const url = whatsappWhitelistProcessUrls.uploadFile();
  const form = new FormData();
  form.append('File', file);
  form.append('NotifyEmail', email);
  return authNetworkService.post(url, form, {
    timeout: 0,
  });
};

export default {
  uploadFile,
};
