import { useQuery, UseQueryResult } from 'react-query';

import { adminFineService } from '../../services';
import { FineDetailDto } from '../../types';

export const useAdminIndividualFinesData = (
  fineId: string,
): UseQueryResult<FineDetailDto, unknown> => {
  return useQuery(['finesIndividualData', fineId], () =>
    adminFineService.getIndividualFine(fineId),
  );
};
