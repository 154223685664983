import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core';
import { Provider } from 'react-redux';
import { QueryClientProvider, QueryClient } from 'react-query';

import './index.css';
import './theme/theme.css';
import { themeMui } from './theme/material-ui.theme';
import { store } from './reducers/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { addPrototypes } from './helpers/prototype';

const queryClient = new QueryClient();

addPrototypes();

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={themeMui}>
      <StylesProvider injectFirst>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </Provider>
      </StylesProvider>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
