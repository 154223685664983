import React from 'react';
import { PaidTableRow } from '..';
import { FineDetailDto, PaidFine } from '../../../types';

type Props = {
  className?: string;
  fine: PaidFine | FineDetailDto; // Todo: Remove PaidFine once individual screen has been refactored
};

export const PaidFineSummary: React.FC<Props> = ({ className, fine }) => {
  return (
    <PaidTableRow
      key={fine.id}
      className={`my-2 ${className}`}
      amountPaid={fine.amountPaid}
      date={fine.date.toLocaleString()}
      fineAmount={fine.fineAmount}
      noticeNumber={fine.noticeNumber}
      paymentType={fine.paymentType}
      vehicleRegistration={fine.vehicleRegistration}
    />
  );
};
