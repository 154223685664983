import config from '../../../config';

const baseUrl = `${config.apiUrl(1)}/BackOffice/PaymentPartnerPayments`;

export default {
  getTotal: (paymentPartnerReference: string): string =>
    `${baseUrl}/total?PaymentPartnerReferenceNumber=${paymentPartnerReference}`,
  settleFines: (paymentPartnerReference: string): string =>
    `${baseUrl}/mark-as-settled?PaymentPartnerReferenceNumber=${paymentPartnerReference}`,
};
