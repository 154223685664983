import React from 'react';
import { useHistory } from 'react-router';

import { useMutation } from 'react-query';
import { FormContainer, OnboardBusinessForm } from '../../../components';
import { OnboardBusinessProps } from '../../../types';
import { profilesService } from '../../../services';
import { useOnboardStatus } from '../../../react-queries';
import { OffenderIdentifierType } from '../../../enums';

const OnboardingBusinessScreen: React.FC = () => {
  const useOnboardStatusQuery = useOnboardStatus();
  const history = useHistory();
  const onboardBusinessInitialValues: OnboardBusinessProps = {
    businessEntities: [
      {
        entityName: '',
        address: '',
        vatNo: '',
        companyRegistration: '',
        links: [{ value: '', identifierType: OffenderIdentifierType.Brn }],
      },
    ],
  };

  const useOnboardBusinessMutation = useMutation(
    (formData: OnboardBusinessProps) => profilesService.onboardBusiness(formData),
    {
      onSuccess: () => {
        useOnboardStatusQuery.refetch();
        history.push('/business');
      },
    },
  );
  return (
    <>
      <FormContainer className="w-4/12 flex">
        <OnboardBusinessForm
          initialValues={onboardBusinessInitialValues}
          onFailure={() => null}
          onSuccess={() => null}
          submitForm={useOnboardBusinessMutation.mutate}
        />
        <div className="flex flex-1 flex-col" />
      </FormContainer>
    </>
  );
};

export default OnboardingBusinessScreen;
