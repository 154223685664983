import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Spinner } from 'react-activity';
import { Chip } from '@material-ui/core';
import { useDownloadFile } from '../../../hooks/global';
import {
  EditCallCentreProfileFormFields,
  CallOutcomeFormFields,
  FinishCallProps,
  CallOutcome,
  CallOutcomeDescriptionEnum,
} from '../../../types';
import { EditCallCentreProfileForm, CallOutcomeForm } from '../../forms/call-centre-forms';
import { decodedToken, sessionStorageService } from '../../../services';

import callsService from '../../../services/api/admin-calls/admin-calls.service';
import dailyCallsService from '../../../services/api/admin-daily-calls/admin-daily-calls.service';
import profilesService from '../../../services/api/profiles/profiles.service';
import { Button } from '../../atoms';
import ConditionalRenderer from '../../atoms/conditional-renderer/conditional-renderer';
import { dateTimeFormatterGmtZoneOffset } from '../../../helpers';
import euphoriaCallsService from '../../../services/api/euphoria-calls/euphoria-calls.service';
import { ClientNetworkError, ServerNetworkError } from '../../../exceptions';
import { StorageKey } from '../../../helpers/storage-keys';

export const CallCentreOrganism: React.FC = () => {
  const [isCreateCallLoading, setIsCreateCallLoading] = useState(false);
  const [isProfileUpdateLoading, setIsProfileUpdateLoading] = useState(false);

  const userId = decodedToken.decodedTokenUserId();
  const queryClient = useQueryClient();
  const [newCallId, setNewCallId] = useState(
    sessionStorageService.getItem(StorageKey.CurrentCallId),
  );
  const [pageNumber, setPageNumber] = useState(1);

  const { download: downloadCallLeftReport, loading: isLoadingCallLeftReport } = useDownloadFile({
    apiDefinition: () => dailyCallsService.getAllDailyCallsContent(userId),
    onError: () => toast.error('Could not download report'),
  });
  const currentDailyCallId = sessionStorageService.getItem(StorageKey.CurrentDailyCallId) as string;

  const { data: agentNextCallData, isFetching: isFetchingNextCall } = currentDailyCallId
    ? useQuery('dailyCall', () => dailyCallsService.getById(currentDailyCallId), {
        refetchOnWindowFocus: false,
      })
    : useQuery('agentNextCall', dailyCallsService.getAgentNextCall, {
        refetchOnWindowFocus: false,
      });

  const { data: callContactCallsData, isFetching: isFetchingCallHistory } = useQuery(
    ['callContactCallHistory', pageNumber, agentNextCallData?.callContactId],
    () => callsService.getCalls(pageNumber, 10, null, agentNextCallData?.callContactId),
    {
      enabled: !!agentNextCallData?.userId,
    },
  );

  const { data: agentDailyCallsTotalData } = useQuery(
    'agentDailyCallsTotal',
    () => callsService.getCalls(1, 1, userId, null, new Date(new Date().setUTCHours(0, 0, 0, 0))),
    {
      enabled: !!userId,
    },
  );

  const { mutate: dialMutate, isLoading: dialIsLoading } = useMutation<
    string,
    ClientNetworkError | ServerNetworkError,
    string
  >('dial', (phoneNumber) => euphoriaCallsService.createCall(phoneNumber), {
    onSuccess: () => {
      toast.success('Call has been successfully initiated');
    },
    onError: (error) => {
      toast.error(`An error occurred initiating the call`);
      if (error instanceof ClientNetworkError) {
        toast.error(error.serviceResultError.message);
      }
    },
  });

  const _createCall = () => {
    setIsCreateCallLoading(true);

    callsService.createCall(agentNextCallData?.callContactId, userId).then((id) => {
      sessionStorageService.setItem(StorageKey.CurrentDailyCallId, agentNextCallData.dailyCallId);
      sessionStorageService.setItem(StorageKey.CurrentCallId, id);
      setNewCallId(id);

      setIsCreateCallLoading(false);

      toast.success('Started new call successfully.');
    });
  };

  const _finishCall = (co: CallOutcomeFormFields) => {
    setIsCreateCallLoading(true);

    const finishCallProps: FinishCallProps = {
      callContactId: agentNextCallData.callContactId,
      callId: newCallId as string,
      callOutcome: +co.callOutcome,
      durationInSeconds: co.durationInSeconds,
      doNotContact: co.doNotContact,
      callBackDate: co.callBack ? co.callBackDate : null,
    };

    callsService.finishCall(finishCallProps).then(() => {
      queryClient.invalidateQueries('agentNextCall');
      queryClient.invalidateQueries('callContactCallHistory');
      queryClient.invalidateQueries('agentDailyCallsTotal');

      setNewCallId(null);
      setIsCreateCallLoading(false);
      sessionStorageService.removeItem(StorageKey.CurrentDailyCallId);
      sessionStorageService.removeItem(StorageKey.CurrentCallId);

      toast.success('Call completed successfully.');
    });
  };

  const _updateProfile = async (account: EditCallCentreProfileFormFields) => {
    setIsProfileUpdateLoading(true);

    return profilesService.editCallCenterAccount(account).then(() => {
      setIsProfileUpdateLoading(false);
      toast.success('Contact updated successfully.');
    });
  };

  const onClickBack = () => {
    setPageNumber(pageNumber - 1);
  };

  const onClickNext = () => {
    setPageNumber(pageNumber + 1);
  };

  return (
    <div>
      <div className="flex flex-row justify-between bg-grey-brand-1 h-10">
        <div className="flex justify-start items-center text-base font-semibold pl-8">
          Call Centre
        </div>
        <div className="flex pr-8">
          <Chip
            label={`${agentDailyCallsTotalData?.totalCount ?? 0} calls completed`}
            className="bg-disabled mb-1 xs:mb-0 h-5 text-sm self-center w-auto mr-3 rounded-sm"
            variant="outlined"
          />
          {newCallId != null && (
            <Button
              isLoading={dialIsLoading}
              onClick={() => dialMutate(agentNextCallData.phoneNumber)}
              className="bg-tertiary hover:bg-primary-dark text-white text-xs self-center w-auto mr-3 rounded-sm"
              spinnerSize={10.5}
            >
              Dial
            </Button>
          )}
          <Button
            isLoading={isCreateCallLoading}
            onClick={_createCall}
            className="bg-primary hover:bg-primary-dark text-white text-xs self-center w-auto mr-3 rounded-sm"
            spinnerSize={10.5}
            disabled={newCallId != null}
          >
            Call Now
          </Button>
          <Button
            isLoading={isLoadingCallLeftReport}
            onClick={downloadCallLeftReport}
            className="bg-secondary hover:bg-secondary-dark text-white text-xs self-center w-auto rounded-sm"
            spinnerSize={10.5}
          >
            Call Left Report
          </Button>
        </div>
      </div>
      <div className="mx-8">
        <div className="card flex flex-row py-3">
          <div className="flex flex-col flex-grow w-2/3 mr-5">
            <div className="flex flex-row mb-5 gap-3">
              <div className="flex flex-col w-1/2">
                <div className="w-full bg-secondary px-2 py-1 text-white text-sm font-medium">
                  Contact
                </div>
                <div className="flex border-solid border-2 border-t-0 min-h-86">
                  {!isFetchingNextCall ? (
                    <EditCallCentreProfileForm
                      defaultValues={{
                        userId: agentNextCallData?.userId,
                        firstName: agentNextCallData?.firstName,
                        lastName: agentNextCallData?.lastName,
                        email: agentNextCallData?.email,
                        cellphoneNumber: agentNextCallData?.phoneNumber,
                        partner: agentNextCallData?.partner,
                      }}
                      onSubmit={_updateProfile}
                      isSubmitLoading={isProfileUpdateLoading}
                    />
                  ) : (
                    <div className="flex flex-grow items-center justify-center">
                      <Spinner />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col flex-grow">
                <div className="bg-secondary px-2 py-1 text-white text-sm font-medium">
                  Call Outcome
                </div>
                <div className="flex flex-grow border-solid border-2 border-t-0 ">
                  {newCallId != null && !isFetchingNextCall ? (
                    <CallOutcomeForm
                      defaultValues={{
                        callDate: new Date().toLocaleString(),
                        agentFullName: agentNextCallData?.agentFullName ?? '',
                        callOutcome: 0,
                        durationInSeconds: 0,
                        doNotContact: false,
                        callBack: false,
                      }}
                      onSubmit={_finishCall}
                      isSubmitLoading={isCreateCallLoading}
                      callOutcomeSelectValues={Object.keys(CallOutcomeDescriptionEnum)
                        .filter((key) => Number.isNaN(Number(key)))
                        .map((val, key) => {
                          return {
                            value: val,
                            key: `${key}`,
                          };
                        })}
                    />
                  ) : (
                    <div className="p-3 text-xs">Waiting for call to start...</div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full bg-secondary px-2 py-1 text-white text-sm font-medium">
              General Notes
            </div>
            <div className="w-full h-24 border-solid border-2 border-t-0">
              <div className="p-3">{agentNextCallData?.notes}</div>
            </div>
          </div>
          <div className="flex flex-col w-1/3">
            <div className="w-full bg-secondary px-2 py-1 text-white text-sm font-medium">
              Call History
            </div>
            <div className="flex border-solid border-2 border-t-0 min-h-100">
              <div className="flex flex-col flex-grow">
                {!isFetchingCallHistory && callContactCallsData?.items ? (
                  <div className="flex flex-col flex-grow">
                    <div className="grid grid-cols-3 px-1 bg-grey-brand-1 h-10 items-center">
                      <span className="font-bold text-xs">Date & Time</span>
                      <span className="font-bold text-xs">Agent</span>
                      <span className="font-bold text-xs">Call Outcome</span>
                    </div>
                    {callContactCallsData.items.length > 0 ? (
                      <div className="grid grid-cols-3">
                        {callContactCallsData.items.map((call, index) => (
                          // Update to use call id
                          // eslint-disable-next-line react/no-array-index-key
                          <React.Fragment key={index}>
                            <div
                              className={`flex items-center text-xs h-9 pl-1 ${
                                index % 2 !== 0 ? 'bg-grey-brand-2' : ''
                              }`}
                            >
                              {dateTimeFormatterGmtZoneOffset(call.callDate, '/', 2)}
                            </div>
                            <div
                              className={`flex items-center text-xs h-9 ${
                                index % 2 !== 0 ? 'bg-grey-brand-2' : ''
                              }`}
                            >
                              {call.agentFirstName}
                            </div>
                            <div
                              className={`flex items-center text-xs h-9 pr-1 ${
                                index % 2 !== 0 ? 'bg-grey-brand-2' : ''
                              }`}
                            >
                              {CallOutcome[call.callOutcome as CallOutcome]
                                .replace(/([A-Z])/g, ' $1')
                                .trim()}
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    ) : (
                      <div className="min-h-90">
                        <span className="text-xs ml-2">No calls found for User</span>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="flex flex-grow items-center justify-center">
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center text-sm">
              <ConditionalRenderer condition={callContactCallsData?.totalPages > 0}>
                <Button
                  type="text"
                  className="bg-transparent ml-auto"
                  onClick={onClickBack}
                  disabled={pageNumber === 1}
                >
                  Back
                </Button>
                <Button
                  type="text"
                  onClick={onClickNext}
                  disabled={pageNumber >= callContactCallsData?.totalPages}
                >
                  Next
                </Button>
                <span>
                  Page {pageNumber}/{callContactCallsData?.totalPages}
                </span>
              </ConditionalRenderer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
