import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, InternalHookFormInput } from '../../../atoms';
import { EditCallCentreProfileFormFields } from '../../../../types';
import { editCallCentreProfileFormValidationSchema } from '../../../../validators';

type EditCallCentreProfileFormProps = {
  defaultValues: EditCallCentreProfileFormFields;
  onSubmit: (values: EditCallCentreProfileFormFields) => Promise<void>;
  isSubmitLoading: boolean;
};

export const EditCallCentreProfileForm: React.FC<EditCallCentreProfileFormProps> = ({
  defaultValues,
  onSubmit,
  isSubmitLoading,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(editCallCentreProfileFormValidationSchema),
    mode: 'onTouched',
  });

  const _navigateToCallContactProfile = () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const baseUrl = `${url.protocol}//${url.host}/`;
    window.open(`${baseUrl}admin/individual/user?Id=${defaultValues.userId}`, '_blank');
  };

  const submit = async () => {
    await onSubmit(getValues() as EditCallCentreProfileFormFields);
    reset({}, { keepValues: true });
  };

  return (
    <form className="flex flex-col flex-grow px-3">
      <InternalHookFormInput variableName="userId" register={register} hidden />
      <label className="flex-row items-center gap-2 mt-2">
        <span className="text-xxs">First Name</span>
        <InternalHookFormInput
          className="w-full border-2 border-solid px-1 text-sm"
          variableName="firstName"
          register={register}
          errors={errors}
        />
      </label>
      <label className="flex-row items-center gap-2 mt-2">
        <span className="text-xxs">Last Name</span>
        <InternalHookFormInput
          className="w-full border-2 border-solid px-1 text-sm"
          variableName="lastName"
          register={register}
          errors={errors}
        />
      </label>
      <label className="flex-row items-center gap-2 mt-2">
        <span className="text-xxs">Cell</span>
        <InternalHookFormInput
          className="w-full border-2 border-solid px-1 text-sm"
          variableName="cellphoneNumber"
          errors={errors}
          register={register}
        />
      </label>
      <label className="flex-row items-center gap-2 mt-2">
        <span className="text-xxs">Email</span>
        <InternalHookFormInput
          className="w-full border-2 border-solid px-1 text-sm"
          variableName="email"
          register={register}
          errors={errors}
        />
      </label>
      <label className="flex-row items-center gap-2 mt-2">
        <span className="text-xxs">Partner</span>
        <InternalHookFormInput
          className="w-full border-2 border-solid px-1 text-sm"
          variableName="partner"
          register={register}
          disabled
        />
      </label>
      <div className="flex justify-between my-4">
        <Button
          isLoading={isSubmitLoading}
          onClick={handleSubmit(submit)}
          className="bg-primary hover:bg-primary-dark text-white text-xs rounded-sm"
          spinnerSize={10.5}
          disabled={!isDirty}
        >
          <span>Update</span>
        </Button>
        <Button
          onClick={_navigateToCallContactProfile}
          className="bg-secondary hover:bg-secondary-dark text-white text-xs self-center rounded-sm"
        >
          Open Profile on Portal
        </Button>
      </div>
    </form>
  );
};
