import React from 'react';
import { useHistory } from 'react-router';

const AdminLandingScreen: React.FC = () => {
  const history = useHistory();
  history.replace('/admin');

  return <></>;
};

export default AdminLandingScreen;
