import config from '../../../config';

const apiV1Url = config.apiUrl();
const baseUrl = `${apiV1Url}/Fines`;
const individualUrl = `${baseUrl}/individual`;
const fleetUrl = `${baseUrl}/fleet`;

export default {
  getBaseUrl: (): string => `${baseUrl}`,
  getBaseContentUrl: (): string => `${baseUrl}/Content`,
  getIndividualFilterData: (): string => `${individualUrl}/filter-data`,
  getIndividualUnpaidFinesUrl: (): string => `${individualUrl}/unpaid`,
  getIndividualPaidFinesUrl: (): string => `${individualUrl}/paid`,
  getFleetFilterData: (): string => `${fleetUrl}/filter-data`,
  getFleetUnpaidFinesUrl: (): string => `${fleetUrl}/unpaid`,
  getFleetPaidFinesUrl: (): string => `${fleetUrl}/paid`,
  getGroupedFinesUrl: (): string => `${individualUrl}/group-fines`,
  getFleetGroupedFinesUrl: (): string => `${fleetUrl}/group-fines`,
  addFineUrl: (): string => `${apiV1Url}/Fines/add-fine`,
  getFleetFilteredFineUrl: (fleetEntityId: string): string =>
    `${apiV1Url}/FleetEntities/${fleetEntityId}/Fines`,
  getFleetFilteredFineContentUrl: (fleetEntityId: string): string =>
    `${apiV1Url}/FleetEntities/${fleetEntityId}/Fines/Content`,
};
