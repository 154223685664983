import config from '../../../config';

const baseUrl = `${config.apiUrl()}/BackOffice/Users`;

export default {
  getUsersUrl: () => `${baseUrl}`,
  generatePasswordLinkUrl: (): string => `${baseUrl}/generate-reset-password-link`,
  signUpConsultantUrl: (): string => `${baseUrl}/invite-user`,
  addRoleToUserUrl: (): string => `${baseUrl}/assign-user-to-role`,
};
