import React, { useState } from 'react';
import { Checkbox, FormControlLabel, IconButton, Typography } from '@material-ui/core';
import { Formik, FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import { Button, FormTextField } from '../../atoms';
import { loginValidation } from '../../../validators';
import { LoginProps, ErrorObject, BaseFormProps } from '../../../types';

export const LoginForm: React.FC<BaseFormProps<LoginProps>> = ({
  initialValues,
  submitForm,
  onSuccess,
  onFailure,
}) => {
  const history = useHistory();

  const _handleFormSubmitError = (error: ErrorObject, actions: FormikHelpers<LoginProps>) => {
    actions.setSubmitting(false);
    const apiErrors = error.errors;
    if (!_.isEmpty(apiErrors)) {
      actions.setFieldError('username', apiErrors.username);
      actions.setFieldError('password', apiErrors.password);
    } else if (error.statusCode === 400) {
      actions.setFieldError(
        'password',
        error.message === 'invalid_grant' ? 'Invalid email or password' : error.message,
      );
    } else {
      actions.setFieldError('username', error.message);
    }
  };

  const _handleSubmission = (formData: LoginProps, actions: FormikHelpers<LoginProps>) => {
    submitForm(formData)
      .then(() => {
        actions.setSubmitting(false);
        onSuccess();
      })
      .catch((error: ErrorObject) => {
        _handleFormSubmitError(error, actions);
        onFailure();
      });
  };

  const _onForgotPasswordClick = () => {
    history.push('/forgot-password');
  };

  const _onSignUpClick = () => {
    history.push('/sign-up');
  };
  const [showPassword, setShowPassword] = useState(false);

  function _toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  const buttonIcon = showPassword ? <Visibility /> : <VisibilityOff />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginValidation}
      validateOnBlur
      validateOnChange={false}
      onSubmit={_handleSubmission}
      enableReinitialize
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleReset,
        setFieldValue,
      }) => {
        const onBlurEmail = (fieldName): void => {
          if (values.username.indexOf(' ') >= 0) {
            setFieldValue(fieldName, values.username.trim());
          }
          handleBlur(fieldName);
        };

        return (
          <form onReset={handleReset} onSubmit={handleSubmit} className="auth-form-body">
            <p className="auth-heading">Sign In</p>
            <FormTextField
              variableName="username"
              placeholder="Email"
              autoComplete="username"
              values={values}
              onChange={handleChange}
              onBlur={onBlurEmail}
              touched={touched}
              errors={errors}
              icon={<img src="assets/icons/mail-icon.png" alt="mail" />}
              required
              className="w-4/5 self-center"
            />
            <FormTextField
              variableName="password"
              placeholder="Password"
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              icon={<img src="assets/icons/lock-icon.png" alt="password" />}
              endIcon={
                <IconButton size="small" onClick={_toggleShowPassword}>
                  {buttonIcon}
                </IconButton>
              }
              required
              className="w-4/5 self-center"
            />
            <div className="flex justify-between w-4/5 self-center">
              <FormControlLabel
                checked={values.remember}
                onChange={handleChange('remember')}
                control={<Checkbox name="remember" id="remember" color="secondary" />}
                label="Remember me"
              />
              <Button type="text" className="font-bold -ml-1" onClick={_onForgotPasswordClick}>
                Set/Forgot Password
              </Button>
            </div>
            <Button
              isLoading={isSubmitting}
              onClick={handleSubmit}
              className="auth-form-submit-button"
              size="medium"
            >
              Login
            </Button>
            <div className="flex self-center">
              <Typography className="self-center">{"Don't have an account? "}</Typography>
              <Button type="text" className="font-bold -ml-1" onClick={_onSignUpClick}>
                Sign Up
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
