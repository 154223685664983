import { AxiosResponse } from 'axios';
import authNetworkService from '../auth-network/auth-network.service';
import referralsUrls from './referral-campaigns.urls';
import { ServiceResult } from '../../../types';

const getActiveReferralCampaignDiscount = (): Promise<number> => {
  const url = referralsUrls.getActiveReferralCampaignDiscountUrl();
  return authNetworkService
    .get(url)
    .then((apiResponse: AxiosResponse<ServiceResult<number>>) => apiResponse.data.data);
};

export default {
  getActiveReferralCampaignDiscount,
};
