import { Button, Card, CardContent, SwipeableDrawer } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useImmerAtom } from 'jotai/immer';
import { useMutation, useQueryClient } from 'react-query';
import { useImmer } from 'use-immer';
import toast from 'react-hot-toast';
import Modal from 'react-modal';
import { Draft } from 'immer';

import { useBlackListData } from '../../../react-queries';
import { BasicTable } from '../../../components/table/adminTable/basicTable';
import { QueryParamSearch, searchParamsAtom } from '../admin-users/users.screen';
import { AddBlackListProps, BlackListFilterOptions, Guid } from '../../../types';
import { AddBlackListForm, PaginationButtons, SearchField } from '../../../components';
import blackListApiService from '../../../services/api/black-list/black-list.api.service';

Modal.setAppElement('#root');

const AdminBlackListScreen: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const history = useHistory();
  const queryClient = useQueryClient();
  const _handleSuccess = () => {
    queryClient.invalidateQueries(['black-list']);
    setTimeout(function waitForToast() {
      history.push('/admin/black-list');
    }, 2500);
  };

  const _signUpSubmission = (formData: AddBlackListProps) => {
    return blackListApiService.add(formData);
  };

  const _deleteData = useMutation(
    'deleteBlackList',
    (id: Guid) => blackListApiService.removeBlackList(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['black-list']);
        toast.success('Successfully removed user');
      },
      onError: () => {
        toast.error('Error removing user');
      },
    },
  );

  const _signUpFail = () => null;

  const [blackListFilterState, setBlackListFilterState] = useImmer<BlackListFilterOptions>({
    userId: '',
    pageNum: 1,
    pageEntries: 10,
    fullName: '',
  });

  const setPage = (pageNumber: number) => {
    setBlackListFilterState({
      ...blackListFilterState,
      pageNum: pageNumber,
    });
  };

  const blackListQuery = useBlackListData(blackListFilterState);
  const [, setSearchParams] = useImmerAtom(searchParamsAtom);
  const columns = useMemo(
    () => [
      {
        Header: 'ID Number',
        accessor: 'userId',
      },
      {
        Header: 'Full Name',
        accessor: 'fullName',
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="mt-2 flex justify-start mx-8 text-xl">Black List</div>
        <Button
          className="mt-2 flex bg-tertiary text-white hover:bg-tertiary-dark mr-8"
          size="small"
          startIcon={<AddCircleOutline />}
          onClick={() => setDrawerOpen(true)}
        >
          Add New
        </Button>
      </div>
      <div className="border-b border-black mt-2 flex justify-start mx-8" />
      <div className="flex justify-start mx-8 mt-2 space-x-2">
        <SearchField
          placeholder="ID Number"
          value={blackListFilterState.userId}
          onChange={(value) => {
            setBlackListFilterState({
              ...blackListFilterState,
              userId: value,
              pageNum: 1,
            });
          }}
          isLoading={blackListQuery.isFetching}
        />
        <SearchField
          placeholder="Full Name"
          value={blackListFilterState.fullName}
          onChange={(value) => {
            setBlackListFilterState({
              ...blackListFilterState,
              fullName: value,
              pageNum: 1,
            });
          }}
          isLoading={blackListQuery.isFetching}
        />
      </div>
      {!blackListQuery.isLoading && (
        <>
          <div className="flex flex-col flex-grow mx-8 mt-2">
            <Card className="flex flex-grow shadow-2xl">
              <CardContent className="flex flex-col flex-grow p">
                <BasicTable
                  tableData={blackListQuery.data.items}
                  tableColumns={columns}
                  path="./individual/fine"
                  isViewable={false}
                  isDeletable
                  onDelete={({ id }) => _deleteData.mutateAsync(id)}
                  linkToAccounts={{
                    action: (data) => {
                      setSearchParams((draft: Draft<QueryParamSearch>) => {
                        draft.individualOffenderIdentifier = data.userId;
                      });
                    },
                  }}
                />
              </CardContent>
            </Card>
          </div>
          <PaginationButtons
            page={blackListFilterState.pageNum}
            setPage={setPage}
            pageIndex={blackListQuery.data.pageIndex}
            totalPages={blackListQuery.data.totalPages}
          />
        </>
      )}

      <SwipeableDrawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
      >
        <AddBlackListForm
          initialValues={{
            userId: '',
          }}
          submitForm={_signUpSubmission}
          onSuccess={() => {
            _handleSuccess();
            setDrawerOpen(false);
          }}
          onFailure={_signUpFail}
        />
      </SwipeableDrawer>
    </>
  );
};
export default AdminBlackListScreen;
