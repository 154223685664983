import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { InternalDateTimePicker } from '../internal-date-time-picker/internal-date-time-picker';

type InternalHookFormDateTimePickerProps = {
  id?: string;
  control: Control<any>;
  variableName: string;
  className?: string;
  classNameOverride?: string;
  touchedFields: Record<string, unknown>;
  errors: Record<string, unknown>;
  businessHoursOnly?: boolean;
};

export const InternalHookFormDateTimePicker: React.FC<InternalHookFormDateTimePickerProps> = ({
  id,
  control,
  variableName,
  className,
  classNameOverride,
  touchedFields,
  errors,
  businessHoursOnly,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={variableName}
      render={({ field: { value, onChange, onBlur } }) => {
        return (
          <InternalDateTimePicker
            id={id}
            className={className}
            classNameOverride={classNameOverride}
            variableName={variableName}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            errors={errors}
            touchedFields={touchedFields}
            businessHoursOnly={businessHoursOnly}
            {...rest}
          />
        );
      }}
    />
  );
};
