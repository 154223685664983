import React from 'react';

export type InternalInputProps = {
  id?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
};

export const InternalInput = React.forwardRef<HTMLInputElement, InternalInputProps>(
  ({ className, id, placeholder, ...rest }, ref) => {
    return (
      <input
        id={id}
        placeholder={placeholder}
        className={`border-2 border-solid px-1 ${className}`}
        ref={ref}
        {...rest}
      />
    );
  },
);
