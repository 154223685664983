import { Checkbox, FormHelperText, IconButton, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff, WhatsApp } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import { isValidNumber } from 'libphonenumber-js';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { Button, FormTextField } from '../../atoms';
import { SignUpV2FormValues } from './sign-up-process';

type SignUpV2FormProps = {
  submitForm: (formData: any) => any;
  onSuccess: () => unknown;
  onFailure: () => unknown;
  isLoading: boolean;
};

export const SignUpV2Form: React.FC<SignUpV2FormProps> = ({ submitForm, isLoading = false }) => {
  const history = useHistory();
  const { values, handleChange, touched, errors, handleBlur, setFieldError } =
    useFormikContext<SignUpV2FormValues>();
  const _onLoginClick = () => {
    history.push('/login');
  };

  const _onTermsClick = () => {
    window.open('https://www.finessa.co.za/terms-and-conditions.php', '_blank');
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  function _toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  function _firstFormSetHasErrors(): boolean {
    const internationalNumber = `+${values.account.code}${values.account.cellphone}`;
    if (!isValidNumber(internationalNumber)) {
      setFieldError('account.cellphone', 'Invalid Phone number');
      return true;
    }
    return (
      (errors?.account?.confirmPassword ||
        errors?.account?.email ||
        errors?.account?.confirmEmail ||
        errors?.account?.firstName ||
        errors?.account?.lastName ||
        errors?.account?.termsChecked) !== undefined
    );
  }

  const onNext = (params) => {
    if (_firstFormSetHasErrors()) {
      return;
    }
    submitForm(params);
  };

  function _toggleShowConfirmPassword() {
    setShowConfirmPassword(!showConfirmPassword);
  }

  const passwordButtonIcon = showPassword ? <Visibility /> : <VisibilityOff />;
  const confirmPasswordButtonIcon = showConfirmPassword ? <Visibility /> : <VisibilityOff />;

  return (
    <form className="flex flex-auto flex-col gap-3 py-2 items-center">
      <p className="text-xl text-text-grey self-center w-4/5">
        Please enter your details so we can check your fines
      </p>
      <FormTextField
        variableName="account.firstName"
        placeholder="First Name"
        values={values}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched}
        errors={errors}
        icon={<img src="assets/icons/user-icon.png" alt="userName" />}
        required
        className="w-4/5 self-center"
      />
      <FormTextField
        variableName="account.lastName"
        placeholder="Last Name"
        values={values}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched}
        errors={errors}
        icon={<img src="assets/icons/user-icon.png" alt="lastName" />}
        required
        className="w-4/5 self-center"
      />
      <FormTextField
        variableName="account.email"
        placeholder="Email"
        values={values}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched}
        errors={errors}
        icon={<img src="assets/icons/mail-icon.png" alt="mail" />}
        required
        className="w-4/5 self-center"
      />
      <FormTextField
        variableName="account.confirmEmail"
        placeholder="Confirm Email"
        values={values}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched}
        errors={errors}
        icon={<img src="assets/icons/mail-icon.png" alt="mail" />}
        required
        className="w-4/5 self-center"
      />
      <div className="w-4/5 self-center flex justify-between">
        <FormTextField
          variableName="account.code"
          placeholder="Code"
          values={values}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched}
          errors={errors}
          icon={<img src="assets/icons/plus-icon.png" alt="code" />}
          required
          className="w-3/12"
        />
        <FormTextField
          variableName="account.cellphone"
          placeholder="Cellphone"
          values={values}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched}
          errors={errors}
          icon={<img src="assets/icons/phone-icon.png" alt="phone" />}
          required
          className="w-8/12"
        />
      </div>
      <div className="w-4/5">
        <Typography className="text-gray-500 font-light mb-1 text-xs">
          Password must be at least 6 characters long
        </Typography>

        <FormTextField
          variableName="account.password"
          placeholder="Create a Password"
          type={showPassword ? 'text' : 'password'}
          values={values}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched}
          errors={errors}
          icon={<img src="assets/icons/lock-icon.png" alt="password" />}
          endIcon={
            <IconButton size="small" onClick={_toggleShowPassword}>
              {passwordButtonIcon}
            </IconButton>
          }
          required
          className="w-full self-center"
        />
      </div>

      <FormTextField
        variableName="account.confirmPassword"
        placeholder="Confirm Password"
        type={showConfirmPassword ? 'text' : 'password'}
        values={values}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched}
        errors={errors}
        icon={<img src="assets/icons/lock-icon.png" alt="passwordConfirm" />}
        endIcon={
          <IconButton size="small" onClick={_toggleShowConfirmPassword}>
            {confirmPasswordButtonIcon}
          </IconButton>
        }
        required
        className="w-4/5 self-center"
      />
      <div className="flex place-items-center w-4/5 self-center">
        <Checkbox
          checked={values.account.termsChecked}
          name="termsChecked"
          id="termsChecked"
          color="secondary"
          onChange={handleChange('account.termsChecked')}
          onBlur={handleBlur('account.termsChecked')}
        />

        <div className="flex flex-row">
          <Typography className="self-center">{'Agree to the '}</Typography>
          <Button type="text" className="font-bold" onClick={_onTermsClick}>
            Terms & Conditions
          </Button>
        </div>
      </div>
      {errors?.account?.termsChecked && (
        <FormHelperText error>{errors?.account?.termsChecked}</FormHelperText>
      )}
      <div className="flex place-items-center w-4/5 self-center">
        <Checkbox
          checked={values.account.whatsAppOptedIn}
          name="whatsAppOptedIn"
          id="whatsAppOptedIn"
          color="secondary"
          onChange={handleChange('account.whatsAppOptedIn')}
          onBlur={handleBlur('account.whatsAppOptedIn')}
        />
        <div className="flex flex-row items-center">
          <Typography className="self-center">
            I want to receive FinesSA communications via WhatsApp{' '}
            <WhatsApp style={{ color: '#25D366' }} />
          </Typography>
        </div>
      </div>
      <Button isLoading={isLoading} onClick={onNext} className="auth-form-submit-button">
        Next
      </Button>
      <div className="self-center flex flex-row">
        <Typography className="self-center">{'Already have an account? '}</Typography>
        <Button type="text" className="font-bold -ml-1" onClick={_onLoginClick}>
          Log In
        </Button>
      </div>
    </form>
  );
};
