import React, { useState } from 'react';
import { Button, Card, CardContent, SwipeableDrawer } from '@material-ui/core';
import Modal from 'react-modal';
import { AddCircleOutline } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { useMutation, useQueryClient } from 'react-query';
import { toast, Toaster } from 'react-hot-toast';
import { PaginationButtons, SearchField } from '../../../components';
import { BasicTable } from '../../../components/table/adminTable/basicTable';
import { useAdminConsultantData } from '../../../react-queries/admin-consultant-data/admin-consultant-data.queries';
import { Guid, SignUpConsultantProps, User } from '../../../types';
import { AddConsultantsForm } from '../../../components/forms/add-consultant-form/add-consultant-form';
import { adminUserService } from '../../../services';
import adminConsultantsService from '../../../services/api/admin-consultants/admin-consultants.service';
import {
  EditCallCenterAgentForm,
  EditCallCenterAgentFormProps,
} from '../../../components/forms/edit-call-center-agent-form/edit-call-center-agent-form';
import userService from '../../../services/api/user/user.service';
import { RoleEnum } from '../../../enums/role.enum';
import { StringHelper } from '../../../helpers';

Modal.setAppElement('#root');

const AdminInvitationScreen: React.FC = () => {
  const anchorRight = 'right';
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [initialEditCallCenterAgentValues, setInitialEditCallCenterAgentValues] = useState({
    userId: '',
    extension: '',
  });
  const [searchConsultants, setSearchConsultants] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const pageEntries = '10';
  const consultantData = useAdminConsultantData(page.toString(), pageEntries, searchConsultants);
  const history = useHistory();
  const queryClient = useQueryClient();

  const _addConsultantFail = () => null;

  const onSuccessAddConsultant = () => {
    setTimeout(function waitForToast() {
      history.push('/admin/consultant');
    }, 2500);
  };

  const useDeleteConsultantMutation = useMutation(
    'deleteConsultant',
    (id: Guid) => adminConsultantsService.deleteConsultant(id),
    {
      onSuccess: () => {
        toast.success('Successfully deleted consultant');
      },
      onError: () => {
        toast.error('Error deleting consultant');
      },
    },
  );

  const useMutationDeleteExtension = useMutation(
    'deleteExtension',
    (formData: EditCallCenterAgentFormProps) => userService.deleteExtension(formData.userId),
    {
      onSuccess: (response) => {
        const updatedUser = consultantData.data.items.find((user) => user.id === response.userId);
        updatedUser.extension = '';
        queryClient.invalidateQueries([
          'consultantData',
          page.toString(),
          pageEntries,
          searchConsultants,
        ]);
        toast.success('Successfully deleted extension');
      },
    },
  );

  const useMutationUpsertExtension = useMutation(
    'upsertExtension',
    (formData: EditCallCenterAgentFormProps) =>
      userService.upsertExtension(formData.userId, formData.extension),
    {
      onSuccess: (response) => {
        const updatedUser = consultantData.data.items.find((user) => user.id === response.userId);
        updatedUser.extension = response.extension;
        queryClient.invalidateQueries([
          'consultantData',
          page.toString(),
          pageEntries,
          searchConsultants,
        ]);
        toast.success('Successfully updated extension');
      },
    },
  );

  const onSubmitFormEditCallCenterAgentForm = (formData: EditCallCenterAgentFormProps) => {
    if (StringHelper.isNullOrEmpty(formData.extension)) {
      return useMutationDeleteExtension.mutateAsync(formData);
    }
    return useMutationUpsertExtension.mutateAsync(formData);
  };

  const signUpConsultantInitialValues: SignUpConsultantProps = {
    firstName: '',
    lastName: '',
    email: '',
    cellPhoneNumber: '',
    role: 'Consultant',
  };

  const _signUpConsultantSubmission = (formData: SignUpConsultantProps) => {
    return adminUserService.signUpConsultant(formData);
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'firstName',
    },
    {
      Header: 'Surname',
      accessor: 'lastName',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'PhoneNumber',
      accessor: 'phoneNumber',
    },
    {
      Header: 'Roles',
      accessor: 'roles',
    },
  ];

  const usersTableCard = () => {
    return (
      <div className="flex flex-col flex-grow mx-8 mt-2">
        <Card className="flex flex-grow shadow-2xl">
          <CardContent className="flex flex-col flex-grow">
            {!consultantData.isLoading && (
              <BasicTable
                tableData={consultantData.data.items.map((user) => ({
                  ...user,
                  isEditable: RoleEnum[user.roles] === RoleEnum.CallCenterAgent,
                }))}
                tableColumns={columns}
                path="/admin/individual/user"
                key={searchConsultants + consultantData.data.totalCount}
                isViewable={false}
                isDeletable
                onDelete={({ id }) => useDeleteConsultantMutation.mutateAsync(id)}
                isEditable
                onEdit={(user: User) => {
                  const isExtensionNew = !!(user.extension === null || user.extension === '');
                  setInitialEditCallCenterAgentValues({
                    userId: user.id,
                    extension: isExtensionNew ? '' : user.extension,
                  });
                  setIsEditOpen(true);
                }}
              />
            )}
          </CardContent>
        </Card>
      </div>
    );
  };

  return (
    <>
      <Toaster />
      <div className="flex flex-row justify-between">
        <div className="mt-2 flex justify-start mx-8 text-xl">Consultant</div>
        <Button
          className="mt-2 flex bg-tertiary text-white hover:bg-tertiary-dark mr-24"
          size="small"
          startIcon={<AddCircleOutline />}
          onClick={() => setIsAddOpen(true)}
        >
          Add New
        </Button>
      </div>

      <div className="border-b border-black mt-2 flex justify-start mx-8" />
      <div className="flex justify-start ml-8 mt-2">
        <SearchField
          placeholder="Search"
          value={searchConsultants}
          isLoading={consultantData.isLoading}
          onChange={(value) => {
            setSearchConsultants(value);
          }}
        />
      </div>
      {usersTableCard()}
      {!consultantData.isLoading && (
        <>
          <PaginationButtons
            page={page}
            setPage={setPage}
            pageIndex={consultantData?.data?.pageIndex}
            totalPages={consultantData?.data?.totalPages}
          />
          <SwipeableDrawer
            anchor={anchorRight}
            open={isAddOpen}
            onClose={() => setIsAddOpen(false)}
            onOpen={() => setIsAddOpen(true)}
            className="w-96"
          >
            <AddConsultantsForm
              initialValues={signUpConsultantInitialValues}
              submitForm={_signUpConsultantSubmission}
              onSuccess={() => {
                onSuccessAddConsultant();
              }}
              onFailure={_addConsultantFail}
            />
          </SwipeableDrawer>
          <SwipeableDrawer
            anchor={anchorRight}
            open={isEditOpen}
            onClose={() => setIsEditOpen(false)}
            onOpen={() => setIsEditOpen(true)}
            className="w-96"
          >
            <EditCallCenterAgentForm
              initialValues={initialEditCallCenterAgentValues}
              submitForm={onSubmitFormEditCallCenterAgentForm}
              onSuccess={() => {
                setIsEditOpen(false);
              }}
              onFailure={() => null}
            />
          </SwipeableDrawer>
        </>
      )}
    </>
  );
};
export default AdminInvitationScreen;
