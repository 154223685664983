import { useQuery, UseQueryResult } from 'react-query';
import { adminConsultantService } from '../../services';

import { PaginatedList, User } from '../../types';

export const useAdminConsultantData = (
  pageNum: string,
  pageEntries: string,
  search: string,
): UseQueryResult<PaginatedList<User>, unknown> => {
  return useQuery(['consultantData', pageNum, pageEntries, search], () =>
    adminConsultantService.getConsultant(pageNum, pageEntries, search),
  );
};
