import React from 'react';

import { useHistory } from 'react-router';
import { Button, FormContainer } from '../../../components';

const AccountDeleteSuccessScreen: React.FC = () => {
  const history = useHistory();

  const onClickLogin = () => {
    history.replace('/login');
  };

  return (
    <FormContainer className="p-5">
      <p className="text-center">
        Your account has been deleted. We&apos;re sorry to see you go! If you&apos;d like to return
        in the future, your welcome back anytime.
      </p>
      <div className="flex justify-center mt-5">
        <Button
          onClick={onClickLogin}
          className="bg-primary hover:bg-primary-dark text-white text-base self-center"
          size="medium"
        >
          Back to Login
        </Button>
      </div>
    </FormContainer>
  );
};

export default AccountDeleteSuccessScreen;
