import React, { useRef, useState } from 'react';
import { UseQueryResult, useQueryClient } from 'react-query';
import { toast } from 'react-hot-toast';
import { Button } from '@material-ui/core';
import { Check, Edit } from '@material-ui/icons';
import { EditProfileInfoProps, Guid, ProfileInfoProps, ProfileType } from '../../../types';
import { EditProfileInfoForm } from '../../forms/edit-profile-form/edit-profile-form';
import adminProfilesService from '../../../services/api/admin-profiles/admin-profiles.service';

export const AccountInfo = ({
  userId,
  profileType,
  accountInfoQuery,
}: {
  userId: Guid;
  profileType: ProfileType;
  accountInfoQuery: UseQueryResult<ProfileInfoProps, void>;
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const toggleChecked = () => setIsEditable((value) => !value);
  const profileEditReference = useRef(null);
  const callSubmit = () => {
    const errors = Object.values<string>(profileEditReference?.current?.errors);
    errors.forEach((error) => toast.error(error));
    if (errors.length > 0) return () => null;

    toggleChecked();
    return profileEditReference?.current.handleSubmission();
  };

  const queryClient = useQueryClient();
  const _editProfile = (formData: EditProfileInfoProps) => {
    return adminProfilesService
      .update(userId, {
        communicationEmails: [formData.communicationEmail],
        ...formData,
      })
      .then(() => {
        toast.success('User Successfully Updated', { duration: 2000 });
        queryClient.invalidateQueries('paged-profile-fines');
      });
  };

  const _editProfileFail = () => {
    toast.error('Could not update profile');
  };

  const editProfileInfoProps = {
    updateProfileType: accountInfoQuery?.data?.updateProfileType,
    firstName: accountInfoQuery?.data?.firstName,
    lastName: accountInfoQuery?.data?.lastName,
    email: accountInfoQuery?.data?.email,
    identifier: accountInfoQuery?.data?.identifier,
    identifierType: accountInfoQuery?.data?.identifierType,
    cellPhoneNumber: accountInfoQuery?.data?.cellPhoneNumber,
    signUpInformation: accountInfoQuery?.data?.signUpInformation,
    isArchived: accountInfoQuery?.data?.isArchived,
    communicationEmail: accountInfoQuery?.data?.communicationEmails
      ? accountInfoQuery?.data?.communicationEmails[0]
      : '',
    roles: accountInfoQuery?.data?.roles,
  } as EditProfileInfoProps;

  return (
    <div className="flex flex-col rounded-md h-64 shadow-md">
      <div className="flex bg-secondary rounded-t-md h-16 p-4 flex-row justify-between items-center font-bold text-white">
        <p className="text-white font-sans text-base font-bold">Account Info</p>
        <Button
          className=" text-white"
          size="small"
          startIcon={isEditable ? <Check /> : <Edit />}
          onClick={isEditable ? callSubmit : toggleChecked}
        />
      </div>
      <div className="px-4 pt-4">
        <EditProfileInfoForm
          initialValues={editProfileInfoProps}
          submitForm={_editProfile}
          onSuccess={() => {
            queryClient.invalidateQueries('AdminAccountProfileData');
          }}
          onFailure={_editProfileFail}
          ref={profileEditReference}
          isBusiness={profileType === ProfileType.Fleet}
          disabled={!isEditable}
        />
      </div>
      <div className="px-4 pt-1 flex text-xs justify-between">
        <span>{accountInfoQuery.data?.signUpInformation?.signUpSource}</span>
        <span className="whitespace-nowrap">
          {accountInfoQuery.data?.signUpInformation?.signUpDateTime}
        </span>
      </div>
    </div>
  );
};
