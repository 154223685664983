export class ValidationHelper {
  static emailRegexValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  public static IsLuhnValid(value: string | undefined): boolean {
    if (value === undefined) return true;

    const digits = value.split('').map(Number);

    const initialSum = digits.reduce((a, b) => a + b, 0);
    if (initialSum === 0) return false;

    let sum = 0;
    digits.forEach((digit, index) => {
      if (index === 12) return;
      sum += index % 2 === 0 ? digit : digit * 2 > 9 ? digit * 2 - 9 : digit * 2;
    });
    return (sum + digits[12]) % 10 === 0;
  }
}
