import 'react-activity/dist/Spinner.css';

import { Button as Btn } from '@material-ui/core';
import React from 'react';
import { Spinner } from 'react-activity';

type Props = {
  children: React.ReactNode;
  type?: 'text' | 'outlined' | 'contained';
  isLoading?: boolean;
  disabled?: boolean;
  onClick: (prop: React.SyntheticEvent) => void;
  className?: string;
  size?: 'large' | 'medium' | 'small';
  spinnerSize?: number;
  tabIndex?: number;
  disableRipple?: boolean;
};

export const Button: React.FC<Props> = ({
  children,
  type = 'contained',
  isLoading = false,
  disabled = false,
  onClick,
  className,
  size = 'medium',
  spinnerSize = 16,
  tabIndex = 0,
  disableRipple = false,
}) => {
  return (
    <Btn
      variant={type}
      disabled={isLoading || disabled}
      onClick={onClick}
      className={className}
      size={size}
      tabIndex={tabIndex}
      disableRipple={disableRipple}
    >
      {isLoading ? <Spinner size={spinnerSize} /> : children}
    </Btn>
  );
};
