import authNetworkService from '../auth-network/auth-network.service';
import welcomeEmailUrls from './welcome-email.urls';

const send = (email: string) => {
  const url = welcomeEmailUrls.baseUrl();
  return authNetworkService.post(url, {
    emails: [email],
  });
};

export default {
  send,
};
