import { Dispatch, SetStateAction, useState } from 'react';
import { PaginationState } from '@tanstack/react-table';

export const usePagination = (): {
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  pagination: PaginationState;
} => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  return {
    setPagination,
    pagination,
  };
};
