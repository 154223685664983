import React, { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { FormHelperText } from '@material-ui/core';

import { Button, FormTextField } from '../../atoms';
import { ErrorObject, BaseFormProps, FamilyMembersProps } from '../../../types';
import { OffenderIdentifierType } from '../../../enums';
import { adminFamilyMemberValidationSchema } from '../../../validators';

export const AddFamilyMembersForm: React.FC<BaseFormProps<FamilyMembersProps>> = ({
  initialValues,
  submitForm,
  onSuccess,
  onFailure,
}) => {
  const [generalError, setGeneralError] = useState(null);
  const _handleFormSubmitError = (
    error: ErrorObject,
    actions: FormikHelpers<FamilyMembersProps>,
  ) => {
    onFailure();
    actions.setSubmitting(false);
    setGeneralError(error.detail);
  };
  const _handleSubmission = (
    formData: FamilyMembersProps,
    actions: FormikHelpers<FamilyMembersProps>,
  ) => {
    submitForm(formData)
      .then(() => {
        onSuccess();
      })
      .catch((error: ErrorObject) => {
        _handleFormSubmitError(error, actions);
      })
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnBlur
        validateOnChange={false}
        onSubmit={_handleSubmission}
        enableReinitialize
        validationSchema={adminFamilyMemberValidationSchema}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleReset,
          setFieldValue,
        }) => {
          return (
            <form
              onReset={handleReset}
              onSubmit={handleSubmit}
              className="flex flex-auto flex-col gap-3 py-1 mx-7"
            >
              <p className="flex justify-center auth-heading text-center">Add New Family Member</p>
              {generalError && <FormHelperText error>{generalError}</FormHelperText>}
              <p className="flex justify-start text-left text-xs font-bold">First Name:</p>
              <FormTextField
                variableName="firstName"
                placeholder="First Name"
                values={values}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched}
                errors={errors}
                required
                className="w-96"
              />
              <p className="flex justify-start text-left text-xs font-bold">Last Name:</p>
              <FormTextField
                variableName="lastName"
                placeholder="Last Name"
                values={values}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched}
                errors={errors}
                required
                className="w-96"
              />
              <p className="flex justify-start text-left text-xs font-bold">Identification Type:</p>
              <select
                name="offenderIdentifierType"
                id="offenderIdentifierType"
                value={values.offenderIdentifier.identifierType}
                onChange={(e) =>
                  setFieldValue('offenderIdentifier.identifierType', parseInt(e.target.value, 10))
                }
              >
                <option value={OffenderIdentifierType.SaIdNumber}>SA ID</option>
                <option value={OffenderIdentifierType.Trn}>Trn</option>
              </select>
              <p className="flex justify-start text-left text-xs font-bold">ID/TRN:</p>
              <FormTextField
                variableName="offenderIdentifier.value"
                placeholder="ID/Trn number"
                values={values}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched}
                errors={errors}
                required
                className="w-96"
              />

              <Button
                isLoading={isSubmitting}
                onClick={handleSubmit}
                className="w-4/5 bg-secondary hover:bg-secondary-dark text-white text-base self-center my-7"
              >
                Add Family Member
              </Button>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
