import React from 'react';

export type ProfileNoteProps = {
  isLoading: boolean;
  value: string;
  onChange: (e) => void;
  onBlur: () => void;
};

export const ProfileNote = React.forwardRef<HTMLTextAreaElement, ProfileNoteProps>(
  ({ isLoading, value, onBlur, onChange }, ref) => {
    return (
      <div className="flex flex-col rounded-md h-64 shadow-md w-64">
        <div className="flex bg-secondary rounded-t-md h-16 p-4 flex-row justify-between items-center font-bold text-white">
          <p className="font-bold text-white font-sans text-base">Notes</p>
        </div>
        <div className="flex overflow-auto text-xs">
          {!isLoading && (
            <textarea
              ref={ref}
              className="w-full h-60 p-3"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        </div>
      </div>
    );
  },
);
