import React from 'react';
import { Card, Divider } from '@material-ui/core';
import { useResponsive } from '../../../hooks';
import { KeyValueRow } from '../..';

type Props = {
  className?: string;
  columns?: React.ReactNode[];
  noticeNumber?: string;
  vehicleRegistration?: string;
  date?: string;
  fineAmount?: number;
  amountPaid?: number;
  paymentType?: string;
};

export const PaidTableRow: React.FC<Props> = ({
  className,
  columns = [],
  date = '',
  noticeNumber = '',
  vehicleRegistration = '',
  fineAmount = 0,
  amountPaid = 0,
  paymentType = '',
}) => {
  const responsive = useResponsive();
  return responsive.isMobile ? (
    <Card className={`flex md:flex-row flex-col px-2 md:place-items-center ${className}`}>
      <div className="flex flex-col w-full">
        <KeyValueRow keyString="Ticket Number:"> {noticeNumber} </KeyValueRow>
        <KeyValueRow keyString="Reg No:"> {vehicleRegistration}</KeyValueRow>
        <KeyValueRow keyString="Offence Date:"> {date} </KeyValueRow>
        <KeyValueRow keyString="Fine Amount:">
          <p className="text-sm">{`R ${fineAmount}`}</p>
        </KeyValueRow>
        <KeyValueRow keyString="Amount Paid:">
          <p className="text-sm">{`R ${amountPaid}`}</p>
        </KeyValueRow>
        <KeyValueRow keyString="Payment Type:"> {paymentType} </KeyValueRow>
      </div>
    </Card>
  ) : (
    <Card className={`flex flex-row px-2 place-items-center ${className}`}>
      <div className="flex flex-1 flex-grow-5">
        {columns.length ? (
          columns[0]
        ) : (
          <div className="flex flex-col w-full">
            <KeyValueRow keyString="Ticket Number:"> {noticeNumber} </KeyValueRow>
            <KeyValueRow keyString="Reg No:"> {vehicleRegistration}</KeyValueRow>
            <KeyValueRow keyString="Offence Date:"> {date} </KeyValueRow>
          </div>
        )}
      </div>
      {columns.length ? null : <Divider orientation="vertical" flexItem className="ml-16 mr-3" />}
      <div className="flex flex-1 flex-grow-1">
        {columns.length ? columns[1] : <p className="text-sm">{`R ${fineAmount}`}</p>}
      </div>
      {columns.length ? null : <Divider orientation="vertical" flexItem className="mx-3" />}
      <div className="flex flex-1 flex-grow-1">
        {columns.length ? columns[2] : <p className="text-sm">{`R ${amountPaid}`}</p>}
      </div>
      {columns.length ? null : <Divider orientation="vertical" flexItem className="mx-3" />}
      <div className="flex flex-1 flex-grow-1">
        {columns.length ? columns[3] : <p className="text-sm">{`${paymentType}`}</p>}
      </div>
    </Card>
  );
};
