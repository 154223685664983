import React from 'react';
import { Chip, IconButton } from '@material-ui/core';
import { Formik, FormikHelpers } from 'formik';
import _ from 'lodash';

import { useQuery } from 'react-query';
import { HighlightOff } from '@material-ui/icons';
import { useQueryParam } from 'use-query-params';
import { useHistory } from 'react-router';
import { Button, FormTextField } from '../../atoms';
import { ErrorObject, BaseFormProps, CreateReferral, ReferralFormProps } from '../../../types';

import { referralsService, referralCampaignsService } from '../../../services';

import { useResponsive } from '../../../hooks';
import { addReferralValidation } from '../../../validators';

export const AddReferralsForm: React.FC<BaseFormProps<ReferralFormProps>> = ({
  initialValues,
  submitForm,
  onSuccess,
  onFailure,
}) => {
  const responsive = useResponsive();
  const [dialogQueryParam] = useQueryParam<string>('dialog');
  const [utmSource] = useQueryParam<string>('utmSource');
  const history = useHistory();

  if (dialogQueryParam === 'referrals') {
    history.push(`${history.location.pathname}?${utmSource ? `utmSource=${utmSource}` : ''}`);
  }

  const userReferralsData =
    useQuery('userReferrals', referralsService.getAllReferrals)?.data ?? null;
  const referralCampaignDiscountData =
    useQuery('referralCampaignDiscount', referralCampaignsService.getActiveReferralCampaignDiscount)
      ?.data ?? 0;
  const userAvailableReferralsDiscount =
    userReferralsData?.filter(
      (referral) =>
        referralsService.referralStatusMapArray.find((m) => m.value === 'Accepted').enum ===
        referral.status.toString(),
    ).length * referralCampaignDiscountData;

  const _handleFormSubmitError = (
    error: ErrorObject,
    actions: FormikHelpers<ReferralFormProps>,
  ) => {
    actions.setSubmitting(false);
    const apiErrors = error.errors;
    if (!_.isEmpty(apiErrors)) {
      // TODO: Consider building this when the errors return instead of per component
      Object.keys(apiErrors).forEach((apiErrorKey) => {
        const startIndex = apiErrorKey.indexOf('[');
        const endIndex = apiErrorKey.indexOf(']');
        const index = Number(apiErrorKey.substring(startIndex + 1, endIndex));
        const errorFieldName = apiErrorKey.substring(
          apiErrorKey.lastIndexOf('.') + 1,
          apiErrorKey.length,
        );
        if (errorFieldName === 'firstName')
          actions.setFieldError(`referrals[${index}].firstName`, apiErrors[apiErrorKey]);
        if (errorFieldName === 'emailAddress')
          actions.setFieldError(`referrals[${index}].emailAddress`, apiErrors[apiErrorKey]);
      });
    }
  };

  const _handleSubmission = (
    formData: ReferralFormProps,
    actions: FormikHelpers<ReferralFormProps>,
  ) => {
    submitForm(formData)
      .then(() => {
        actions.setSubmitting(false);
        onSuccess();
      })
      .catch((error: ErrorObject) => {
        _handleFormSubmitError(error, actions);
        onFailure();
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addReferralValidation}
      validateOnBlur
      validateOnChange={false}
      onSubmit={_handleSubmission}
      enableReinitialize
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleReset,
        setFieldValue,
      }) => {
        const onClickAddMore = () => {
          setFieldValue('referrals', [
            ...values.referrals,
            {
              key: crypto.randomUUID(),
              emailAddress: '',
              firstName: '',
              utmSource,
            } as CreateReferral,
          ]);
        };

        const onClickRemove = (index: number) => {
          const { referrals } = values;
          referrals.splice(index, 1);
          setFieldValue('referrals', referrals);
        };

        return (
          <form onReset={handleReset} onSubmit={handleSubmit} className="auth-form-body">
            <p className="auth-heading text-center">Referrals</p>
            <p className="text-left w-4/5 text-sm">
              Our referral program offers a unique opportunity to benefit from accepted referrals by
              providing a discount of R{referralCampaignDiscountData}, per referral, on fines that
              require payment.
            </p>
            <p className="text-left w-4/5">
              <strong className="text-lg">Your Referrals:</strong>
              <br />
              GET FRIENDS TO PAY YOUR FINES !!!
            </p>
            {!responsive.isLarge ? (
              <div className="max-h-28 overflow-auto overflow-x-hidden justify-center w-4/5">
                <table className="table-fixed">
                  <thead>
                    <tr key="tr-th-referral">
                      <th key="th-referral-email" className="p-0 text-base w-56">
                        Email
                      </th>
                      <th key="th-referral-firstname" className="p-0 text-base w-24">
                        First Name
                      </th>
                      <th key="th-referral-status" className="p-0 text-base text-right w-28">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(userReferralsData, (referral) => {
                      const referralStatus = referralsService.referralStatusMapArray.find(
                        (m) => m.enum === referral.status.toString(),
                      );
                      return (
                        <tr key={`tr-td-referral-${referral.id}`}>
                          <td
                            key={`td-referral-${referral.id}-email`}
                            className="p-0 pr-3 truncate"
                          >
                            {referral.emailAddress}
                          </td>
                          <td key={`td-referral-${referral.id}-firstname`} className="p-0 truncate">
                            {referral.firstName}
                          </td>
                          <td key={`td-referral-${referral.id}-status`} className="p-0 text-right">
                            <Chip
                              label={referralStatus.value}
                              className={`${referralStatus.class} text-white mb-1 xs:mb-0 h-5 font-semibold text-sm mr-1 rounded-sm float-right w-28 max-w-28`}
                              variant="outlined"
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="w-4/5 max-h-20 overflow-auto">
                {_.map(userReferralsData, (referral) => {
                  const referralStatus = referralsService.referralStatusMapArray.find(
                    (m) => m.enum === referral.status.toString(),
                  );
                  return (
                    <div key={referral.id}>
                      <span className="w-36 text-xs inline-block truncate">
                        {referral.emailAddress}
                      </span>
                      <Chip
                        label={referralStatus.value}
                        className={`${referralStatus.class} text-white h-5 font-semibold text-xs mr-1 rounded-sm float-right`}
                        variant="outlined"
                      />
                    </div>
                  );
                })}
              </div>
            )}
            <span className="text-left w-4/5 text-sm">
              <span>Total referral credit received towards next payment:</span>
              <Chip
                label={`R${userAvailableReferralsDiscount}`}
                className="text-white mb-1 xs:mb-0 h-5 bg-total-green font-semibold text-sm mr-1 rounded-sm float-right"
                variant="outlined"
              />
            </span>
            <div className="w-4/5 lg:max-h-56 max-h-24 overflow-auto overflow-x-hidden space-y-4">
              {values.referrals.map((referral, index) => (
                <div className="flex" key={referral.key}>
                  <FormTextField
                    variableName={`referrals[${index}].firstName`}
                    placeholder="First Name"
                    values={values}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    icon={<img src="assets/icons/user-icon.png" alt="firstName" />}
                    required
                    className="flex w-1/2"
                    inputClassName="rounded-r-none"
                  />
                  <FormTextField
                    variableName={`referrals[${index}].emailAddress`}
                    placeholder="Referral Email"
                    values={values}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    icon={<img src="assets/icons/mail-icon.png" alt="emailAddress" />}
                    required
                    className="flex w-1/2 border-l-0"
                    inputClassName="rounded-l-none"
                    endIcon={
                      index !== 0 && (
                        <IconButton
                          className="h-4 w-4 m-0 p-0"
                          onClick={() => onClickRemove(index)}
                        >
                          <HighlightOff className="h-4 w-4 m-0 p-0" />
                        </IconButton>
                      )
                    }
                  />
                </div>
              ))}
            </div>
            <Button className="text-secondary" onClick={onClickAddMore} type="text">
              + Add another
            </Button>
            <span className="text-sm text-red-500">
              {typeof errors.referrals === 'string' ? errors.referrals : null}
            </span>
            <Button
              isLoading={isSubmitting}
              onClick={handleSubmit}
              className="auth-form-submit-button"
            >
              Invite
            </Button>
            <p className="text-left w-4/5 text-xs">
              <i>
                * Referrals are accepted only when the referred user makes their first payment
                through Fines SA.
              </i>
            </p>
          </form>
        );
      }}
    </Formik>
  );
};
