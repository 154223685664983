import { useQueryClient } from 'react-query';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Button, SwipeableDrawer } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import finesService from '../../../services/api/fines/fines.api.service';
import { EditFamilyMemberProps, FamilyMembersProps, FriendsAndFamily, Guid } from '../../../types';
import { EditableTable } from '../../table/editableTable/editableTable';
import { AddFamilyMembersForm } from '../../forms/add-family-members-form/add-family-members-form';
import { EditFamilyMembersForm } from '../../forms/edit-family-member-form/edit-family-member-form';
import adminFamilyMembersService from '../../../services/api/admin-family-members/admin-family-members.service';
import { OffenderIdentifierType } from '../../../enums';

const friendsFamilyCol = [
  {
    Header: 'Name',
    accessor: 'firstName',
    minWidth: 200,
  },
  {
    Header: 'Surname',
    accessor: 'lastName',
    minWidth: 200,
  },
  {
    Header: 'ID',
    accessor: 'offenderIdentifier.value',
    minWidth: 200,
  },
  {
    Header: 'Sync',
    accessor: 'sync',
    minWidth: 20,
  },
];

export const FamilyMembers = ({
  hasIndividualProfile,
  individualId,
  familyMembers,
}: {
  hasIndividualProfile: boolean;
  individualId: Guid;
  familyMembers: FriendsAndFamily[];
}) => {
  const queryClient = useQueryClient();
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [familyMemberTableData, setFamilyMemberTableData] = useState<any>();
  const anchorRight = 'right';

  const toggleFamilyMemberTableData = (open: boolean, rowData) => {
    setEditIsOpen(true);
    setFamilyMemberTableData(rowData);
  };

  const AddFamilyMembersInitialValues: FamilyMembersProps = {
    individualId,
    firstName: '',
    lastName: '',
    offenderIdentifier: {
      value: '',
      identifierType: OffenderIdentifierType.SaIdNumber,
    },
  };

  const EditFamilyMemberInitialValues: EditFamilyMemberProps = {
    firstName: familyMemberTableData?.firstName,
    lastName: familyMemberTableData?.lastName,
    offenderIdentifier: familyMemberTableData?.offenderIdentifier,
  };

  const _addFamilyMember = (formData: FamilyMembersProps) => {
    return finesService.addFamilyMember(formData);
  };

  const deleteFamilyMember = (familyMemberId: string) => {
    if (familyMemberId != null) {
      adminFamilyMembersService.deleteFamilyMembers(familyMemberId).then(() => {
        queryClient.invalidateQueries('AdminAccountProfileData');
      });
    }
  };

  const editFail = () => {
    toast.error('Error adding family member');
  };

  const _editFamilyMember = (formData: EditFamilyMemberProps) => {
    return adminFamilyMembersService.editFamilyMember(familyMemberTableData?.id, formData);
  };

  return (
    <>
      <div className="flex flex-col rounded-md h-64 shadow-md">
        <div className="flex bg-secondary rounded-t-md h-16 p-4 flex-row justify-between items-center font-bold text-white">
          <p className=" font-bold text-white font-sans text-base">Friends & Family</p>
          <Button
            className={
              hasIndividualProfile ? 'bg-tertiary text-white hover:bg-tertiary-dark' : 'bg-gray-200'
            }
            size="small"
            startIcon={<AddCircleOutline />}
            onClick={() => setAddIsOpen(true)}
            disabled={!hasIndividualProfile}
          >
            Add
          </Button>
        </div>
        {familyMembers.length > 0 ? (
          <div className="flex pt-0 px-0 overflow-auto text-xs">
            <EditableTable
              isViewable
              isDeleteAble
              data={familyMembers}
              columns={friendsFamilyCol}
              deleteData={deleteFamilyMember}
              isFamilyMemberTable
              toggleDrawer={toggleFamilyMemberTableData}
            />
          </div>
        ) : (
          <div className="flex pt-0 px-0 overflow-auto text-xs">No family members were found</div>
        )}
      </div>
      <SwipeableDrawer
        anchor={anchorRight}
        open={addIsOpen}
        onClose={() => setAddIsOpen(false)}
        onOpen={() => setAddIsOpen(true)}
        className="w-96"
      >
        <AddFamilyMembersForm
          initialValues={AddFamilyMembersInitialValues}
          submitForm={_addFamilyMember}
          onSuccess={() => {
            queryClient.invalidateQueries('AdminAccountProfileData');
            setAddIsOpen(false);
          }}
          onFailure={editFail}
        />
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor={anchorRight}
        open={editIsOpen}
        onClose={() => setEditIsOpen(false)}
        onOpen={() => setEditIsOpen(true)}
        className="w-96"
      >
        <EditFamilyMembersForm
          initialValues={EditFamilyMemberInitialValues}
          submitForm={_editFamilyMember}
          onSuccess={() => {
            queryClient.invalidateQueries('AdminAccountProfileData');
            setEditIsOpen(false);
          }}
          onFailure={editFail}
        />
      </SwipeableDrawer>
    </>
  );
};
