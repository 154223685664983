import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useDownloadFile } from '../../../../hooks/global';
import referralsService from '../../../../services/api/referrals/referrals.service';
import { Button, InternalDateTimePicker } from '../../../atoms';

export const ReferralsExportForm: React.FC = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { download, loading } = useDownloadFile({
    apiDefinition: () => referralsService.getAllReferralsContent(fromDate, toDate),
    onError: () => toast.error('Could not download report'),
  });

  const onChangeFromDate = (date) => {
    setFromDate(date);
  };

  const onChangeToDate = (date) => {
    setToDate(date);
  };

  return (
    <form className="flex flex-col justify-start items-start bg-gray-100 shadow-lg p-4 gap-4">
      <span className="w-full text-xl mb-2">Download Sign Up Export</span>
      <label htmlFor="filter-date-from" className="w-4/5 flex flex-col ">
        Filter From
        <InternalDateTimePicker
          id="filter-date-from"
          onChange={onChangeFromDate}
          value={fromDate}
        />
      </label>
      <label htmlFor="filter-date-to" className="w-4/5 flex flex-col">
        Filter To
        <InternalDateTimePicker
          id="filter-date-to"
          onChange={onChangeToDate}
          value={toDate}
          defaultToEndOfDay
        />
      </label>
      <Button
        isLoading={loading}
        onClick={download}
        className="bg-tertiary hover:bg-tertiary-dark text-white text-base self-center w-auto"
      >
        Download
      </Button>
    </form>
  );
};
