import dayjs from 'dayjs';

export const dateFormatter = (dateInput: string) => {
  return dayjs(dateInput).format('D MMM , YYYY');
};

export const dateTimeFormatter = (dateInput: string, seperator: string, twentyFourHour = false) => {
  return dayjs(dateInput).format(
    `D${seperator}MM${seperator}YY ${twentyFourHour ? 'HH:mm' : 'hh:mm a'}`,
  );
};

export const dateTimeFormatterGmtZoneOffset = (
  dateInput: string,
  seperator: string,
  gmtZoneOffset: number,
  twentyFourHour = false,
) => {
  return dayjs(dateInput)
    .add(gmtZoneOffset, 'hour')
    .format(`D${seperator}MM${seperator}YY ${twentyFourHour ? 'HH:mm' : 'hh:mm a'}`);
};
