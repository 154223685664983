import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface UserRoleState {
  userRole: string;
}

const initialState: UserRoleState = {
  userRole: '',
};

export const roleSlice = createSlice({
  name: 'roleSlice',
  initialState,
  reducers: {
    setUserRoleAction: (state, action: PayloadAction<string>) => {
      state.userRole = action.payload;
    },
  },
});

export const { setUserRoleAction } = roleSlice.actions;
export const useRoleSelector = (state: RootState) => state.userRoleReducer;

export default roleSlice.reducer;
