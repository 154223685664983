import React, { useLayoutEffect, useRef, useState } from 'react';
import { Card, Checkbox, Divider, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import Modal from 'react-modal';
import CloseIcon from '@material-ui/icons/Close';
import Loader from 'react-loader-spinner';
import numberFormatter from 'number-formatter';

import { ReactComponent as NoImageIcon } from '../../../assets/strike-through-camera-icon.svg';

import { useResponsive } from '../../../hooks';
import { Button, KeyValueRow } from '../..';
import fineImagesService from '../../../services/api/fine-images/fine-images.service';
import { demeritPointsFormatter } from '../../../helpers';

type Props = {
  className?: string;
  columns?: React.ReactNode[];
  ticketNumber?: string;
  regNo?: string;
  reason?: string;
  area?: string;
  offenceDate?: string;
  fineAmount?: number;
  discount?: number;
  aartoFee?: number;
  enforcementOrder?: number;
  serviceFee?: number;
  total?: number;
  amountPaid?: number;
  totalOutstanding?: number;
  demeritPoints?: number;
  onCheck?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showCheckBoxes?: boolean;
  checked: boolean;
  hasImage?: boolean;
  evidenceTokens?: string[];
  isNag?: boolean;
  isPayable?: boolean;
  notPayableReason?: string;
  excludeImage?: boolean;
};

const customStyles = (isLarge) => {
  return {
    overlay: {
      zIndex: 10,
      background:
        'linear-gradient(290deg, #061F40D9 0%, #031020D9 10%, #101925D9 30%, #0C0C0CD9 100%) 0% 0% no-repeat padding-box',
      boxShadow: '0px 20px 50px #3E3E3E4D',
      filter: 'blur(0px)',
    },
    content: {
      top: '50%',
      left: '50%',
      width: !isLarge ? '50%' : null,
      height: !isLarge ? '50%' : null,
      right: !isLarge ? null : 'auto',
      bottom: !isLarge ? null : 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
      padding: 0,
      zIndex: 100,
      opacity: 1,
    },
  };
};

Modal.setAppElement('#root');

export const UnpaidTableRow: React.FC<Props> = ({
  className,
  columns = [],
  onCheck,
  checked,
  aartoFee = 0,
  amountPaid = '',
  area = '',
  discount = '',
  enforcementOrder = 0,
  fineAmount = '',
  offenceDate = '',
  reason = '',
  regNo = '',
  serviceFee = 0,
  ticketNumber = '',
  totalOutstanding = '',
  demeritPoints = 0,
  hasImage = false,
  showCheckBoxes = true,
  evidenceTokens = [],
  isPayable = true,
  excludeImage = false,
}) => {
  const [showAll, setShowAll] = useState(false);
  const responsive = useResponsive();
  const [expandedReason, setExpandedReason] = useState(false);
  const [expandedArea, setExpandedArea] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [evidenceImageUrls, setEvidenceImageUrls] = useState<string[]>(
    new Array(evidenceTokens.length).fill(null),
  );
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const _expandFineReason = () => {
    setExpandedReason(!expandedReason);
  };

  const _expandFineArea = () => {
    setExpandedArea(!expandedArea);
  };

  const checkString = (string, expandFunction) =>
    _.size(string) > 12 && (
      <Button onClick={expandFunction} type="text" className="m-0 p-0" size="small">
        <p className="text-xs">show more</p>
      </Button>
    );

  const otherFeesTotal = () => enforcementOrder + serviceFee + aartoFee;

  const _renderReason = (string: string) => {
    if (expandedReason) {
      return (
        <>
          {string}
          <Button onClick={_expandFineReason} type="text" className="m-0 p-0" size="small">
            <p className="text-xs">show less</p>
          </Button>
        </>
      );
    }
    return (
      <div className="w-full">
        {_.truncate(string, { length: 12 })}
        {checkString(string, _expandFineReason)}
      </div>
    );
  };

  const _renderArea = (string: string) => {
    if (expandedArea) {
      return (
        <>
          {string}
          <Button onClick={_expandFineArea} type="text" className="m-0 p-0" size="small">
            <p className="text-xs">show less</p>
          </Button>
        </>
      );
    }
    return (
      <div className="w-full">
        {_.truncate(string, { length: 12 })}
        {checkString(string, _expandFineArea)}
      </div>
    );
  };

  const fetchImages = () => {
    if (evidenceImageUrls[currentImageIndex] === null) {
      setLoading(true);
      fineImagesService
        .fetchFineImage(evidenceTokens[currentImageIndex])
        .then((url) => {
          const state = [...evidenceImageUrls];
          state[currentImageIndex] = url;
          setEvidenceImageUrls(state);
        })
        .catch(() => {
          const state = [...evidenceImageUrls];
          state[currentImageIndex] =
            'https://www.publicdomainpictures.net/pictures/280000/nahled/not-found-image-15383864787lu.jpg';
          setEvidenceImageUrls(state);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      fetchImages();
    }
  }, [currentImageIndex]);

  const prevImage = () => {
    setCurrentImageIndex(Math.abs(currentImageIndex - 1) % evidenceTokens.length);
  };

  const nextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % evidenceTokens.length);
  };

  const openModal = () => {
    fetchImages();
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleImageLoaded = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setLoading(false);
  };

  const numberFormat = (amount) => {
    return amount === 0 ? '0' : numberFormatter('#,###.', amount);
  };

  return responsive.isMobile ? (
    <div className="flex flex-col flex-grow">
      <Card className={`flex md:flex-row flex-col px-2 md:place-items-center ${className}`}>
        <div className="flex flex-col w-full">
          <KeyValueRow keyString="Ticket Number:"> {ticketNumber} </KeyValueRow>
          <KeyValueRow keyString="Reg No:"> {regNo}</KeyValueRow>
          <KeyValueRow keyString="Reason:"> {_renderReason(reason)} </KeyValueRow>
          <KeyValueRow keyString="Area:"> {_renderArea(area)} </KeyValueRow>
          <KeyValueRow keyString="Offence Date:"> {offenceDate} </KeyValueRow>
          <KeyValueRow keyString="Demerit Points:">
            {demeritPointsFormatter(demeritPoints)}
          </KeyValueRow>
          <KeyValueRow keyString="Fine Amount:"> {`R ${numberFormat(fineAmount)}`} </KeyValueRow>
          <KeyValueRow keyString="Discount:"> {`R ${numberFormat(discount)}`} </KeyValueRow>
        </div>
        {showAll ? (
          <>
            <div className="flex flex-col w-full">
              <KeyValueRow keyString="Warrant/AARTO Fee:">
                {' '}
                {`R ${numberFormat(aartoFee)}`}{' '}
              </KeyValueRow>
              <KeyValueRow keyString="Enforcement Order:">
                {' '}
                {`R ${numberFormat(enforcementOrder)}`}{' '}
              </KeyValueRow>
              <KeyValueRow keyString="Service Fee:">
                {' '}
                {`R ${numberFormat(serviceFee)}`}{' '}
              </KeyValueRow>
              <KeyValueRow keyString="Amount Paid:">
                {' '}
                {`R ${numberFormat(amountPaid)}`}{' '}
              </KeyValueRow>
            </div>
            <Button
              onClick={() => setShowAll(false)}
              type="text"
              className="text-text-grey font-thin text-xs"
            >
              show less
            </Button>
          </>
        ) : (
          <>
            <KeyValueRow keyString="Other Fees:">
              {`R ${numberFormat(otherFeesTotal())}`}{' '}
            </KeyValueRow>
            <Button
              onClick={() => setShowAll(true)}
              type="text"
              className="text-text-grey font-thin text-xs"
            >
              show more
            </Button>
          </>
        )}
        <div className="flex flex-row border-t-2 border-gray-200">
          {showCheckBoxes && (
            <Checkbox
              onChange={onCheck}
              checked={checked}
              className="flex flex-grow-0 self-start"
            />
          )}
          <div className="flex flex-col place-items-center w-full mr-10">
            <p className="text-sm">Amount Outstanding</p>
            <div className="flex flex-1 flex-grow-2">{`R ${numberFormat(totalOutstanding)}`}</div>
          </div>
          <div className="flex">
            {hasImage ? (
              <div className="flex w-full h-full justify-center items-center">
                <button className="flex w-2/3" onClick={openModal} type="button">
                  <img src="/assets/icons/camera-icon.png" alt="menu" />
                </button>
              </div>
            ) : (
              <div className="flex w-full h-full justify-center items-center">
                <NoImageIcon className="flex" />
              </div>
            )}
          </div>
        </div>
      </Card>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles(responsive.isLarge)}
        contentLabel="Mobile Modal"
      >
        <div className="flex flex-col m-3">
          <div className="absolute right-5 top-2">
            <button onClick={closeModal} type="button">
              <CloseIcon className="text-text-grey" />
            </button>
          </div>
          <div className="flex flex-col mt-4">
            <p className="text-lg text-text-grey">{`Fine Image ${currentImageIndex + 1} / ${
              evidenceTokens.length
            }`}</p>
          </div>
        </div>
        <div
          className={
            loading ? 'h-60 border-t-2 w-60 flex justify-center items-center' : 'w-full border-t-2'
          }
        >
          {loading && (
            <div className="absolute flex justify-center items-center">
              <Loader
                visible={loading}
                type="BallTriangle"
                height={100}
                width={100}
                color="#1F487E"
              />
            </div>
          )}
          <img
            src={evidenceImageUrls[currentImageIndex]}
            alt=""
            className="px-5 py-5"
            onLoad={handleImageLoaded}
            onError={handleImageError}
          />
        </div>
      </Modal>
    </div>
  ) : (
    <div className="flex flex-col flex-grow">
      {/* <span className="text-white  text-lg">asdfjlskdjflsadfjlsak;dfj;alsdkfjlsdkjf</span> */}
      <Card className={`flex md:flex-row md:place-items-center ${className}`}>
        {showCheckBoxes && isPayable && (
          <Checkbox
            onChange={onCheck}
            disabled={!isPayable}
            checked={checked}
            className="flex flex-grow-0 self-start "
          />
        )}
        {showCheckBoxes && !isPayable && (
          <Tooltip title="Fine is not payable through the portal" className="self-start">
            <div className="flex">
              <Checkbox
                onChange={onCheck}
                disabled={!isPayable}
                checked={checked}
                className="flex flex-grow-0 self-start "
              />
            </div>
          </Tooltip>
        )}
        {columns.length ? null : <Divider orientation="vertical" flexItem className="mr-3" />}
        <div className="flex flex-1 flex-grow-5">
          {columns.length ? (
            <>{columns[0]}</>
          ) : (
            <div className="flex flex-col w-full">
              <KeyValueRow keyString="Ticket Number:"> {ticketNumber} </KeyValueRow>
              <KeyValueRow keyString="Reg No:"> {regNo}</KeyValueRow>
              <KeyValueRow keyString="Reason:"> {_renderReason(reason)} </KeyValueRow>
              <KeyValueRow keyString="Area:"> {_renderArea(area)} </KeyValueRow>
              <KeyValueRow keyString="Offence Date:"> {offenceDate} </KeyValueRow>
            </div>
          )}
        </div>
        {columns.length ? null : <Divider orientation="vertical" flexItem className="mx-1" />}
        <div className="flex flex-1 flex-grow-2">
          {columns.length ? (
            <>{columns[1]}</>
          ) : (
            <p className="text-sm">{`R ${numberFormat(fineAmount)}`}</p>
          )}
        </div>
        {columns.length ? null : <Divider orientation="vertical" flexItem className="mx-1" />}
        <div className="flex flex-1 flex-grow-2">
          {columns.length ? (
            <>{columns[2]}</>
          ) : (
            <p className="text-sm">{`R ${numberFormat(discount)}`}</p>
          )}
        </div>
        {columns.length ? null : <Divider orientation="vertical" flexItem className="mx-1" />}
        <div className="flex flex-1 flex-grow-4">
          {columns.length ? (
            <>{columns[3]}</>
          ) : (
            <div className="flex flex-col w-full">
              <KeyValueRow keyString="Warrant /AARTO Fee:" colName="other">
                {`R ${numberFormat(aartoFee)}`}
              </KeyValueRow>
              <KeyValueRow keyString="Enforcement Order:" colName="other">
                {`R ${numberFormat(enforcementOrder)}`}
              </KeyValueRow>
              <KeyValueRow keyString="Service Fee:" colName="other">
                {`R ${numberFormat(serviceFee)}`}
              </KeyValueRow>
            </div>
          )}
        </div>
        {columns.length ? null : <Divider orientation="vertical" flexItem className="mx-1" />}
        <div className="flex justify-center flex-1 flex-grow-1">
          {columns.length ? (
            <>{columns[4]}</>
          ) : (
            <p className="text-sm">{`${demeritPointsFormatter(demeritPoints)}`}</p>
          )}
        </div>
        {columns.length ? null : <Divider orientation="vertical" flexItem className="mx-1" />}
        <div className="flex flex-1 flex-grow-2">
          {columns.length ? (
            <>{columns[5]}</>
          ) : (
            <p className="text-sm">{`R ${numberFormat(amountPaid)}`}</p>
          )}
        </div>
        {columns.length ? null : <Divider orientation="vertical" flexItem className="mx-1" />}
        <div className="flex flex-1 flex-grow-2">
          {columns.length ? (
            <>{columns[6]}</>
          ) : (
            <p className="text-sm">{`R ${numberFormat(totalOutstanding)}`}</p>
          )}
        </div>
        <div className="flex flex-1 flex-grow-1 justify-center">
          {/* eslint-disable-next-line no-nested-ternary */}
          {columns.length ? (
            <div className="w-full" />
          ) : hasImage ? (
            <div className="flex w-full h-full justify-center items-center">
              <button className="flex w-1/3 2xl:w-1/4" onClick={openModal} type="button">
                <img src="/assets/icons/camera-icon.png" alt="icon" />
              </button>
            </div>
          ) : (
            !excludeImage && (
              <Tooltip title="No image available">
                <NoImageIcon className="flex w-3/5" />
              </Tooltip>
            )
          )}
        </div>
      </Card>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles(responsive.isLarge || responsive.isExtraLarge)}
        contentLabel="Desktop Modal"
      >
        <div className="flex flex-col px-3 py-3">
          <div className="absolute right-5 top-2">
            <button onClick={closeModal} type="button">
              <CloseIcon className="text-text-grey" />
            </button>
          </div>
          <div className="flex flex-col mt-3">
            <p className="text-lg text-text-grey">{`Fine Image ${currentImageIndex + 1} / ${
              evidenceTokens.length
            }`}</p>
          </div>
        </div>
        <div
          className={
            loading
              ? 'border-t-2 flex justify-center items-center py-5 px-5 2xl:py-10 2xl:px-10 w-96 h-96'
              : 'border-t-2 flex flex-col justify-center items-center py-5 px-5 2xl:py-10 2xl:px-10'
          }
        >
          <div className="absolute flex justify-center items-center">
            <Loader
              visible={loading}
              type="BallTriangle"
              height={100}
              width={100}
              color="#1F487E"
            />
          </div>
          {!loading && (
            <>
              <div className="flex justify-between w-full">
                <button type="button" className="underline cursor-pointer" onClick={prevImage}>
                  previous
                </button>
                <button type="button" className="underline cursor-pointer" onClick={nextImage}>
                  next
                </button>
              </div>
              <img
                src={evidenceImageUrls[currentImageIndex]}
                onLoad={handleImageLoaded}
                className="w-96"
                onError={handleImageError}
                alt=""
              />
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
