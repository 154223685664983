import { AxiosResponse } from 'axios';
import { useState } from 'react';

interface DownloadFileProps {
  readonly apiDefinition: () => Promise<AxiosResponse<Blob>>;
  readonly onSuccess?: () => void;
  readonly onError?: () => void;
  readonly fileName?: string;
}

interface DownloadedFileInfo {
  readonly download: () => Promise<void>;
  loading: boolean;
}

export const useDownloadFile = ({
  apiDefinition,
  onSuccess,
  onError,
  fileName,
}: DownloadFileProps): DownloadedFileInfo => {
  const [loading, setLoading] = useState<boolean>(false);
  const download = async () => {
    try {
      setLoading(true);
      const { data, headers } = await apiDefinition();

      const anchorElement = document.createElement('a');
      const url = URL.createObjectURL(new Blob([data]));
      anchorElement.href = url;
      const downloadFileName = fileName ?? decodeURIComponent(headers?.filename) ?? 'Unknown';
      anchorElement.setAttribute('download', downloadFileName);
      anchorElement.click();
      anchorElement.remove();

      if (onSuccess) onSuccess();
      setLoading(false);
      URL.revokeObjectURL(url);
    } catch (error) {
      // Disabled rule to just give some indication to the developer of what went wrong
      // eslint-disable-next-line no-console
      console.error(error);
      if (onError) onError();
      setLoading(false);
    }
  };

  return { download, loading };
};
