import { useQuery } from 'react-query';

import finesService from '../../services/api/fines/fines.api.service';
import { useGetOnboardStatus } from '../onboard-status/onboard-status.queries';

export const useIndividualUnpaidFines = (filter: string[], active = false) => {
  return useQuery(
    ['getIndividualUnpaidFines', filter],
    () => finesService.fetchIndividualUnpaidFines(filter),
    {
      enabled: active,
    },
  );
};

export const useBusinessUnpaidFines = (filter: string[], active = false) => {
  const getOnboardStatusQuery = useGetOnboardStatus();
  return useQuery(
    ['getBusinessUnpaidFines', filter],
    () => finesService.fetchBusinessUnpaidFines(filter),
    {
      enabled: active && getOnboardStatusQuery?.data?.fleet,
    },
  );
};

export const useIndividualPaidFines = (filter: string[], active = false) => {
  return useQuery(
    ['getIndividualPaidFines', filter],
    () => finesService.fetchIndividualPaidFines(filter),
    { enabled: active },
  );
};

export const useBusinessPaidFines = (filter: string[], active = false) => {
  return useQuery(
    ['getBusinessPaidFines', filter],
    () => finesService.fetchBusinessPaidFines(filter),
    { enabled: active },
  );
};
