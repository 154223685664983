import { Dispatch, SetStateAction, useState } from 'react';
import { FinePaymentStatus } from '../../types';

export const useFinePaymentStatus = (): {
  status: FinePaymentStatus;
  setStatus: Dispatch<SetStateAction<FinePaymentStatus>>;
} => {
  const [status, setStatus] = useState(FinePaymentStatus.Unpaid);

  return {
    status,
    setStatus,
  };
};
