import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import _ from 'lodash';
import { InternalTextArea, InternalTextAreaProps } from '../internal-text-area/internal-text-area';

type InternalHookFormTextAreaProps<T> = InternalTextAreaProps & {
  id?: string;
  placeholder?: string;
  register?: UseFormRegister<T>;
  variableName: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  className?: string;
  hidden?: boolean;
  errors?: Record<string, unknown>;
};

export const InternalHookFormTextArea: React.FC<InternalHookFormTextAreaProps<any>> = ({
  id,
  placeholder,
  register,
  variableName,
  onChange,
  onBlur,
  className,
  errors,
  ...rest
}) => {
  const error = _.get(errors, variableName) as any;

  return (
    <>
      <InternalTextArea
        id={id}
        {...register(variableName, {
          onChange: (e) => {
            if (onChange) onChange(e);
          },
          onBlur: (e) => {
            if (onBlur) onBlur(e);
          },
        })}
        placeholder={placeholder}
        className={className}
        {...rest}
      />
      {!!error && <span className="text-xxs text-red-500">{error.message}</span>}
    </>
  );
};
