// Add prototype changes. Use with CAUTION

export const addPrototypes = () => {
  Object.defineProperty(Array.prototype, 'isNullOrEmpty', {
    value() {
      if (this === null || this === undefined) return true;
      if (this.length === 0) return true;
      return false;
    },
  });
};
