import { useState } from 'react';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { AccountProfiles, Business, Guid } from '../../types';
import { getAccountProfiles } from '../../services/api/admin-profiles/admin-profiles.service';

export const useBusiness = (userId: Guid) => {
  const [activeBusinessEntityId, setActiveBusinessEntityId] = useState<Guid | null>(null);

  const businessEntitiesQuery = useQuery<AccountProfiles, void, Business>(
    ['AdminAccountProfileData', userId],
    () => getAccountProfiles(userId),
    {
      enabled: !!userId,
      select: (result) => {
        return result?.business;
      },
      onSuccess: (result) => {
        if (activeBusinessEntityId === null) {
          setActiveBusinessEntityId(_.first(result?.businesses)?.id);
        }
      },
    },
  );

  return {
    businessEntities: businessEntitiesQuery?.data?.businesses ?? [],
    businessEntityLinks:
      businessEntitiesQuery?.data?.businesses
        ?.filter((s) => s.id === activeBusinessEntityId)
        ?.flatMap((business) => business.links) ?? [],
    activeBusinessEntityId,
    setActiveBusinessEntityId,
    fleetId: businessEntitiesQuery?.data?.id,
    ownerId: userId,
  };
};
