import React, { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { Field, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { FormHelperText } from '@material-ui/core';
import { Button, FormTextField } from '../../atoms';
import { ErrorObject, BaseFormProps, AddFineProps } from '../../../types';

export const AddFinesForm: React.FC<BaseFormProps<AddFineProps>> = ({
  initialValues,
  submitForm,
  onSuccess,
}) => {
  const [generalError, setGeneralError] = useState(null);
  const _handleFormSubmitError = (error: ErrorObject, actions: FormikHelpers<AddFineProps>) => {
    actions.setSubmitting(false);
    setGeneralError(error.detail);
  };

  const _handleSubmission = (formData: AddFineProps, actions: FormikHelpers<AddFineProps>) => {
    submitForm(formData)
      .then(() => {
        onSuccess();
      })
      .catch((error: ErrorObject) => {
        _handleFormSubmitError(error, actions);
      })
      .finally(() => actions.setSubmitting(false));
  };
  const AddFineSchema = Yup.object().shape({
    identificationNumber: Yup.string()
      .length(13, 'ID should be exactly 13 characters long')
      .required('ID Number is required'),
    vehicleRegistration: Yup.string().required('Vehicle Registration is Required '),
    noticeNumber: Yup.string()
      .matches(/^(\S+$)/g, '* This field cannot contain blankspaces')
      .required('Notice Number is Required'),
    municipality: Yup.string().required('Municipality is Required'),
    offenceLocation: Yup.string().required('Offence Location is Required'),
    amountDue: Yup.string().required('The Amount Due is Required'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      validateOnChange={false}
      onSubmit={_handleSubmission}
      validationSchema={AddFineSchema}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleReset,
        setFieldValue,
      }) => {
        const onPasteNoticeNumber = (e: ClipboardEvent) => {
          if (e.clipboardData) {
            const value = e.clipboardData.getData('text/plain').trim();
            if (value && value.length > 0) {
              const target = e.target as HTMLInputElement;
              target.value = value;
              setFieldValue('noticeNumber', value, true);
              e.preventDefault();
            }
          }
        };

        const _setDate = (e) => {
          setFieldValue('offenceDate', e);
        };
        return (
          <form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="flex flex-auto flex-col gap-3 py-1 mx-7"
          >
            <p className="flex justify-center auth-heading text-center">Fine Details</p>
            {generalError && <FormHelperText error>{generalError}</FormHelperText>}
            <p className=" text-xs text-left">Identification Number :</p>
            <FormTextField
              variableName="identificationNumber"
              placeholder="Identification Number"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="self-center w-96"
            />
            <p className="FormTextField text-xs">Identification Type :</p>
            <Field as="select" name="identificationType">
              <option value="SaIdNumber">SaIdNumber</option>
              <option value="Brn">Brn</option>
              <option value="Trn">Trn</option>
              <option value="ProxyId">ProxyId</option>
            </Field>
            <>
              <p className="FormTextField text-xs">Vehicle Registration :</p>
              <FormTextField
                variableName="vehicleRegistration"
                placeholder="Vehicle Registration"
                values={values}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched}
                errors={errors}
                required
                className=" self-center w-96"
              />
              <p className="FormTextField text-xs">Notice Number :</p>
              <FormTextField
                variableName="noticeNumber"
                placeholder="Notice Number"
                values={values}
                onChange={handleChange}
                onPaste={onPasteNoticeNumber}
                onBlur={handleBlur}
                touched={touched}
                errors={errors}
                required
                className=" self-center w-96"
              />
            </>
            <p className="FormTextField text-xs">OffenceDate :</p>
            <DateTimePicker
              clearIcon={null}
              format="y-MM-dd HH:mm"
              onChange={_setDate}
              value={values.offenceDate}
              disableClock
            />

            <p className="FormTextField text-xs">Municipality :</p>
            <FormTextField
              variableName="municipality"
              placeholder="Municipality"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />
            <p className="FormTextField text-xs">Offence Location :</p>
            <FormTextField
              variableName="offenceLocation"
              placeholder="Offence Location"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />
            <p className="FormTextField text-xs">Offence Description :</p>
            <FormTextField
              variableName="offenceDescription"
              placeholder="Offence Description"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />
            <p className="FormTextField text-xs">Manual Discount :</p>
            <FormTextField
              type="text"
              variableName="manualDiscount"
              placeholder="Manual Discount"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              className="w-96"
            />
            <p className="FormTextField text-xs">Amount Due :</p>
            <FormTextField
              type="text"
              variableName="amountDue"
              placeholder="Amount Due"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />

            <Button
              isLoading={isSubmitting}
              onClick={handleSubmit}
              className="w-4/5 bg-secondary hover:bg-secondary-dark text-white text-base self-center my-7"
            >
              Add Fine
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
