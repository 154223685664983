import { AxiosResponse } from 'axios';
import authNetworkService from '../auth-network/auth-network.service';
import finesUrls from './fine-images.urls';

const fetchFineImage = (evidenceToken: string) => {
  const url = finesUrls.getFineImageUrl();

  return authNetworkService
    .get(url, {
      responseType: 'blob',
      params: {
        evidenceToken,
      },
    })
    .then((apiResponse: AxiosResponse<BlobPart>) => {
      return window.URL.createObjectURL(new Blob([apiResponse.data]));
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  fetchFineImage,
};
