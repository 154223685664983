import { AxiosResponse } from 'axios';
import * as qs from 'qs';
import authNetworkService from '../auth-network/auth-network.service';
import userUrls from './user.urls';
import { Guid, PaginatedList, ReportFilterProps, User } from '../../../types';
import { RoleEnum } from '../../../enums/role.enum';

const get = async (pageNumber = 1, pageSize = 10, role: RoleEnum = RoleEnum.CallCenterAgent) => {
  const url = userUrls.getBaseUrl();

  const response = await authNetworkService
    .get<PaginatedList<User>>(url, {
      params: { pageNumber, pageSize, role },
      paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
    })
    .catch((error) => {
      return Promise.reject(error);
    });
  return response.data;
};

const deletePortalUserAccount = (userId: Guid) => {
  const url = userUrls.getUserResourceUrl(userId);

  return authNetworkService.delete(url).catch((error) => {
    return Promise.reject(error);
  });
};

export const getUnverifiedEmailsReport = (
  filterProps?: ReportFilterProps,
): Promise<AxiosResponse<Blob>> => {
  const url = userUrls.baseContentUrl();

  return authNetworkService.get(url, {
    responseType: 'blob',
    timeout: 0,
    headers: { Accept: 'application/csv' },
    params: {
      fromDate: filterProps.fromDate,
      toDate: filterProps.toDate,
      attributes: ['Email', 'CreatedDate', 'PhoneNumber', 'FullName'],
      isUnverified: true,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  });
};

export const getUserProfiles = (): Promise<AxiosResponse<Blob>> => {
  const url = userUrls.baseContentUrl();

  return authNetworkService.get(url, {
    responseType: 'blob',
    timeout: 0,
    headers: { Accept: 'application/csv' },
    params: {
      attributes: ['Identifier', 'Email', 'ProfileType'],
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  });
};

const updateIsWhatsAppVerified = (userId: string, isWhatsAppVerified: boolean): Promise<void> => {
  const url = `${userUrls.getUserResourceUrl(userId)}/IsWhatsAppVerified`;

  return authNetworkService.put(url, { isVerified: isWhatsAppVerified });
};

export const upsertExtension = async (
  userId: string,
  extension: string,
): Promise<{ userId: string; extension: string }> => {
  const url = userUrls.extensionUrl(userId);

  await authNetworkService.put(url, { extension });
  return { userId, extension };
};

export const deleteExtension = async (userId: string): Promise<{ userId: string }> => {
  const url = userUrls.extensionUrl(userId);

  await authNetworkService.delete(url);
  return { userId };
};

export default {
  get,
  deletePortalUserAccount,
  getUnverifiedEmailsReport,
  getUserProfiles,
  updateIsWhatsAppVerified,
  upsertExtension,
  deleteExtension,
};
