import { AxiosResponse } from 'axios';
import * as qs from 'qs';
import networkService from '../network/network.service';
import invoiceUrls from './invoice.url';
import {
  Guid,
  InvoicePrintTemplateDto,
  PaymentUrlDto,
  PortalInvoiceDetailDto,
  ServiceResult,
} from '../../../types';
import authNetworkService from '../auth-network/auth-network.service';

const getInvoice = (id: Guid) => {
  const url = invoiceUrls.getInvoiceUrl(id);
  return networkService
    .get(url)
    .then((s: AxiosResponse<ServiceResult<PortalInvoiceDetailDto>>) => s.data.data);
};

const createInvoicePaymentLink = (id: Guid, source: number) => {
  const url = invoiceUrls.getInvoicePaymentLinkUrl(id);
  return networkService
    .post(
      url,
      {
        source,
      },
      { timeout: 0 },
    )
    .then((s: AxiosResponse<ServiceResult<PaymentUrlDto>>) => s.data.data);
};

const createInvoicePrintTemplate = (id: Guid) => {
  const url = invoiceUrls.getInvoicePrintTemplateUrl(id);
  return networkService
    .post(url)
    .then((s: AxiosResponse<ServiceResult<InvoicePrintTemplateDto>>) => s.data.data);
};

export const getInvoiceTotalsContent = (
  fromDate?: string,
  toDate?: string,
): Promise<AxiosResponse<Blob>> => {
  const url = invoiceUrls.getInvoiceTotalsContentUrl();

  return authNetworkService.get(url, {
    responseType: 'blob',
    timeout: 0,
    headers: { Accept: 'application/csv' },
    params: {
      fromDate,
      toDate,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  });
};

export default {
  getInvoice,
  createInvoicePaymentLink,
  createInvoicePrintTemplate,
  getInvoiceTotalsContent,
};
