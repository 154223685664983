import React, { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import _ from 'lodash';

import { Card, FormHelperText, IconButton } from '@material-ui/core';

import { Edit, HighlightOff } from '@material-ui/icons';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import { Button, OffenderTypeTextField } from '../../atoms';
import { onboardBusinessValidation } from '../../../validators';
import { ErrorObject, BaseFormProps, OnboardBusinessProps, LinkProps } from '../../../types';
import { TextFieldCollection } from '../../molecules';
import { OffenderIdentifierType, OffenderIdentifierTypeDescription } from '../../../enums';

export const OnboardBusinessForm: React.FC<BaseFormProps<OnboardBusinessProps>> = ({
  initialValues,
  submitForm,
  onSuccess,
  onFailure,
}) => {
  const names = ['entityName', 'address', 'vatNo', 'companyRegistration'];
  const placeholders = ['Entity Name', 'Address', 'Vat No.', 'Company Reg.'];
  const icons = [
    <img src="./assets/icons/building-icon.png" alt="businessentity" />,
    <img src="assets/icons/building-icon.png" alt="buildingName" />,
    <img src="assets/icons/contact-icon.png" alt="contact" />,
    <img src="assets/icons/id-card-icon.png" alt="companyReg" />,
  ];
  const linkTypes = [OffenderIdentifierType.Brn, OffenderIdentifierType.ProxyId];

  const _handleFormSubmitError = (
    error: ErrorObject,
    actions: FormikHelpers<OnboardBusinessProps>,
  ) => {
    actions.setSubmitting(false);
    const apiErrors = error.errors;
    if (!_.isEmpty(apiErrors)) {
      actions.setFieldError('businessEntities', apiErrors.businessEntities);
    }
    if (error.statusCode === 422) {
      actions.setFieldError('businessEntities', error.detail);
    }
  };

  const _handleSubmission = (
    formData: OnboardBusinessProps,
    actions: FormikHelpers<OnboardBusinessProps>,
  ) => {
    const nonEmptyBusinessEntityData = _.filter(formData.businessEntities, (business) => {
      if (_.isEmpty(business.address) && business.links.every((link) => _.isEmpty(link.value))) {
        return false;
      }
      return true;
    });

    const newForm: OnboardBusinessProps = {
      businessEntities: nonEmptyBusinessEntityData,
    };

    submitForm(newForm)
      .then(() => {
        actions.setSubmitting(false);
        onSuccess();
      })
      .catch((error: ErrorObject) => {
        _handleFormSubmitError(error, actions);
        onFailure();
      });
  };

  const _renderIfString = (value: any) => {
    if (typeof value === 'string') return value;
    return '';
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={onboardBusinessValidation}
      validateOnBlur
      validateOnChange={false}
      onSubmit={_handleSubmission}
      enableReinitialize
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleReset,
        setFieldValue,
        validateField,
      }) => {
        const [displayToolTip, setDisplayToolTip] = useState(false);

        const _addEntity = () => {
          const isValid = Object.keys(errors).length === 0;
          if (!isValid) return;

          const currentBusinessEntity = _.last(values.businessEntities);
          if (
            !_.isEmpty(currentBusinessEntity.entityName) &&
            currentBusinessEntity.links.every((link) => !_.isEmpty(link.value))
          ) {
            setFieldValue('businessEntities', [
              ...values.businessEntities,
              {
                entityName: '',
                address: '',
                vatNo: '',
                companyRegistration: '',
                links: [{ value: '', identifierType: OffenderIdentifierType.Brn }],
              },
            ]);
          }
        };

        const _editEntity = (index: number) => () => {
          const entities = _.filter(_.cloneDeep(values.businessEntities), (business) => {
            if (!_.isEmpty(business?.entityName)) {
              return true;
            }
            return false;
          });
          entities.push(entities.splice(index, 1)[0]);

          setFieldValue('businessEntities', entities);
        };

        const _removeEntity = (index: number) => () => {
          const entities = _.cloneDeep(values.businessEntities);
          entities.splice(index, 1);

          setFieldValue('businessEntities', entities);
        };

        const _addLink = (index: number, identifierType: OffenderIdentifierType) => () => {
          setFieldValue(`businessEntities.${index}.links`, [
            ...values.businessEntities[index].links,
            { value: '', identifierType },
          ]);
        };

        const _removeLink = (entityIndex: number, linkIndex: number) => () => {
          const myLinks = _.cloneDeep(values.businessEntities[entityIndex].links);
          myLinks.splice(linkIndex, 1);

          setFieldValue(`businessEntities.${entityIndex}.links`, myLinks);
          setTimeout(() => validateField(`businessEntities`), 200);
        };
        const entityCount = values.businessEntities.length - 1;
        const entity = values.businessEntities[entityCount];

        const toolTip = () => {
          return (
            <>
              <div className="flex flex-row bg-blue-400 rounded-lg text-white p-1 m-2">
                <div className="flex justify-center items-center p-2">
                  <EmojiObjectsOutlinedIcon />
                </div>
                <p className="text-left">
                  A BRN (Business Registration Number) is the unique 13 digit number issued to a
                  business by the Vehicle Registration Authority.
                </p>
              </div>
            </>
          );
        };

        const _renderLinks = () =>
          _.map(entity.links, (link: LinkProps, linkIndex: number) => {
            return (
              <div className="flex flex-row w-full justify-evenly">
                <div className="flex w-full">
                  <OffenderTypeTextField
                    key={`businessEntity-${entityCount}-link-${linkIndex}`}
                    variableType={`businessEntities.${entityCount}.links.${linkIndex}.identifierType`}
                    variableTypes={linkTypes}
                    variableName={`businessEntities.${entityCount}.links.${linkIndex}.value`}
                    values={values}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    openInitial
                    icon={<img src="./assets/icons/contact-icon.png" alt="contact" />}
                    endIcon={
                      linkIndex === 0 ? null : (
                        <IconButton
                          className="h-4 w-4 m-0 p-0"
                          onClick={_removeLink(entityCount, linkIndex)}
                        >
                          <HighlightOff className="h-4 w-4 m-0 p-0" />
                        </IconButton>
                      )
                    }
                    required
                    className="mb-2 flex w-11/12"
                    placeholder={
                      OffenderIdentifierTypeDescription[
                        values.businessEntities[entityCount].links[linkIndex].identifierType
                      ]
                    }
                  />
                </div>
                {linkIndex === 0 ? (
                  <div className="p-1 w-5">
                    <IconButton
                      onClick={() => {
                        setDisplayToolTip(!displayToolTip);
                      }}
                      className="p-1 w-4 "
                      disableFocusRipple
                      disableRipple
                    >
                      <InfoOutlinedIcon className="h-4 w-4" />
                    </IconButton>
                  </div>
                ) : (
                  <div className="p-1 w-5 h-4" />
                )}
              </div>
            );
          });

        const _renderCurrentBusinessEntity = () => {
          return (
            <div key={`businessEntity-${entityCount}`} className="flex flex-col flex-1 self-center">
              <>
                <TextFieldCollection
                  index={entityCount}
                  variableName="businessEntities"
                  names={names}
                  placeHolders={placeholders}
                  icons={icons}
                  values={values}
                  errors={errors}
                  touched={touched}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  containerClassName="my-4 w-full"
                  className="mb-2"
                  required
                />
                {displayToolTip && toolTip()}
                <div className="flex flex-row w-full justify-evenly">
                  <div className="flex w-full" />
                </div>
              </>
              <FormHelperText error>
                {_renderIfString(_.get(errors, `businessEntities.${entityCount}.links`))}
              </FormHelperText>
              <>{_renderLinks()}</>

              <Button
                onClick={_addLink(entityCount, OffenderIdentifierType.Brn)}
                className="text-secondary self-end "
                type="text"
              >
                add more
              </Button>
            </div>
          );
        };

        const _renderSubmittedBusinessEntities = () =>
          _.map(values.businessEntities, (businessEntity, index) => {
            if (index < values.businessEntities.length - 1) {
              const { links } = businessEntity;

              const _filterAndFlatten = (type: OffenderIdentifierType) => {
                const filter = _.filter(links, { identifierType: type });
                const linkValues = [];
                _.each(filter, (link) => linkValues.push(link.value));
                return _.join(linkValues, ', ');
              };

              const _brns = _filterAndFlatten(OffenderIdentifierType.Brn);

              const _proxyIds = _filterAndFlatten(OffenderIdentifierType.ProxyId);

              return (
                <Card className="flex mt-1.5">
                  <div className="p-2">
                    <img src="assets/icons/id-card-icon.png" alt="companyReg" />
                  </div>
                  <div className="text-left flex flex-col justify-center">
                    <p className="text-xs font-bold text-text-grey">{businessEntity.entityName}</p>
                    <p className="text-xs font-medium text-text-grey">{`Address: ${businessEntity.address}`}</p>
                    <p className="text-xs font-medium text-text-grey">{`Vat No: ${businessEntity.vatNo}`}</p>
                    <p className="text-xs font-medium text-text-grey">{`Company Reg: ${businessEntity.companyRegistration}`}</p>
                    {_brns.length > 0 && (
                      <p className="text-xs font-thin text-text-grey">{`BRNs: ${_brns}`}</p>
                    )}
                    {_proxyIds.length > 0 && (
                      <p className="text-xs font-thin text-text-grey">{`Proxy IDs: ${_proxyIds}`}</p>
                    )}
                  </div>
                  <div className="flex flex-1" />
                  <IconButton onClick={_editEntity(index)} className="m-1">
                    <Edit className="h-4 w-4" />
                  </IconButton>
                  <IconButton onClick={_removeEntity(index)} className="m-1">
                    <HighlightOff className="h-4 w-4" />
                  </IconButton>
                </Card>
              );
            }
            return <></>;
          });

        return (
          <form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="auth-form-body text-center h-formThird w-full"
          >
            <div className="flex flex-col flex-1 self-center w-4/5 contain-content">
              <p className="auth-heading">Entity Details</p>
              <div className="overflow-y-auto">
                <div className="grid gap-4  ">{_renderSubmittedBusinessEntities()}</div>
              </div>

              <hr className="bg-black h-0.7 border-black mt-3" />
              <div className="grid gap-4 w-full overflow-y-auto ">
                {_renderCurrentBusinessEntity()}
              </div>
              <FormHelperText error>
                {_renderIfString(_.get(errors, `businessEntities`))}
              </FormHelperText>
              <div className="flex flex-1 flex-col" />

              <Button onClick={_addEntity} className="text-secondary" type="text">
                + Add Additional Entity
              </Button>
              <Button
                isLoading={isSubmitting}
                onClick={handleSubmit}
                className="auth-form-submit-button"
              >
                Continue
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
