import React from 'react';

export type InternalTextAreaProps = {
  id: string;
  className?: string;
  classNameOverride?: string;
  placeholder?: string;
  value?: string;
  rows?: number;
  cols?: number;
};

export const InternalTextArea = React.forwardRef<HTMLTextAreaElement, InternalTextAreaProps>(
  ({ id, className, classNameOverride, placeholder, value, rows, cols, ...rest }, ref) => {
    return (
      <textarea
        id={id}
        ref={ref}
        className={classNameOverride || `px-2 border border-grey-brand-3 rounded-sm ${className}`}
        placeholder={placeholder}
        value={value}
        rows={rows}
        cols={cols}
        {...rest}
      />
    );
  },
);
