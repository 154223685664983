import { AxiosResponse } from 'axios';
import * as qs from 'qs';
import authNetworkService from '../auth-network/auth-network.service';
import {
  ServiceResult,
  Guid,
  CallHistoryDto,
  FinishCallProps,
  PaginatedList,
} from '../../../types';
import adminCallsUrls from './admin-calls.urls';

const createCall = (callContactId: Guid, callCentreAgentId: string) => {
  const url = adminCallsUrls.callsUrl();
  return authNetworkService
    .post(url, { callContactId, callCentreAgentId })
    .catch((error) => {
      return Promise.reject(error);
    })
    .then((s: AxiosResponse<ServiceResult<Guid>>) => s.data.data);
};

const getCalls = async (
  pageNumber: number,
  pageEntries: number,
  callCentreAgentId: string = null,
  callContactId: string = null,
  fromDate?: Date,
  toDate?: Date,
): Promise<PaginatedList<CallHistoryDto>> => {
  const url = `${adminCallsUrls.callsUrl()}`;
  const response = await authNetworkService.get<ServiceResult<PaginatedList<CallHistoryDto>>>(url, {
    params: {
      pageNumber,
      pageEntries,
      callCentreAgentId: callCentreAgentId != null ? callCentreAgentId : '',
      callContactId: callContactId != null ? callContactId : '',
      fromDate: fromDate != null ? fromDate : '',
      toDate: toDate != null ? toDate : '',
    },
  });
  return response.data.data;
};

const finishCall = async (formData: FinishCallProps) => {
  const url = adminCallsUrls.finishCallUrl();
  return authNetworkService.post(url, formData).catch((error) => {
    return Promise.reject(error);
  });
};

export const getCallTotalsContent = (
  fromDate?: string,
  toDate?: string,
): Promise<AxiosResponse<Blob>> => {
  const url = adminCallsUrls.getCallTotalsContentUrl();

  return authNetworkService.get(url, {
    responseType: 'blob',
    timeout: 0,
    headers: { Accept: 'application/csv' },
    params: {
      fromDate,
      toDate,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  });
};

export default {
  createCall,
  getCalls,
  finishCall,
  getCallTotalsContent,
};
