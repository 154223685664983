import React from 'react';
import { Button, Card } from '@material-ui/core';

type Props = {
  page: number;
  setPage: (page: number) => void;
  pageIndex: number;
  totalPages: number;
};

export const PaginationButtons: React.FC<Props> = ({
  page,
  setPage,
  pageIndex = 1,
  totalPages,
}) => {
  return (
    <span className="flex justify-end mr-20 my-2">
      <Button
        className="PaginationButtons"
        onClick={() => (page === 1 ? setPage(page) : setPage(page - 1))}
      >
        Prev Page
      </Button>
      <Button
        className="PaginationButtons"
        onClick={() => (page < totalPages ? setPage(page + 1) : totalPages)}
      >
        Next Page
      </Button>
      <Card className="cursor-default mr-3 bg-secondary bg-opacity-80 text-white hover:bg-secondary hover:bg-opacity-80 pt-2 px-1 font-sans">
        Page: {pageIndex}/{totalPages}
      </Card>
    </span>
  );
};
