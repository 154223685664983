import React, { useState } from 'react';
import { Field, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { FormHelperText } from '@material-ui/core';
import _ from 'lodash';
import { Button, FormTextField } from '../../atoms';
import { ErrorObject, BaseFormProps, SignUpConsultantProps } from '../../../types';

export const AddConsultantsForm: React.FC<BaseFormProps<SignUpConsultantProps>> = ({
  initialValues,
  submitForm,
  onSuccess,
}) => {
  const [generalError, setGeneralError] = useState(null);
  const _handleFormSubmitError = (
    error: ErrorObject,
    actions: FormikHelpers<SignUpConsultantProps>,
  ) => {
    actions.setSubmitting(false);
    const apiErrors = error.errors;
    if (!_.isEmpty(apiErrors)) {
      actions.setFieldError('email', apiErrors.DuplicateEmail);
    }
    setGeneralError(error.detail);
  };

  const _handleSubmission = (
    formData: SignUpConsultantProps,
    actions: FormikHelpers<SignUpConsultantProps>,
  ) => {
    submitForm(formData)
      .then(() => {
        onSuccess();
      })
      .catch((error: ErrorObject) => {
        _handleFormSubmitError(error, actions);
      })
      .finally(() => actions.setSubmitting(false));
  };
  const AddConsultantSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email().required('Email is required'),
    cellphoneNumber: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Phone number is not valid',
      )
      .required('Phone Number is required'),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnBlur
        validateOnChange={false}
        onSubmit={_handleSubmission}
        enableReinitialize
        validationSchema={AddConsultantSchema}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleReset,
        }) => (
          <form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="flex flex-auto flex-col gap-3 py-1 mx-7"
          >
            <p className="flex justify-center auth-heading text-center">Add New Consultant</p>
            {generalError && <FormHelperText error>{generalError}</FormHelperText>}

            <p className="flex justify-start text-left text-xs">First Name :</p>
            <FormTextField
              variableName="firstName"
              placeholder="First Name"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />
            <p className="flex justify-start text-left text-xs">Last Name :</p>
            <FormTextField
              variableName="lastName"
              placeholder="Last Name"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />
            <p className="flex justify-start text-left text-xs">Email :</p>
            <FormTextField
              variableName="email"
              placeholder="Email"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />
            <p className="flex justify-start text-left text-xs">Cellphone :</p>
            <FormTextField
              variableName="cellphoneNumber"
              placeholder="Cellphone Number"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />
            <p className="flex justify-start text-left text-xs">Role :</p>
            <Field as="select" name="role">
              <option value="Consultant">Consultant</option>
              <option value="SuperAdministrator">SuperAdministrator</option>
              <option value="CallCenterAgent">CallCenterAgent</option>
              <option value="CallCenterManager">CallCenterManager</option>
            </Field>

            <Button
              isLoading={isSubmitting}
              onClick={handleSubmit}
              className="w-4/5 bg-secondary hover:bg-secondary-dark text-white text-base self-center my-7"
            >
              Add Consultant
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};
