import { AddFleetEntityLinkProps } from '../../../types';
import authNetworkService from '../auth-network/auth-network.service';
import adminFleetEntityLinksUrls from './admin-fleet-entity-links.urls';

const deleteFleetEntityLink = (fleetEntityLinkId: string) => {
  const url = adminFleetEntityLinksUrls.deleteFleetEntityLinks(fleetEntityLinkId);

  return authNetworkService.delete(url).catch((error) => {
    return Promise.reject(error);
  });
};

const editFleetEntityLinks = (fleetEntityLinkId, formData) => {
  const url = adminFleetEntityLinksUrls.editFleetEntityLinks(fleetEntityLinkId);

  return authNetworkService.put(url, formData).catch((error) => {
    return Promise.reject(error);
  });
};

const addFleetEntityLink = (formData: AddFleetEntityLinkProps) => {
  const url = adminFleetEntityLinksUrls.addFleetEntityLinkUrl();
  return authNetworkService.post(url, formData);
};

export default {
  deleteFleetEntityLink,
  editFleetEntityLinks,
  addFleetEntityLink,
};
