import authNetworkService from '../auth-network/auth-network.service';
import euphoriaCallsUrls from './euphoria-calls.urls';

const createCall = async (cellPhoneNumber: string) => {
  const url = euphoriaCallsUrls.baseUrl();

  const response = await authNetworkService.post<string>(url, { cellPhoneNumber });
  return response.data;
};

export default {
  createCall,
};
