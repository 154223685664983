import React, { forwardRef, useImperativeHandle } from 'react';
import { Formik, FormikHelpers } from 'formik';
import _ from 'lodash';

import { Checkbox } from '@material-ui/core';
import { FormTextField } from '../../atoms';
import { ErrorObject, BaseFormProps, EditProfileInfoProps } from '../../../types';
import { OffenderIdentifierType } from '../../../enums';
import { profileEditValidationSchema } from '../../../validators';
import { RoleEnum } from '../../../enums/role.enum';

export const EditProfileInfoForm = forwardRef<any, BaseFormProps<EditProfileInfoProps>>(
  ({ initialValues, submitForm, onSuccess, disabled, isBusiness, onFailure }, ref) => {
    const isPortalUser = initialValues?.roles?.some((role) => role === RoleEnum.PortalUser);

    const _handleFormSubmitError = (
      error: ErrorObject,
      actions: FormikHelpers<EditProfileInfoProps>,
    ) => {
      actions.setSubmitting(false);
      const apiErrors = error.errors;
      if (!_.isEmpty(apiErrors)) {
        actions.setFieldError('firstName', apiErrors.firstName);
        actions.setFieldError('lastName', apiErrors.lastName);
        actions.setFieldError('email', apiErrors.email);
        actions.setFieldError('cellphone', apiErrors.cellphone);
      } else {
        onFailure();
      }
    };

    const _handleSubmission = (
      formData: EditProfileInfoProps,
      actions: FormikHelpers<EditProfileInfoProps>,
    ) => {
      submitForm(formData)
        .then(() => {
          onSuccess();
        })
        .catch((error: ErrorObject) => {
          _handleFormSubmitError(error, actions);
        })
        .finally(() => actions?.setSubmitting(false));
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={isBusiness || !isPortalUser ? null : profileEditValidationSchema}
        onSubmit={_handleSubmission}
        enableReinitialize
        disabled={disabled}
        isBusiness={isBusiness}
        innerRef={ref}
      >
        {({
          handleSubmit,
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleReset,
          setFieldValue,
        }) => {
          const onChangeTrn = () => {
            setFieldValue(
              'identifierType',
              values.identifierType === OffenderIdentifierType.SaIdNumber
                ? OffenderIdentifierType.Trn
                : OffenderIdentifierType.SaIdNumber,
            );
          };

          useImperativeHandle(ref, () => {
            return {
              handleSubmission: handleSubmit,
              errors,
            };
          });
          return (
            <form
              id="EditProfileForm"
              onReset={handleReset}
              onSubmit={handleSubmit}
              className="flex flex-auto flex-col"
            >
              {disabled ? (
                isBusiness ? (
                  <div className="flex flex-row rounded-md flex-1 ">
                    <div className="flex flex-col flex-grow-1">
                      <p>Name: </p>
                      <p>Surname: </p>
                      <p>Cell: </p>
                      <p>Email: </p>
                      <p>Comms:</p>
                    </div>
                    <div className="flex flex-col flex-grow-1 ml-2">
                      <FormTextField
                        variableName="firstName"
                        placeholder="First Name"
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required
                        className="h-6 w-60 shadow-none"
                        inputClassName="h-6"
                        disabled
                      />
                      <FormTextField
                        variableName="lastName"
                        placeholder="Last Name"
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required
                        className="h-6 w-60 shadow-none"
                        inputClassName="h-6"
                        disabled
                      />

                      <FormTextField
                        variableName="cellPhoneNumber"
                        placeholder="Cellphone Number"
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required
                        className="h-6 w-60 shadow-none "
                        inputClassName="h-6 border-0"
                        disabled
                      />
                      <FormTextField
                        variableName="email"
                        placeholder="Email"
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required
                        className="h-6 w-60 shadow-none"
                        inputClassName="h-6"
                        disabled
                      />
                      <FormTextField
                        variableName="communicationEmail"
                        placeholder="Email 2"
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        className="h-6 w-60 shadow-none"
                        inputClassName="h-6"
                        disabled
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-row rounded-md flex-1 ">
                    <div className="flex flex-col flex-grow-1">
                      <p>Name: </p>
                      <p>Surname: </p>
                      <p>ID: </p>
                      <p>Cell: </p>
                      <p>Email: </p>
                      <p>Comms:</p>
                    </div>
                    <div className="flex flex-col flex-grow-1 ml-2">
                      <FormTextField
                        variableName="firstName"
                        placeholder="First Name"
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required
                        className="h-6 w-60 shadow-none"
                        inputClassName="h-6"
                        disabled
                      />
                      <FormTextField
                        variableName="lastName"
                        placeholder="Last Name"
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required
                        className="h-6 w-60 shadow-none"
                        inputClassName="h-6"
                        disabled
                      />
                      <FormTextField
                        variableName="identifier"
                        placeholder="ID"
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required
                        className="h-6 w-60 shadow-none"
                        inputClassName="h-6"
                        disabled
                      />

                      <FormTextField
                        variableName="cellPhoneNumber"
                        placeholder="Cellphone Number"
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required
                        className="h-6 w-60 shadow-none "
                        inputClassName="h-6 border-0"
                        disabled
                      />
                      <FormTextField
                        variableName="email"
                        placeholder="Email"
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required
                        className="h-6 w-60 shadow-none"
                        inputClassName="h-6"
                        disabled
                      />
                      <FormTextField
                        variableName="communicationEmail"
                        placeholder="Email 2"
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        className="h-6 w-60 shadow-none"
                        inputClassName="h-6"
                        disabled
                      />
                    </div>
                  </div>
                )
              ) : isBusiness ? (
                <div className="flex flex-row rounded-md flex-1">
                  <div className="flex flex-col flex-grow-1 gap-y-1">
                    <p>Name: </p>
                    <p>Surname: </p>
                    <p>Cell: </p>
                    <p>Email: </p>
                    <p>Comms:</p>
                  </div>
                  <div className="flex flex-col flex-grow-1 ml-2 gap-y-1">
                    <FormTextField
                      variableName="firstName"
                      placeholder="First Name"
                      values={values}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required
                      className="h-6 w-60 shadow-none"
                      inputClassName="h-6"
                    />
                    <FormTextField
                      variableName="lastName"
                      placeholder="Last Name"
                      values={values}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required
                      className="h-6 w-60 shadow-none"
                      inputClassName="h-6"
                    />

                    <FormTextField
                      variableName="cellPhoneNumber"
                      placeholder="Cellphone Number"
                      values={values}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required
                      className="h-6 w-60 shadow-none "
                      inputClassName="h-6 border-0"
                    />
                    <FormTextField
                      variableName="email"
                      placeholder="Email"
                      values={values}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required
                      className="h-6 w-60 shadow-none"
                      inputClassName="h-6"
                    />
                    <FormTextField
                      variableName="communicationEmail"
                      placeholder="Email 2"
                      values={values}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      className="h-6 w-60 shadow-none"
                      inputClassName="h-6"
                    />
                  </div>
                </div>
              ) : (
                <div className="flex flex-row rounded-md flex-1 ">
                  <div className="flex flex-col flex-grow-1">
                    <p>Name: </p>
                    <p>Surname: </p>
                    <p>ID: </p>
                    <p>Cell: </p>
                    <p>Email: </p>
                    <p>Comms: </p>
                  </div>
                  <div className="flex flex-col flex-grow-1 ml-2">
                    <FormTextField
                      variableName="firstName"
                      placeholder="First Name"
                      values={values}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required
                      className="h-6 w-60 shadow-none"
                      inputClassName="h-6"
                    />
                    <FormTextField
                      variableName="lastName"
                      placeholder="Last Name"
                      values={values}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required
                      className="h-6 w-60 shadow-none"
                      inputClassName="h-6"
                    />
                    <div className="flex">
                      <FormTextField
                        variableName="identifier"
                        placeholder="ID"
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required
                        className="h-6 w-44 shadow-none"
                        inputClassName="h-6"
                      />
                      <Checkbox
                        checked={values?.identifierType === OffenderIdentifierType.Trn}
                        onChange={onChangeTrn}
                        className="w-6 h-6"
                      />
                      <span>TRN?</span>
                    </div>

                    <FormTextField
                      variableName="cellPhoneNumber"
                      placeholder="Cellphone Number"
                      values={values}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required
                      className="h-6 w-60 shadow-none "
                      inputClassName="h-6 border-0"
                    />
                    <FormTextField
                      variableName="email"
                      placeholder="Email"
                      values={values}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required
                      className="h-6 w-60 shadow-none"
                      inputClassName="h-6"
                    />
                    <FormTextField
                      variableName="communicationEmail"
                      placeholder="Email 2"
                      values={values}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      className="h-6 w-60 shadow-none"
                      inputClassName="h-6"
                    />
                  </div>
                </div>
              )}
            </form>
          );
        }}
      </Formik>
    );
  },
);
