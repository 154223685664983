import React from 'react';
import toast from 'react-hot-toast';
import { useDownloadFile } from '../../../../hooks/global';
import userService from '../../../../services/api/user/user.service';
import { Button } from '../../../atoms';

export const FacebookUserProfilesExportForm: React.FC = () => {
  const { download, loading } = useDownloadFile({
    apiDefinition: () => userService.getUserProfiles(),
    onError: () => toast.error('Could not download report'),
  });

  return (
    <form className="flex flex-col justify-start items-start bg-gray-100 shadow-lg p-4 gap-4">
      <span className="w-full text-xl mb-2">Download Facebook User Profiles</span>
      <Button
        isLoading={loading}
        onClick={download}
        className="bg-tertiary hover:bg-tertiary-dark text-white text-base self-center w-auto"
      >
        Download
      </Button>
    </form>
  );
};
