import { Dispatch, SetStateAction, useState } from 'react';

export const useNoticeNumberSearch = (): {
  noticeNumberSearch: string;
  setNoticeNumberSearch: Dispatch<SetStateAction<string>>;
} => {
  const [noticeNumberSearch, setNoticeNumberSearch] = useState('');

  return {
    noticeNumberSearch,
    setNoticeNumberSearch,
  };
};
