import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, FormContainer } from '../../../components';

const SignUpSuccessScreen: React.FC = () => {
  const history = useHistory();
  const onClickLogin = () => {
    history.replace('/login');
  };

  return (
    <FormContainer className="p-5">
      <p>
        You have successfully signed up. We have sent you a verification email. Please confirm your
        account.
      </p>
      <div className="flex justify-center mt-5">
        <Button
          onClick={onClickLogin}
          className="bg-primary hover:bg-primary-dark text-white text-base self-center"
          size="medium"
        >
          Back to Login
        </Button>
      </div>
    </FormContainer>
  );
};

export default SignUpSuccessScreen;
