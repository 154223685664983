import React, { Dispatch, SetStateAction, useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-hot-toast';
import { Button, SwipeableDrawer } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import { useQueryParam } from 'use-query-params';
import { AddFleetEntitiesProps, BusinessInfo, EditFleetEntitiesProps, Guid } from '../../../types';
import { EditableTable } from '../../table/editableTable/editableTable';
import { EditFleetEntityForm } from '../../forms/edit-fleet-entity-form/edit-fleet-entity-form';
import { AddFleetEntityForm } from '../../forms/add-fleet-entity-form/add-fleet-entity-form';
import { useDeleteFleetEntityMutation } from '../../../hooks/fleet-entities/use-delete-fleet-entity-mutation';
import adminFleetEntitiesService from '../../../services/api/admin-fleet-entities/admin-fleet-entities.service';

const businessInfoCol = [
  {
    Header: 'Entity Name',
    accessor: 'name',
    minWidth: 120,
  },
  {
    Header: 'Address',
    accessor: 'address',
    minWidth: 120,
  },
  {
    Header: 'Vat No',
    accessor: 'vatNumber',
    minWidth: 120,
  },
  {
    Header: 'Company Registration',
    accessor: 'companyRegistrationNumber',
    minWidth: 120,
  },
];

export const BusinessEntities = ({
  activeEntityId,
  setActiveBusinessEntityId,
  businessEntities,
  fleetId,
  businessOwnerId,
}: {
  activeEntityId: Guid;
  setActiveBusinessEntityId: Dispatch<SetStateAction<Guid>>;
  businessEntities: BusinessInfo[];
  fleetId: Guid;
  businessOwnerId: Guid;
}) => {
  const [FleetEntityTable, setFleetEntityTable] = useState({});
  const [businessInfoTableData, setBusinessInfoTableData] = useState<any>();
  const [userId] = useQueryParam<Guid>('Id');
  const queryClient = useQueryClient();
  const toggleTable = (open: boolean, rowData) => {
    setFleetEntityTable({ FleetEntityTable, right: open });
    setBusinessInfoTableData(rowData);
  };

  const getBusiness = (entityId: string) => {
    setActiveBusinessEntityId(entityId);
  };

  const useDeleteEntityMutation = useDeleteFleetEntityMutation(
    (value) => businessEntities.find((entity) => entity.id === value.toString()).links.length > 0,
    () => {
      toast.success('Successfully deleted fleet entity');
      queryClient.invalidateQueries(['AdminAccountProfileData', userId]);
    },
    () => {
      toast.error('Cannot delete a business that still has a BRN or ProxyID attached to it');
    },
  );

  const editFail = () => {
    toast.error('Error edit business entity');
  };

  const EditFleetEntityInitialValues: EditFleetEntitiesProps = {
    entityName: businessInfoTableData?.name,
    address: businessInfoTableData?.address,
    vatNumber: businessInfoTableData?.vatNumber,
    companyRegistration: businessInfoTableData?.companyRegistrationNumber,
  };

  const addFleetEntityInitialValues: AddFleetEntitiesProps = {
    ownerId: businessOwnerId,
    fleetId,
    entityName: '',
    address: '',
    vatNumber: '',
    companyRegistration: '',
  };

  const _editFleetEntity = (formData: EditFleetEntitiesProps) => {
    return adminFleetEntitiesService.editFleetEntities(businessInfoTableData?.id, formData);
  };

  const [stateBusinessInfo, setStateBusinessInfo] = useState({});

  const toggleDrawerBusinessInfo = (open: boolean) => () => {
    setStateBusinessInfo({ stateBusinessInfo, right: open });
  };

  const _handleFormSuccessFleetEntity = () => {
    queryClient.invalidateQueries('AdminAccountProfileData');
  };
  const _handleFormSuccessEditFleetEntity = () => {
    queryClient.invalidateQueries('AdminAccountProfileData');
  };
  const anchorRight = 'right';

  const _addFleetEntity = (formData: AddFleetEntitiesProps) => {
    return adminFleetEntitiesService.addFleetEntities(formData);
  };

  return (
    <>
      <div className="flex flex-col rounded-md h-56 shadow-md">
        <div className="flex bg-secondary rounded-t-md h-16 p-4 flex-row justify-between items-center font-bold text-white">
          <p className="text-white font-sans text-base font-bold">Business Info</p>
          <Button
            className="bg-tertiary text-white hover:bg-tertiary-dark"
            size="small"
            startIcon={<AddCircleOutline />}
            onClick={toggleDrawerBusinessInfo(true)}
          >
            Add
          </Button>
        </div>
        <div className="flex p-1 overflow-auto text-xs">
          {businessEntities.length !== 0 ? (
            <EditableTable
              isViewable={false}
              isDeleteAble={businessEntities.length > 1}
              data={businessEntities}
              columns={businessInfoCol}
              deleteData={useDeleteEntityMutation.mutate}
              getEntityId={getBusiness}
              toggleDrawer={toggleTable}
              isBusinessInfoTable
              activeBusinessEntityId={activeEntityId}
            />
          ) : (
            <div>No business Info was found</div>
          )}
        </div>
      </div>

      <SwipeableDrawer
        anchor={anchorRight}
        open={stateBusinessInfo[anchorRight]}
        onClose={toggleDrawerBusinessInfo(false)}
        onOpen={toggleDrawerBusinessInfo(true)}
        className="w-96"
      >
        <AddFleetEntityForm
          initialValues={addFleetEntityInitialValues}
          submitForm={_addFleetEntity}
          onSuccessWithResponse={(response) => {
            setActiveBusinessEntityId(response);
          }}
          onSuccess={() => {
            _handleFormSuccessFleetEntity();
            setStateBusinessInfo(false);
          }}
          onFailure={editFail}
        />
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor={anchorRight}
        open={FleetEntityTable[anchorRight]}
        onClose={() => toggleTable(false, {})}
        onOpen={() => toggleTable(true, {})}
        className="w-96"
      >
        <EditFleetEntityForm
          initialValues={EditFleetEntityInitialValues}
          submitForm={_editFleetEntity}
          onSuccess={() => {
            _handleFormSuccessEditFleetEntity();
            setFleetEntityTable(false);
          }}
          onFailure={editFail}
        />
      </SwipeableDrawer>
    </>
  );
};
