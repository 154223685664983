import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-hot-toast';
import { Guid } from '../../types';
import adminFinesService from '../../services/api/admin-fines/admin-fines.service';

export const useRemoveFineFromProfile = () => {
  const queryClient = useQueryClient();
  return useMutation<void, string[], Guid[]>(
    (checkedFines) => adminFinesService.removeFinesFromProfile(checkedFines),
    {
      onError: () => {
        toast.error('Error deleting fine.');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['paged-profile-fines']);
      },
    },
  );
};
