import React from 'react';

import { ReportsOrganism } from '../../../components/organisms/reports-organism/reports-organism';

const ReportsScreen: React.FC = () => {
  return (
    <div className="mr-16 ml-16">
      <div className="flex flex-row justify-between">
        <div className="mt-8 flex justify-start text-2xl">Reporting</div>
      </div>
      <div className="border-b border-black my-3 flex justify-start" />
      <div className="flex">
        <ReportsOrganism />
      </div>
    </div>
  );
};

export default ReportsScreen;
