/* eslint-disable @typescript-eslint/no-shadow */
// NOTE: Disabling for this file. In subsequent refactors we'll get rid of this component by creating a dedicated table for each table that uses this one.
import React, { Dispatch, SetStateAction } from 'react';
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import _ from 'lodash';
import { Delete, Edit } from '@material-ui/icons';
import { toast } from 'react-hot-toast';
import { Guid } from '../../../types';
import { classNames } from '../../../helpers/class-names';

type Props = {
  columns: any[];
  data: any[];
  isViewable: boolean;
  isDeleteAble: boolean;
  getEntityId?: (id: string, rowIndex: number) => any;
  deleteData?: (id: string) => any;
  toggleDrawer?: (open: boolean, rowData) => any;
  isBusinessInfoTable?: boolean;
  isFleetEntityLinkTable?: boolean;
  isFamilyMemberTable?: boolean;
  isFineTable?: boolean;
  isClickAble?: boolean;
  checked?: string[];
  setChecked?: Dispatch<SetStateAction<string[]>>;
  toggleCheckAll?: () => void;
  isAllChecked?: boolean;
  activeBusinessEntityId?: Guid;
};

export const EditableTable: React.FC<Props> = ({
  columns,
  data,
  deleteData,
  toggleDrawer,
  isViewable,
  isDeleteAble,
  getEntityId,
  isBusinessInfoTable,
  isFleetEntityLinkTable,
  isFamilyMemberTable,
  isFineTable,
  isClickAble,
  checked,
  setChecked,
  toggleCheckAll,
  isAllChecked,
  activeBusinessEntityId,
}) => {
  const [editableRowIndex, setEditableRowIndex] = React.useState(null);
  const handleChecked = (e, index) => {
    const prev = checked;
    const itemIndex = prev.indexOf(index);
    if (itemIndex !== -1) {
      prev.splice(itemIndex, 1);
    } else {
      prev.push(index);
    }
    setChecked([...prev]);
  };

  const { getTableProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
      editableRowIndex,
      setEditableRowIndex,
      deleteData,
      toggleDrawer,
      isViewable,
      isDeleteAble,
      isBusinessInfoTable,
      isFleetEntityLinkTable,
      getEntityId,
      isFamilyMemberTable,
      isFineTable,
      isClickAble,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (isClickAble) {
        hooks.allColumns.push((columnsProp) => [
          {
            accessor: 'checkBox',
            id: 'checkBox',
            maxWidth: 10,
            Header: () => (
              <div>
                <input type="checkbox" checked={isAllChecked} onChange={toggleCheckAll} />
              </div>
            ),
            Cell: ({ row }) => {
              return !isFineTable ? (
                <input
                  type="checkbox"
                  id={`custom-checkbox-${row?.index}`}
                  checked={checked.includes(row?.original?.id)}
                  onChange={(e) => handleChecked(e, row?.original?.id)}
                />
              ) : (
                <input
                  type="checkbox"
                  id={`custom-checkbox-${row?.index}`}
                  checked={checked.includes(row?.original?.id)}
                  onChange={(e) => {
                    if (row?.original.isPayable) {
                      handleChecked(e, row?.original?.id);
                    } else {
                      toast.error('cannot add fine');
                    }
                  }}
                />
              );
            },
          },
          ...columnsProp,
          {
            accessor: 'edit',
            id: 'edit',
            Header: 'Action',
            minWidth: 20,
            maxWidth: 40,
            Cell: ({
              row,
              setEditableRowIndex,
              editableRowIndex,
              isDeleteAble: _isDeleteAble,
              deleteData: _deleteData,
            }) => (
              <>
                {!isViewable ? (
                  <>
                    <button
                      type="button"
                      className="hover:bg-gray-200 text-xs"
                      onClick={() => getEntityId(row?.original?.id, row?.index)}
                    >
                      View
                    </button>
                    <button
                      type="button"
                      className="hover:bg-gray-200 ml-1"
                      onClick={() => {
                        const currentIndex = row?.index;
                        if (editableRowIndex !== currentIndex) {
                          setEditableRowIndex(currentIndex);
                        } else {
                          setEditableRowIndex(null);
                        }
                      }}
                    >
                      <button
                        type="button"
                        className="hover:bg-gray-200"
                        onClick={() => isBusinessInfoTable && toggleDrawer(true, row?.original)}
                      >
                        <Edit className="text-base" fontSize="medium" />
                      </button>
                    </button>
                    {_isDeleteAble ? (
                      <button
                        type="button"
                        className="hover:bg-gray-200"
                        onClick={() => _deleteData(row?.original?.id)}
                      >
                        <Delete className="text-base" fontSize="medium" />
                      </button>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="hover:bg-gray-200"
                      onClick={() => {
                        const currentIndex = row?.index;
                        if (editableRowIndex !== currentIndex) {
                          setEditableRowIndex(currentIndex);
                        } else {
                          setEditableRowIndex(null);
                        }
                      }}
                    >
                      {isFleetEntityLinkTable && (
                        <button
                          type="button"
                          className="hover:bg-gray-200"
                          onClick={() => toggleDrawer(true, row?.original)}
                        >
                          <Edit className="text-base" fontSize="medium" />
                        </button>
                      )}
                      {isFamilyMemberTable && (
                        <button
                          type="button"
                          className="hover:bg-gray-200"
                          onClick={() => toggleDrawer(true, row?.original)}
                        >
                          <Edit className="text-base" fontSize="medium" />
                        </button>
                      )}
                      {isFineTable && (
                        <button
                          type="button"
                          className="hover:bg-gray-200"
                          onClick={() => toggleDrawer(true, row?.original)}
                        >
                          <Edit className="text-base" fontSize="medium" />
                        </button>
                      )}
                    </button>
                    {_isDeleteAble ? (
                      <button
                        type="button"
                        className="hover:bg-gray-200 ml-3"
                        onClick={() => _deleteData(row?.original?.id)}
                      >
                        <Delete className="text-base" fontSize="medium" />
                      </button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            ),
          },
        ]);
      } else {
        hooks.allColumns.push((columnsProps) => [
          ...columnsProps,
          {
            accessor: 'edit',
            id: 'edit',
            Header: 'Action',
            minWidth: 20,
            maxWidth: 40,
            Cell: ({
              row,
              setEditableRowIndex,
              editableRowIndex,
              isDeleteAble: _isDeleteAble,
              deleteData: _deleteData,
            }) => (
              <>
                {!isViewable ? (
                  <>
                    <button
                      type="button"
                      className="hover:bg-gray-200 text-xs"
                      onClick={() => getEntityId(row?.original?.id, row?.index)}
                    >
                      View
                    </button>
                    <button
                      type="button"
                      className="hover:bg-gray-200 ml-1"
                      onClick={() => {
                        const currentIndex = row?.index;
                        if (editableRowIndex !== currentIndex) {
                          setEditableRowIndex(currentIndex);
                        } else {
                          setEditableRowIndex(null);
                        }
                      }}
                    >
                      <button
                        type="button"
                        className="hover:bg-gray-200"
                        onClick={() => isBusinessInfoTable && toggleDrawer(true, row?.original)}
                      >
                        <Edit className="text-base" fontSize="medium" />
                      </button>
                    </button>
                    {_isDeleteAble ? (
                      <button
                        type="button"
                        className="hover:bg-gray-200"
                        onClick={() => _deleteData(row?.original?.id)}
                      >
                        <Delete className="text-base" fontSize="medium" />
                      </button>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="hover:bg-gray-200"
                      onClick={() => {
                        const currentIndex = row?.index;
                        if (editableRowIndex !== currentIndex) {
                          setEditableRowIndex(currentIndex);
                        } else {
                          setEditableRowIndex(null);
                        }
                      }}
                    >
                      {isFleetEntityLinkTable && (
                        <button
                          type="button"
                          className="hover:bg-gray-200"
                          onClick={() => toggleDrawer(true, row?.original)}
                        >
                          <Edit className="text-base" fontSize="medium" />
                        </button>
                      )}
                      {isFamilyMemberTable && (
                        <button
                          type="button"
                          className="hover:bg-gray-200"
                          onClick={() => toggleDrawer(true, row?.original)}
                        >
                          <Edit className="text-base" fontSize="medium" />
                        </button>
                      )}
                      {isFineTable && (
                        <button
                          type="button"
                          className="hover:bg-gray-200"
                          onClick={() => toggleDrawer(true, row?.original)}
                        >
                          <Edit className="text-base" fontSize="medium" />
                        </button>
                      )}
                    </button>
                    {_isDeleteAble ? (
                      <button
                        type="button"
                        className="hover:bg-gray-200"
                        onClick={() => _deleteData(row?.original?.id)}
                      >
                        <Delete className="text-base" fontSize="medium" />
                      </button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            ),
          },
        ]);
      }
    },
  );
  return (
    <>
      <table className="individualTable" {...getTableProps()}>
        <thead className="individualTableThead">
          {_.map(headerGroups, (headerGroup) => (
            <tr {...headerGroup?.getHeaderGroupProps()}>
              {_.map(headerGroup?.headers, (column) => (
                <th
                  {...column?.getHeaderProps(
                    { style: { minWidth: column.minWidth } },
                    column?.getSortByToggleProps(),
                  )}
                >
                  {column?.render('Header')}
                  <span>{column.isSorted && (column.isSortedDesc ? '<' : '>')}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {_.map(rows, (row) => {
            prepareRow(row);
            return (
              <tr
                className={classNames(
                  'individualTableTr',
                  isBusinessInfoTable && row?.original?.id === activeBusinessEntityId
                    ? 'selectedTr'
                    : '',
                )}
                {...row.getRowProps()}
              >
                {_.map(row?.cells, (cell) => {
                  return (
                    <td className="individualTableTd" {...cell?.getCellProps()}>
                      {cell?.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
