import jwtDecode from 'jwt-decode';

import { JwtPayload } from '../../types/role/role.types';
import { accessTokenOperations } from '../token/token.service';

const getRole = () => {
  const JWTtoken = accessTokenOperations.get();
  const token = JWTtoken ? jwtDecode<JwtPayload>(JWTtoken?.toString()) : {};

  return token.role;
};
const decodedTokenUserId = () => {
  const JWTtoken = accessTokenOperations.get();
  const token = JWTtoken ? jwtDecode<JwtPayload>(JWTtoken?.toString()) : {};

  return token.sub;
};

export default { getRole, decodedTokenUserId };
