import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInfo } from '../../types';
import { RootState } from '../store';

export interface UserState {
  isLoading: boolean;
  id: string;
  user: UserInfo;
}

const initialState: UserState = {
  isLoading: false,
  id: '',
  user: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    communicationEmails: null,
  },
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setLoadingAction: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIdAction: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setUserAction: (state, action: PayloadAction<UserInfo>) => {
      state.user = action.payload;
    },
  },
});

export const { setLoadingAction, setIdAction, setUserAction } = userSlice.actions;
export const userSelector = (state: RootState) => state.userReducer;

export default userSlice.reducer;
