import React, { ChangeEventHandler } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { KeyValuePair } from '../../../types/common.types';

type SelectProps<T, U> = {
  register?: UseFormRegister<T>;
  options: KeyValuePair<U>[];
  variableName?: string;
  className?: string;
  onChange?: ChangeEventHandler<T> | undefined;
};

export const InternalSelect: React.FC<SelectProps<any, any>> = ({
  register,
  options,
  variableName,
  ...rest
}) => {
  return (
    <select {...(register ? register(variableName) : null)} {...rest}>
      {options.map((value) => (
        <option key={value.key} value={value.key}>
          {value.value}
        </option>
      ))}
    </select>
  );
};
