import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useOnboardStatus } from '../../../react-queries';

const LandingScreen: React.FC = () => {
  const onboardStatus = useOnboardStatus();
  const history = useHistory();

  useEffect(() => {
    if (!onboardStatus.data) {
      onboardStatus.refetch().then((onboardStatusresponse) => {
        if (onboardStatusresponse.data.individual) {
          history.replace(`/individual${history.location.search}`);
        } else if (onboardStatusresponse.data.fleet) {
          history.replace(`/business${history.location.search}`);
        } else {
          history.replace('/onboard');
        }
      });
    }
  }, []);

  return <></>;
};

export default LandingScreen;
