import React, { ChangeEvent, useState } from 'react';

import 'react-dropzone-uploader/dist/styles.css';
import { toast, Toaster } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { FileUpload, TailwindButton } from '../../../components';
import whatsappWhitelistProcessService from '../../../services/api/whatsapp-whitelist-process/whatsapp-whitelist-process.service';
import { ValidationHelper } from '../../../helpers';

const WhatsAppWhitelistBulkUploadScreen: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [email, setEmail] = useState<string | null>(null);

  const onChangeDropZoneFile = (inputFile: File) => {
    setFile(inputFile);
  };

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    if (ValidationHelper.emailRegexValidation.test(event.target.value)) {
      setEmail(event.target.value);
    } else {
      setEmail(null);
    }
  };

  const useFileUploadMutation = useMutation(
    'uploadWhatsAppWhitelistBulk',
    () => {
      return whatsappWhitelistProcessService.uploadFile(file, email);
    },
    {
      onSuccess: () => {
        toast.success('Successfully uploaded file');
        setFile(null);
      },
      onError: () => {
        toast.error('An Error occurred while uploading file');
      },
    },
  );

  return (
    <>
      <Toaster />
      <div className="flex items-center justify-center  flex-col">
        <div className="flex justify-center items-center w-full">
          <FileUpload onChange={onChangeDropZoneFile} file={file} type="xlsx" />
        </div>
        <div className="m-3 flex gap-2 items-center">
          <span className="text-gray-400">Email: </span>
          <input
            id="email"
            onChange={onChangeEmail}
            type="text"
            className="border-solid border-2"
          />
          <TailwindButton
            className="bg-tertiary hover:bg-tertiary-dark focus:bg-tertiary-dark self-center"
            isDisabled={!file || !email}
            isLoading={useFileUploadMutation.isLoading}
            onClick={() => useFileUploadMutation.mutateAsync()}
            type="submit"
          >
            Upload {file?.name ?? ''}
          </TailwindButton>
        </div>
      </div>
    </>
  );
};

export default WhatsAppWhitelistBulkUploadScreen;
