import { AxiosResponse } from 'axios';

import authNetworkService from '../auth-network/auth-network.service';
import blackListUrls from './black-list.urls';
import {
  AddBlackListProps,
  BlackListDto,
  BlackListFilterOptions,
  PaginatedList,
  ServiceResult,
} from '../../../types';

const get = async (filter: BlackListFilterOptions): Promise<PaginatedList<BlackListDto>> => {
  const url = blackListUrls.getPaginatedBlackListUrl(filter);

  return authNetworkService
    .get(url)
    .then(
      (apiResponse: AxiosResponse<ServiceResult<PaginatedList<BlackListDto>>>) =>
        apiResponse.data.data,
    );
};

const add = async (formData: AddBlackListProps) => {
  const url = blackListUrls.getBaseUrl();

  return authNetworkService.post(url, formData);
};

const removeBlackList = async (userId: string) => {
  const url = blackListUrls.deleteUrl(userId);

  return authNetworkService.delete(url);
};

export default {
  get,
  add,
  removeBlackList,
};
