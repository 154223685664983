import React, { useState } from 'react';

import { Formik } from 'formik';
import { FormContainer } from '../../../components';
import { SignUpProcess } from '../../../components/forms/sign-up/sign-up-process';
import {
  signUpValidation,
  signUpValidationBusiness,
  signUpValidationIndividual,
} from '../../../validators';
import { ProfileTypeEnum } from '../../../enums/profile-type.enum';
import { OffenderIdentifierType } from '../../../enums';

const SignUpScreen: React.FC = () => {
  const [signUpType, setSignUpType] = useState(ProfileTypeEnum.both);

  return (
    <FormContainer>
      <Formik
        validationSchema={
          signUpType === ProfileTypeEnum.both
            ? signUpValidation
            : signUpType === ProfileTypeEnum.individual
            ? signUpValidationIndividual
            : signUpValidationBusiness
        }
        initialValues={{
          account: {
            firstName: '',
            lastName: '',
            email: '',
            confirmEmail: '',
            code: '27',
            cellphone: '',
            password: '',
            confirmPassword: '',
            cellphoneNumber: '',
            termsChecked: true,
            whatsAppOptedIn: false,
          },
          individual: {
            idType: 0,
            idNumber: '',
            identifierType: OffenderIdentifierType.SaIdNumber,
            confirmIdNumber: '',
            familyMembers: [],
          },
          business: {
            businessEntities: [
              {
                entityName: '',
                address: '',
                vatNo: '',
                companyRegistration: '',
                links: [{ value: '', identifierType: OffenderIdentifierType.Brn }],
              },
            ],
          },
        }}
        onSubmit={() => null}
      >
        <SignUpProcess setSignUpType={setSignUpType} />
      </Formik>
    </FormContainer>
  );
};

export default SignUpScreen;
