import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { Guid } from '../../types';
import adminFleetEntitiesService from '../../services/api/admin-fleet-entities/admin-fleet-entities.service';

export const useDeleteFleetEntityMutation = (
  preCheckCondition?: (entityId: Guid) => boolean,
  onSuccess?: () => void,
  onError?: () => void,
) =>
  useMutation(
    'deleteFleetEntity',
    (entityId: Guid) => {
      if (preCheckCondition(entityId)) {
        return Promise.reject();
      }
      return adminFleetEntitiesService.deleteFleetEntity(entityId);
    },
    {
      onSuccess: () => {
        toast.success('Successfully deleted fleet entity');
        onSuccess();
      },
      onError: () => {
        if (onError) {
          onError();
        } else {
          toast.error('Error deleting fleet entity');
        }
      },
    },
  );
