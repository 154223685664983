import React from 'react';
import { UnpaidTableRow } from '..';
import { FineDetailDto } from '../../../types';

type Props = {
  onCheck?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  fine: Partial<FineDetailDto>;
  checked?: boolean;
  showCheckBox?: boolean;
  hasImage?: boolean;
  excludeImage?: boolean;
};

export const UnpaidFineSummary: React.FC<Props> = ({
  onCheck,
  showCheckBox = true,
  className,
  checked,
  fine,
  hasImage,
  excludeImage = false,
}) => {
  return (
    <UnpaidTableRow
      key={fine.id}
      onCheck={onCheck}
      checked={checked}
      showCheckBoxes={showCheckBox}
      className={`my-2 ${className}`}
      aartoFee={fine.aartoFee}
      amountPaid={fine.amountPaid}
      area={fine.area}
      discount={fine.discount}
      enforcementOrder={fine.enforcementOrder}
      fineAmount={fine.fineAmount}
      offenceDate={fine.date.toLocaleString()}
      reason={fine.reason}
      regNo={fine.vehicleRegistration}
      serviceFee={fine.serviceFee}
      ticketNumber={fine.noticeNumber}
      total={fine.total}
      totalOutstanding={fine.total}
      demeritPoints={fine.demeritPoints}
      hasImage={hasImage}
      evidenceTokens={fine.evidence}
      isNag={fine.isNag}
      isPayable={fine.isPayable}
      excludeImage={excludeImage}
    />
  );
};
