import React from 'react';
import { Button as Btn, Card } from '@material-ui/core';

type Props = {
  onClick: () => void;
  className?: string;
  icon: React.ReactNode;
  text: string;
};

export const IconCard: React.FC<Props> = ({ onClick, className, icon, text = 'placeholder' }) => {
  return (
    <Btn className={`bg-primary p-2 gap-4 justify-start flex-grow ${className}`} onClick={onClick}>
      <Card className="bg-opacity-20 bg-gray-800 p-2">{icon}</Card>
      <p className="text-white mx-2">{text}</p>
    </Btn>
  );
};
