import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { InternalCheckbox } from '../internal-checkbox/internal-checkbox';

type InternalHookFormCheckboxProps = {
  variableName: string;
  control: Control;
  key?: string;
  className?: string;
};

export const InternalHookFormCheckbox: React.FC<InternalHookFormCheckboxProps> = ({
  variableName,
  control,
  key,
  className,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={variableName}
      render={({ field: { value, onChange, onBlur } }) => (
        <InternalCheckbox
          className={className}
          value={value}
          key={key}
          onBlur={onBlur}
          onChange={onChange}
        />
      )}
      {...rest}
    />
  );
};
