import React, { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Link, useHistory } from 'react-router-dom';
import _ from 'lodash';

import './adminTable.css';
import '../individualTable/individualTable.css';
import { CircularProgress } from '@material-ui/core';
import { Button } from '../../atoms';

type LinkToAccounts = {
  action: (param: any) => void;
};

type Props = {
  tableData: any[];
  tableColumns: any[];
  path: string;
  isViewable: boolean;
  isLoading?: boolean;
  isDeletable?: boolean;
  isEditable?: boolean;
  onDelete?: (params: any) => void;
  onEdit?: (params: any) => void;
  linkToAccounts?: LinkToAccounts;
};

export const BasicTable: React.FC<Props> = ({
  tableData,
  tableColumns,
  path,
  isViewable,
  isLoading = false,
  isDeletable,
  isEditable,
  onDelete,
  onEdit,
  linkToAccounts,
}) => {
  const history = useHistory();

  const _navigateToAdmin = (index: string) => (): void => {
    history.push(`${path}?Id=${index}`);
  };
  const columns = useMemo<any[]>(() => tableColumns, [tableColumns]);
  const data = useMemo<any[]>(() => tableData, [tableData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {_.map(headerGroups, (headerGroup) => (
            <tr {...headerGroup?.getHeaderGroupProps()}>
              {_.map(headerGroup?.headers, (column) => (
                <th {...column?.getHeaderProps(column?.getSortByToggleProps())}>
                  {column?.render('Header')}
                  <span>{column.isSorted ? (column.isSortedDesc ? '<' : '>') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {!isLoading && (
          <tbody {...getTableBodyProps()}>
            {_.map(rows, (row) => {
              prepareRow(row);
              return (
                <tr className="individualTableTr text-sm h-10" {...row?.getRowProps()}>
                  {_.map(row?.cells, (cell) => {
                    return <td {...cell?.getCellProps()}>{cell?.render('Cell')}</td>;
                  })}
                  {isViewable && (
                    <td>
                      <Button
                        className="bg-transparent border-blue-700 h-6 border-opacity-60 hover:bg-transparent text-blue-700 text-opacity-60"
                        onClick={_navigateToAdmin(row?.original?.id)}
                        type="outlined"
                      >
                        View
                      </Button>
                    </td>
                  )}
                  {linkToAccounts && (
                    <td>
                      <Link
                        onClick={() => linkToAccounts.action(row?.original)}
                        to={{
                          pathname: '/admin/users',
                          search: `?offenderIdentifier=${
                            row?.original?.offenderIdentifier ?? row?.original?.userId
                          }`,
                        }}
                      >
                        <Button
                          className="bg-transparent border-blue-700 h-6 border-opacity-60 hover:bg-transparent text-blue-700 text-opacity-60"
                          type="outlined"
                          onClick={() => null}
                        >
                          Account
                        </Button>
                      </Link>
                    </td>
                  )}
                  {isEditable && (
                    <td>
                      {row?.original.isEditable ? (
                        <Button
                          className="bg-transparent border-blue-700 h-6 border-opacity-60 hover:bg-transparent text-blue-700 text-opacity-60"
                          onClick={() => {
                            onEdit(row?.original);
                          }}
                          type="outlined"
                        >
                          Edit
                        </Button>
                      ) : (
                        <></>
                      )}
                    </td>
                  )}
                  {isDeletable && (
                    <td>
                      <Button
                        className="bg-transparent border-red-700 h-6 border-opacity-60 hover:bg-transparent text-red-700 text-opacity-60"
                        onClick={() => {
                          // eslint-disable-next-line no-alert
                          if (window.confirm('Are you sure you wish to delete this item?')) {
                            onDelete({ id: row?.original?.id });
                          }
                        }}
                        type="outlined"
                      >
                        Delete
                      </Button>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        )}
        {!isLoading && rows.length === 0 && (
          <tbody className="px-2 text-sm">
            <tr>
              <td>No items found</td>
            </tr>
          </tbody>
        )}
      </table>
      {isLoading && (
        <div className="self-center">
          <CircularProgress />
        </div>
      )}
    </>
  );
};
