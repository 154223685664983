import config from '../../../config';
import { Guid } from '../../../types';

const baseUrl = `${config.apiUrl()}/Invoices`;
const baseReportsUrl = `${config.apiUrl()}/Reports/Invoices`;

export default {
  getInvoiceUrl: (id: Guid): string => `${baseUrl}/${id}`,
  getInvoicePaymentLinkUrl: (id: Guid): string => `${baseUrl}/${id}/create-payment-link`,
  getInvoicePrintTemplateUrl: (id: Guid): string =>
    `${baseUrl}/${id}/create-invoice-print-template`,
  getInvoiceTotalsContentUrl: (): string => `${baseReportsUrl}/Totals`,
};
