import { useQuery, UseQueryResult } from 'react-query';
import { adminInvoiceService } from '../../services';

import { getInvoices } from '../../services/api/admin-invoices/admin-invoices.service';
import { PaginatedList, Invoices, InvoiceDetailDto, InvoiceFilterOptions } from '../../types';

export const useAdminInvoiceData = (
  filter: InvoiceFilterOptions,
): UseQueryResult<PaginatedList<Invoices>, unknown> => {
  return useQuery(['invoiceData', filter], () => getInvoices(filter));
};

export const useIndividualInvoiceData = (
  invoiceId: string,
): UseQueryResult<InvoiceDetailDto, unknown> => {
  return useQuery(['IndividualInvoiceData', invoiceId], () =>
    adminInvoiceService.getIndividualInvoice(invoiceId),
  );
};
