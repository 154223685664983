import { Card, CardContent } from '@material-ui/core';
import React from 'react';

import moment from 'moment';
import { useImmer } from 'use-immer';
import { PaginationButtons, SearchField } from '../../../components';
import { BasicTable } from '../../../components/table/adminTable/basicTable';
import { useAdminInvoiceData } from '../../../react-queries/admin-invoices-data/admin-invoices-data.queries';
import { InvoiceFilterOptions } from '../../../types';

const InvoicesScreen: React.FC = () => {
  const [filterInvoice, setFilterInvoice] = useImmer<InvoiceFilterOptions>({
    referenceNumber: '',
    offenderIdentifierValue: '',
    recipientName: '',
    vatNumber: '',
    address: '',
    companyRegistrationNumber: '',
    pageNum: 1,
    pageEntries: 10,
  });
  const invoiceData = useAdminInvoiceData(filterInvoice);

  const columns = [
    {
      Header: 'Ref No.',
      accessor: 'referenceNumber',
    },
    {
      Header: 'Invoice to',
      accessor: 'recipientName',
    },
    {
      Header: 'Date Created',
      accessor: (row) => moment(row.createdDate).format('DD-MM-YYYY'),
    },
    {
      Header: 'ID No.',
      accessor: 'offenderIdentifier',
    },
    {
      Header: 'Amount Paid',
      accessor: 'amountPaid',
    },
    {
      Header: 'Amount Due',
      accessor: 'amountDue',
    },
  ];

  const setPage = (pageNumber: number) => {
    setFilterInvoice({
      ...filterInvoice,
      pageNum: pageNumber,
    });
  };

  const invoiceTableCard = () => {
    return (
      <div className="flex flex-col flex-grow mx-8 mt-2">
        <Card className="flex flex-grow shadow-2xl">
          <CardContent className="flex flex-col flex-grow">
            {!invoiceData.isLoading && (
              <BasicTable
                tableData={invoiceData.data.items}
                tableColumns={columns}
                path="./individual/invoice"
                key={filterInvoice.referenceNumber}
                isViewable
              />
            )}
          </CardContent>
        </Card>
      </div>
    );
  };
  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="mt-2 flex justify-start mx-8 text-xl">Invoices</div>
      </div>
      <div className="border-b border-black mt-2 flex justify-start mx-8" />
      <div className="flex justify-start mx-8 mt-2 space-x-3">
        <div>
          <p className="text-sm font-bold">Reference:</p>
          <SearchField
            placeholder="Reference Number"
            value={filterInvoice.referenceNumber}
            isLoading={invoiceData.isLoading}
            onChange={(value) => {
              setFilterInvoice({
                ...filterInvoice,
                referenceNumber: value,
                pageNum: 1,
              });
            }}
          />
        </div>
        <div>
          <p className="text-sm font-bold">Identifier:</p>
          <SearchField
            placeholder="Identifier"
            value={filterInvoice.offenderIdentifierValue}
            isLoading={invoiceData.isLoading}
            onChange={(value) => {
              setFilterInvoice({
                ...filterInvoice,
                offenderIdentifierValue: value,
                pageNum: 1,
              });
            }}
          />
        </div>
        <div>
          <p className="text-sm font-bold">Recipient Name:</p>
          <SearchField
            placeholder="Recipient Name"
            value={filterInvoice.recipientName}
            isLoading={invoiceData.isLoading}
            onChange={(value) => {
              setFilterInvoice({
                ...filterInvoice,
                recipientName: value,
                pageNum: 1,
              });
            }}
          />
        </div>
        <div>
          <p className="text-sm font-bold">Vat Number:</p>
          <SearchField
            placeholder="Vat Number"
            value={filterInvoice.vatNumber}
            isLoading={invoiceData.isLoading}
            onChange={(value) => {
              setFilterInvoice({
                ...filterInvoice,
                vatNumber: value,
                pageNum: 1,
              });
            }}
          />
        </div>
        <div>
          <p className="text-sm font-bold">Address:</p>
          <SearchField
            placeholder="Address"
            value={filterInvoice.address}
            isLoading={invoiceData.isLoading}
            onChange={(value) => {
              setFilterInvoice({
                ...filterInvoice,
                address: value,
                pageNum: 1,
              });
            }}
          />
        </div>
        <div>
          <p className="text-sm font-bold">Company Reg Num:</p>
          <SearchField
            placeholder="Company Registration Number"
            value={filterInvoice.referenceNumber}
            isLoading={invoiceData.isLoading}
            onChange={(value) => {
              setFilterInvoice({
                ...filterInvoice,
                companyRegistrationNumber: value,
                pageNum: 1,
              });
            }}
          />
        </div>
      </div>
      {invoiceTableCard()}
      {!invoiceData.isLoading && (
        <PaginationButtons
          page={filterInvoice.pageNum}
          setPage={setPage}
          pageIndex={invoiceData.data.pageIndex}
          totalPages={invoiceData.data.totalPages}
        />
      )}
    </>
  );
};
export default InvoicesScreen;
