import config from '../../../config';

const baseUrl = `${config.apiUrl()}/Referrals`;
const baseReportsUrl = `${config.apiUrl()}/Reports/Referrals`;

export default {
  referralsUrl: (): string => `${baseUrl}`,
  referralsBulkUrl: (): string => `${baseUrl}/Bulk`,
  cancelReferralsUrl: (): string => `${baseUrl}/cancel`,
  backofficeGetReferralsContentUrl: (): string => `${baseReportsUrl}/SignUpTotals`,
};
