import {
  FineSyncConfiguration,
  FineSyncConfigurationUpdateRequest,
  ServiceResult,
} from '../../../types';
import authNetworkService from '../auth-network/auth-network.service';
import fineSyncConfigurationUrls from './fine-sync-configuration.urls';

const get = async (): Promise<FineSyncConfiguration> => {
  const url = fineSyncConfigurationUrls.get();

  const apiResponse = await authNetworkService.get<ServiceResult<FineSyncConfiguration>>(url);
  return apiResponse.data.data;
};

const update = (configuration: FineSyncConfigurationUpdateRequest) => {
  const url = fineSyncConfigurationUrls.update();

  return authNetworkService.put(url, configuration);
};

export default {
  get,
  update,
};
