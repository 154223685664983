import React, { ReactNode } from 'react';

type Props = {
  keyString: string;
  children: ReactNode;
  colName?: string;
};

export const KeyValueRow: React.FC<Props> = ({ keyString, children, colName }) => {
  return (
    <div className="flex-row justify-between grid-cols-12 grid gap-1">
      <div className={colName === 'other' ? 'col-span-9' : ' col-span-5'}>
        <p className="font-medium text-sm">{keyString}</p>
      </div>
      <div className={colName === 'other' ? 'col-span-3' : 'col-span-7'}>
        <div className="font-light text-sm break-words">{children}</div>
      </div>
    </div>
  );
};
