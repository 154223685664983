import { Button, Card, CardContent, SwipeableDrawer } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { atomWithHash } from 'jotai/utils';
import { useImmerAtom } from 'jotai/immer';
import { Draft } from 'immer';
import { useQueryParam } from 'use-query-params';
import { PaginationButtons, SearchField } from '../../../components';
import { AddPortalUserForm } from '../../../components/forms/add-portal-user-form/add-portal-user-form';

import { BasicTable } from '../../../components/table/adminTable/basicTable';
import { useAdminUsersData } from '../../../react-queries/admin-users-data/admin-users-data.queries';
import { SignUpPortalUserProps } from '../../../types';
import adminUsersService from '../../../services/api/admin-users/admin-users.service';

const columns = [
  {
    Header: 'Name',
    accessor: 'firstName',
  },
  {
    Header: 'Surname',
    accessor: 'lastName',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'PhoneNumber',
    accessor: 'phoneNumber',
  },
];

export type QueryParamSearch = {
  fullName: string;
  entityName: string;
  mobileNumber: string;
  email: string;
  businessOffenderIdentifier: string;
  individualOffenderIdentifier: string;
  pageNum: number;
  communicationEmail: string;
};

export const searchParamsAtom = atomWithHash<QueryParamSearch>('searchParams', {
  fullName: '',
  entityName: '',
  mobileNumber: '',
  email: '',
  businessOffenderIdentifier: '',
  individualOffenderIdentifier: new URLSearchParams(window.location.search).get(
    'offenderIdentifier',
  ),
  pageNum: 1,
  communicationEmail: '',
});

const UsersScreen: React.FC = () => {
  const anchorRight = 'right';
  const queryClient = useQueryClient();
  const [offenderIdentifier] = useQueryParam<string>('offenderIdentifier');

  const [state, setState] = useState({});
  const toggleDrawer = (open: boolean) => () => {
    setState({ state, right: open });
  };
  const [searchParams, setSearchParams] = useImmerAtom(searchParamsAtom);
  const setPage = useCallback(
    (pageNum) => {
      setSearchParams((c: Draft<QueryParamSearch>) => {
        c.pageNum = pageNum;
      });
    },
    [searchParams.pageNum],
  );
  useEffect(() => {
    if (offenderIdentifier) {
      setSearchParams((draft: Draft<QueryParamSearch>) => {
        draft.individualOffenderIdentifier = offenderIdentifier;
      });
    }
  }, [offenderIdentifier]);

  useEffect(() => {
    setPage(1);
  }, [
    searchParams.fullName,
    searchParams.entityName,
    searchParams.mobileNumber,
    searchParams.email,
    searchParams.businessOffenderIdentifier,
    searchParams.individualOffenderIdentifier,
  ]);

  const history = useHistory();

  const _handleSuccess = () => {
    queryClient.invalidateQueries('userData');
  };
  const _addConsultantFail = () => null;

  const signUpConsultantInitialValues: SignUpPortalUserProps = {
    firstName: '',
    lastName: '',
    email: '',
    cellPhoneNumber: '',
    role: 'PortalUser',
  };

  const _signUpPortalUserSubmission = (formData: SignUpPortalUserProps) => {
    return adminUsersService.signUpConsultant(formData);
  };
  const pageEntries = 10;
  const usersQuery = useAdminUsersData(
    searchParams.pageNum,
    pageEntries,
    searchParams.fullName,
    searchParams.entityName,
    searchParams.mobileNumber,
    searchParams.email,
    searchParams.businessOffenderIdentifier,
    searchParams.individualOffenderIdentifier,
    searchParams.communicationEmail,
  );

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="mt-2 flex justify-start mx-8 text-xl">Accounts</div>
        <Button
          className="mt-2 flex bg-tertiary text-white hover:bg-tertiary-dark mr-8"
          size="small"
          startIcon={<AddCircleOutline />}
          onClick={toggleDrawer(true)}
        >
          Add New
        </Button>
      </div>
      <div className="border-b border-black mt-2 flex justify-start mx-8" />
      <div className="flex justify-start mx-8 mt-2 space-x-3">
        <div>
          <p className="text-sm font-bold">Name</p>
          <SearchField
            placeholder="Name"
            value={searchParams.fullName}
            onChange={(value) => {
              setSearchParams((c: Draft<QueryParamSearch>) => {
                c.fullName = value;
              });
            }}
          />
        </div>
        <div>
          <p className="text-sm font-bold">Email</p>
          <SearchField
            placeholder="Email"
            value={searchParams.email}
            onChange={(value) => {
              setSearchParams((c: Draft<QueryParamSearch>) => {
                c.email = value;
              });
            }}
          />
        </div>
        <div>
          <p className="text-sm font-bold">Business Entity Name</p>
          <SearchField
            placeholder="Entity Name"
            value={searchParams.entityName}
            onChange={(value) => {
              setSearchParams((c: Draft<QueryParamSearch>) => {
                c.entityName = value;
              });
            }}
          />
        </div>
        <div>
          <p className="text-sm font-bold">Mobile Number</p>
          <SearchField
            placeholder="Mobile Number"
            value={searchParams.mobileNumber}
            onChange={(value) => {
              setSearchParams((c: Draft<QueryParamSearch>) => {
                c.mobileNumber = value;
              });
            }}
          />
        </div>
        <div>
          <p className="text-sm font-bold">BRN/ProxyId</p>
          <SearchField
            placeholder="BRN/ProxyId"
            value={searchParams.businessOffenderIdentifier}
            onChange={(value) => {
              setSearchParams((c: Draft<QueryParamSearch>) => {
                c.businessOffenderIdentifier = value;
              });
            }}
          />
        </div>
        <div>
          <p className="text-sm font-bold">ID/TRN</p>
          <SearchField
            placeholder="ID/TRN"
            value={searchParams.individualOffenderIdentifier}
            onChange={(value) => {
              setSearchParams((c: Draft<QueryParamSearch>) => {
                c.individualOffenderIdentifier = value;
              });
            }}
          />
        </div>
        <div>
          <p className="text-sm font-bold">Comms Email</p>
          <SearchField
            placeholder="Communication Email"
            value={searchParams.communicationEmail}
            onChange={(value) => {
              setSearchParams((c: Draft<QueryParamSearch>) => {
                c.communicationEmail = value;
              });
            }}
          />
        </div>
      </div>
      <div className="flex flex-col flex-grow mx-8 mt-2">
        <Card className="flex flex-grow shadow-2xl">
          <CardContent className="flex flex-col flex-grow">
            <BasicTable
              tableData={usersQuery.data?.items ?? []}
              tableColumns={columns}
              path="/admin/individual/user"
              isLoading={usersQuery.isFetching}
              isViewable
            />
          </CardContent>
        </Card>
      </div>
      {!usersQuery.isLoading && (
        <>
          <PaginationButtons
            page={searchParams.pageNum}
            setPage={setPage}
            pageIndex={usersQuery.data.pageIndex}
            totalPages={usersQuery.data.totalPages}
          />
          <SwipeableDrawer
            anchor={anchorRight}
            open={state[anchorRight] !== undefined}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            className="w-96"
          >
            <AddPortalUserForm
              initialValues={signUpConsultantInitialValues}
              submitForm={_signUpPortalUserSubmission}
              onSuccess={() => null}
              onSuccessWithResponse={(userId) => {
                _handleSuccess();
                setState(false);
                usersQuery.refetch();
                history.push(`/admin/individual/user?Id=${userId}`);
              }}
              onFailure={_addConsultantFail}
            />
          </SwipeableDrawer>
        </>
      )}
    </>
  );
};
export default UsersScreen;
