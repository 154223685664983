import authNetworkService from '../auth-network/auth-network.service';
import paymentPartnerUrls from './payment-partners.urls';
import { PaymentPartnerDto, ServiceResult } from '../../../types';

const get = async (): Promise<PaymentPartnerDto[]> => {
  const url = paymentPartnerUrls.getBaseUrl();
  const response = await authNetworkService
    .get<ServiceResult<PaymentPartnerDto[]>>(url)
    .catch(Promise.reject);
  return response.data.data;
};

export default {
  get,
};
