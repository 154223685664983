import React, { SyntheticEvent, useState } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { Card, FormHelperText, IconButton } from '@material-ui/core';

import { Edit, HighlightOff } from '@material-ui/icons';
import { Button, FormTextField, OffenderTypeTextField } from '../../atoms';
import { SignUpV2FormValues } from '../sign-up/sign-up-process';
import { OffenderIdentifierType, OffenderIdentifierTypeDescription } from '../../../enums';
import { FamilyMemberProps } from '../../../types';

export type OnboardIndividualV2Props = {
  submitForm: (formData: any) => any;
};

export const OnboardIndividualV2: React.FC<OnboardIndividualV2Props> = ({ submitForm }) => {
  const [generalError] = useState(null);
  const [hasFriendsAndFamily, toggleFriendsAndFamily] = useState(false);
  const names = ['firstName', 'lastName'];
  const placeholders = ['First Name', 'Last Name'];
  const icons = [
    <img src="assets/icons/user-icon.png" alt="userFirst" />,
    <img src="assets/icons/user-icon.png" alt="userLast" />,
    <img src="assets/icons/id-card-icon.png" alt="idNumber" />,
  ];
  const individualProfileTypes = [OffenderIdentifierType.SaIdNumber, OffenderIdentifierType.Trn];

  const {
    values,
    setFieldValue,
    errors,
    handleChange,
    handleBlur,
    touched,
    handleReset,
    isSubmitting,
  } = useFormikContext<SignUpV2FormValues>();

  const _addMember = () => {
    const familyMember =
      values.individual.familyMembers[values.individual.familyMembers.length - 1];
    const isFamilyMembersValid = Object.keys(errors.individual?.familyMembers ?? []).length === 0;
    if (
      !_.isEmpty(familyMember?.firstName) &&
      !_.isEmpty(familyMember?.lastName) &&
      !_.isEmpty(familyMember?.idNumber) &&
      isFamilyMembersValid
    ) {
      setFieldValue('individual.familyMembers', [
        ...values.individual.familyMembers,
        {
          firstName: '',
          lastName: '',
          idNumber: '',
          identifierType: OffenderIdentifierType.SaIdNumber,
        },
      ]);
    }
  };

  const _addFriendsAndFamily = () => {
    setFieldValue('individual.familyMembers', [
      ...values.individual.familyMembers,
      {
        firstName: '',
        lastName: '',
        idNumber: '',
        identifierType: OffenderIdentifierType.SaIdNumber,
      } as FamilyMemberProps,
    ]);
    toggleFriendsAndFamily(true);
  };

  const _editMember = (index: number) => () => {
    const familyMembers = _.filter(_.cloneDeep(values.individual.familyMembers), (member) => {
      return !(
        _.isEmpty(member?.firstName) &&
        _.isEmpty(member?.lastName) &&
        _.isEmpty(member?.lastName)
      );
    });

    familyMembers.push(familyMembers.splice(index, 1)[0]);

    setFieldValue('individual.familyMembers', familyMembers);
    toggleFriendsAndFamily(true);
  };

  const _removeMember = (index: number) => () => {
    const myFamilyMembers = _.cloneDeep(values.individual.familyMembers);
    myFamilyMembers.splice(index, 1);
    setFieldValue('individual.familyMembers', myFamilyMembers);
  };

  const membersCount = values.individual.familyMembers.length - 1;
  const _renderFamilyMembers = () => (
    <div>
      {_.map(names, (name, itemIndex) => {
        return (
          <FormTextField
            key={`familyMember-${itemIndex}`}
            variableName={`individual.familyMembers.${membersCount}.${name}`}
            placeholder={placeholders[itemIndex]}
            onChange={handleChange}
            onBlur={handleBlur}
            values={values}
            errors={errors}
            touched={touched}
            icon={icons[itemIndex]}
            className="w-full"
          />
        );
      })}
      <div className="flex w-full">
        <OffenderTypeTextField
          variableType={`individual.familyMembers[${membersCount}].identifierType`}
          variableTypes={individualProfileTypes}
          variableName={`individual.familyMembers.${membersCount}.idNumber`}
          values={values}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched}
          errors={errors}
          required
          className="mb-2 w-full"
          containerClassName="flex w-full"
          placeholder={
            OffenderIdentifierTypeDescription[
              values.individual.familyMembers[membersCount].identifierType
            ]
          }
        />
      </div>
    </div>
  );

  const _renderSubmittedFamilyMembers = () =>
    _.map(values.individual.familyMembers, (familyMember, index) => {
      if (index < values.individual.familyMembers.length - 1) {
        return (
          <Card className="flex mt-1.5">
            <div className="p-2">
              <img src="assets/icons/id-card-icon.png" alt="companyReg" />
            </div>
            <div className="text-left flex flex-col justify-center">
              <p className="text-xs font-bold text-text-grey">
                {familyMember.firstName} {familyMember.lastName}
              </p>
              <p className="text-xs font-thin text-text-grey">{`${
                OffenderIdentifierTypeDescription[familyMember.identifierType]
              }: ${familyMember.idNumber}`}</p>
            </div>
            <div className="flex flex-1" />
            <IconButton onClick={_editMember(index)} className="m-1">
              <Edit className="h-4 w-4" />
            </IconButton>
            <IconButton onClick={_removeMember(index)} className="m-1">
              <HighlightOff className="h-4 w-4" />
            </IconButton>
          </Card>
        );
      }
      return <></>;
    });

  const _renderFriendsAndFamilyMembersForm = () => (
    <>
      <div className="overflow-y-auto">
        <div className="grid gap-2 mt-2">
          <OffenderTypeTextField
            variableType="individual.identifierType"
            variableTypes={individualProfileTypes}
            variableName="individual.idNumber"
            values={values}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched}
            errors={errors}
            required
            className="mb-2 w-full"
            containerClassName="flex w-full"
            placeholder={OffenderIdentifierTypeDescription[values.individual.identifierType]}
          />
          <FormTextField
            inputClassName="rounded-l-none border-l-none"
            placeholder={`Confirm ${
              OffenderIdentifierTypeDescription[values.individual.identifierType]
            }`}
            values={values}
            icon={<img src="assets/icons/id-card-icon.png" alt="confirm IdNumber" />}
            variableName="individual.confirmIdNumber"
            errors={errors}
            touched={touched}
            onChange={handleChange}
            onBlur={handleBlur}
            className="w-full"
          />
          <div className="flex justify-center ">
            <p className="w-full text-2xl text-text-grey text-center ">Friends & Family</p>
          </div>
          <div className="overflow-y-auto max-h-48"> {_renderSubmittedFamilyMembers()} </div>
          {_renderFamilyMembers()}
          <Button onClick={_addMember} className="text-secondary font-thin" type="text">
            + Add Additional Members
          </Button>
        </div>
      </div>
    </>
  );
  const _handleSubmit = (submitEvent: SyntheticEvent<Element, Event>) => {
    const isValid = Object.keys(errors?.individual ?? {}).length === 0;
    if (!isValid) return;

    submitForm(submitEvent);
  };

  return (
    <form
      onReset={handleReset}
      // onSubmit={handleSubmit}
      className={`auth-form-body w-full text-center ${hasFriendsAndFamily ? 'h-formThird' : ''}`}
    >
      <div className="flex flex-col flex-1 w-4/5 overflow-y-auto">
        <p className="auth-heading">Your Details</p>
        {generalError && <FormHelperText error>{generalError}</FormHelperText>}
        {!hasFriendsAndFamily ? (
          <div className="grid gap-4 w-full">
            <OffenderTypeTextField
              values={values}
              icon={<img src="assets/icons/id-card-icon.png" alt="idNumber" />}
              variableName="individual.idNumber"
              variableType="individual.identifierType"
              variableTypes={individualProfileTypes}
              errors={errors}
              touched={touched}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full mb-2"
              placeholder={OffenderIdentifierTypeDescription[values.individual.identifierType]}
            />

            <FormTextField
              inputClassName="rounded-l-none border-l-none"
              placeholder={`Confirm ${
                OffenderIdentifierTypeDescription[values.individual.identifierType]
              }`}
              values={values}
              icon={<img src="assets/icons/id-card-icon.png" alt="confirm IdNumber" />}
              variableName="individual.confirmIdNumber"
              errors={errors}
              touched={touched}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full"
            />
            <div className="flex flex-row">
              <div className="flex flex-1" />
              <Button onClick={_addFriendsAndFamily} className="text-secondary -mt-6" type="text">
                + Add Friends & Family
              </Button>
            </div>
          </div>
        ) : (
          <>{_renderFriendsAndFamilyMembersForm()} </>
        )}
        <div className="flex flex-1" />
        <Button
          isLoading={isSubmitting}
          onClick={_handleSubmit}
          className="auth-form-submit-button mt-2"
        >
          Continue
        </Button>
      </div>
    </form>
  );
};
