import { useQuery, UseQueryResult } from 'react-query';

import finesService from '../../services/api/fines/fines.api.service';
import { IndividualFilterData } from '../../types';

export const useIndividualFilterData = (
  enabled = true,
): UseQueryResult<IndividualFilterData[], unknown> => {
  return useQuery('filterData', finesService.fetchIndividualFilterData, {
    staleTime: Infinity,
    enabled,
  });
};
