import React, { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { FormHelperText } from '@material-ui/core';

import { Button, FormTextField } from '../../atoms';
import { ErrorObject, BaseFormProps, AddFleetEntitiesProps } from '../../../types';
import { addFleetEntityValidationSchema } from '../../../validators';

export const AddFleetEntityForm: React.FC<BaseFormProps<AddFleetEntitiesProps>> = ({
  initialValues,
  submitForm,
  onSuccess,
  onSuccessWithResponse,
}) => {
  const [generalError, setGeneralError] = useState(null);
  const _handleFormSubmitError = (
    error: ErrorObject,
    actions: FormikHelpers<AddFleetEntitiesProps>,
  ) => {
    actions.setSubmitting(false);
    setGeneralError(error.detail);
  };

  const _handleSubmission = (
    formData: AddFleetEntitiesProps,
    actions: FormikHelpers<AddFleetEntitiesProps>,
  ) => {
    submitForm(formData)
      .then((response) => {
        onSuccess();
        onSuccessWithResponse(response);
      })
      .catch((error: ErrorObject) => {
        _handleFormSubmitError(error, actions);
      })
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnBlur
        validateOnChange={false}
        onSubmit={_handleSubmission}
        enableReinitialize
        validationSchema={addFleetEntityValidationSchema}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleReset,
        }) => (
          <form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="flex flex-auto flex-col gap-3 py-1 mx-7"
          >
            <p className="flex justify-center auth-heading text-center">Add New Fleet Entity</p>
            {generalError && <FormHelperText error>{generalError}</FormHelperText>}
            <p className="flex justify-start text-left text-xs">Entity Name:</p>
            <FormTextField
              variableName="entityName"
              placeholder="Entity Name"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />
            <p className="flex justify-start text-left text-xs">Address:</p>
            <FormTextField
              variableName="address"
              placeholder="Address"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />
            <p className="flex justify-start text-left text-xs">Vat number :</p>
            <FormTextField
              variableName="vatNumber"
              placeholder="Vat number"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />
            <p className="flex justify-start text-left text-xs">Company Registration :</p>
            <FormTextField
              variableName="companyRegistration"
              placeholder="Company Registration"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />

            <Button
              isLoading={isSubmitting}
              onClick={handleSubmit}
              className="w-4/5 bg-secondary hover:bg-secondary-dark text-white text-base self-center my-7"
            >
              Add Fleet Entity
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};
