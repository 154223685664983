import config from '../../../config';

const baseUrl = `${config.apiUrl()}/Calls`;
const baseReportsUrl = `${config.apiUrl()}/Reports/Calls`;

export default {
  callsUrl: (): string => baseUrl,
  finishCallUrl: (): string => `${baseUrl}/finish`,
  getCallTotalsContentUrl: (): string => `${baseReportsUrl}/Totals`,
};
