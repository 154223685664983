import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import './internalDateTimePicker.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import _ from 'lodash';

export type InternalDateTimePickerProps = {
  id?: string;
  variableName?: string;
  className?: string;
  classNameOverride?: string;
  onChange: (date: Date) => void;
  onBlur?: (date: Date) => void;
  value: Date;
  showTimeSelect?: boolean;
  defaultToEndOfDay?: boolean;
  businessHoursOnly?: boolean;
  errors?: Record<string, unknown>;
  touchedFields?: Record<string, unknown>;
};

export const InternalDateTimePicker: React.FC<InternalDateTimePickerProps> = ({
  id,
  variableName,
  className,
  classNameOverride,
  onChange,
  onBlur,
  value,
  showTimeSelect,
  defaultToEndOfDay,
  businessHoursOnly,
  errors,
  touchedFields,
}) => {
  const error = _.get(touchedFields, variableName) && !!_.get(errors, variableName);

  const onChangeDateTimePicker = (date: Date) => {
    if (!date) {
      onChange(null);
      return;
    }

    if (defaultToEndOfDay) {
      const endOfDay = new Date(date);
      endOfDay.setHours(23, 59, 59, 999);
      onChange(endOfDay);
      return;
    }

    if (businessHoursOnly) {
      const currentHour = date.getHours();

      if (currentHour < 8) {
        const openingDateTime = new Date(date);
        openingDateTime.setHours(9, 0, 0, 0);
        onChange(openingDateTime);
        return;
      }

      if (currentHour > 16) {
        const closingDateTime = new Date(date);
        closingDateTime.setHours(14, 59, 59, 999);
        onChange(closingDateTime);
        return;
      }
    }

    onChange(date);
  };

  return (
    <DateTimePicker
      id={id}
      className={`${classNameOverride || `w-80 bg-white shadow-lg p-1 ${className}`} 
      ${error ? 'border-red-500' : ''}`}
      onChange={onChangeDateTimePicker}
      onBlur={onBlur}
      value={value}
      dateFormat="y-MM-dd"
      minDate={businessHoursOnly ? new Date() : null}
      showTimeSelect={showTimeSelect}
      showTimeSelectOnly
      timeFormat="HH:mm"
      timeIntervals={15}
      minTime={businessHoursOnly ? 9 : null}
      maxTime={businessHoursOnly ? 16 : null}
      disableClock
    />
  );
};
