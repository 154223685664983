import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { accessTokenOperations, decodedToken } from '../../../services';
import { RouteLayoutProps } from '../../../types';

export const ProtectedRoute: React.FC<RouteLayoutProps> = ({
  component: Component,
  layout: Layout,
  roles,
  ...rest
}) => {
  const isAuthenticated = accessTokenOperations.get();

  if (Component) {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (!isAuthenticated) {
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
          }
          const userRole = decodedToken.getRole();
          if (roles && roles.indexOf(userRole) === -1) {
            return <Redirect to={{ pathname: '/' }} />;
          }
          return (
            <Layout {...props}>
              <Component {...props} />
            </Layout>
          );
        }}
      />
    );
  }
  return null;
};
