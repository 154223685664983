import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

import _ from 'lodash';
import { FormContainer, InitialPasswordForm } from '../../../components';
import { ResetPasswordProps } from '../../../types';
import { userAuthService } from '../../../services';
import { useInitialPasswordToken } from '../../../react-queries';

const InitialPasswordScreen: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [token, setToken] = useState('');
  const getInitialPasswordToken = useInitialPasswordToken(
    new URLSearchParams(location.search).get('email'),
  );

  const onSubmit = (formData: ResetPasswordProps) => {
    const insertToken = { ...formData };
    insertToken.token = token;
    return userAuthService.resetPassword(insertToken);
  };

  const onSuccess = () => {
    history.replace('/login?reset=initialized');
  };

  const initialValues: ResetPasswordProps = {
    password: '',
    confirmPassword: '',
    token: new URLSearchParams(location.search).get('token') || '',
    email: new URLSearchParams(location.search).get('email') || '',
  };

  useEffect(() => {
    getInitialPasswordToken.refetch().then((result) => {
      if (result.isError) {
        toast.error(
          `${_.get(result, 'error.detail', 'Something went wrong, please try again later')}`,
          {
            duration: 30000,
          },
        );
      } else {
        setToken(result.data);
      }
    });
  }, []);

  return (
    <>
      <FormContainer>
        <Toaster position="top-center" />
        <InitialPasswordForm
          initialValues={initialValues}
          submitForm={onSubmit}
          onSuccess={onSuccess}
          onFailure={() => null}
        />
      </FormContainer>
    </>
  );
};

export default InitialPasswordScreen;
