import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  InternalHookFormCheckbox,
  InternalHookFormDateTimePicker,
  InternalHookFormInput,
} from '../../../atoms';
import { InternalSelect } from '../../../atoms/internal-select/internal-select';
import { CallOutcomeFormFields } from '../../../../types';
import { KeyValuePair } from '../../../../types/common.types';
import { callOutcomeFormValidationSchema } from '../../../../validators';

type CallOutcomeFormProps = {
  defaultValues: CallOutcomeFormFields;
  onSubmit: (values: CallOutcomeFormFields) => void;
  callOutcomeSelectValues: KeyValuePair<string>[];
  isSubmitLoading: boolean;
};

export const CallOutcomeForm: React.FC<CallOutcomeFormProps> = ({
  defaultValues,
  onSubmit,
  callOutcomeSelectValues,
  isSubmitLoading,
}) => {
  const {
    handleSubmit,
    register,
    control,
    getValues,
    reset,
    setValue,
    formState: { errors, dirtyFields, touchedFields },
  } = useForm({
    defaultValues,
    resolver: yupResolver(callOutcomeFormValidationSchema),
    mode: 'onTouched',
  });

  const submit = async () => {
    await onSubmit(getValues() as CallOutcomeFormFields);
    reset({}, { keepValues: false });
  };

  useEffect(() => {
    let durationInSeconds = 0;
    const interval = setInterval(() => {
      setValue('durationInSeconds', durationInSeconds);

      durationInSeconds += 1;
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <form className="flex flex-col flex-grow px-3 h-full" onSubmit={handleSubmit(onSubmit)}>
      <label className="flex-row items-center gap-2 mt-2">
        <span className="text-xxs">Call Date & Time</span>
        <InternalHookFormInput
          variableName="callDate"
          register={register}
          className="w-full text-sm"
          disabled
        />
      </label>
      <label className="flex-row items-center gap-2 mt-2">
        <span className="text-xxs">Agent</span>
        <InternalHookFormInput
          variableName="agentFullName"
          register={register}
          className="w-full text-sm"
          disabled
        />
      </label>
      <label className="flex-row items-center gap-2 mt-2">
        <span className="text-xxs w-full">Call Outcome</span>
        <InternalSelect
          variableName="callOutcome"
          options={callOutcomeSelectValues.map((item) => ({ value: item.value, key: item.key }))}
          register={register}
          className="w-full border-2 border-solid text-sm"
        />
      </label>
      {dirtyFields.callBack ? (
        <label className="flex-row items-center gap-2 mt-2">
          <span className="text-xxs">Call-back Date</span>
          <InternalHookFormDateTimePicker
            control={control}
            variableName="callBackDate"
            touchedFields={touchedFields}
            errors={errors}
            classNameOverride="w-full border-2 border-solid px-1 text-sm small-datetime-picker"
            businessHoursOnly
          />
        </label>
      ) : (
        <></>
      )}
      <label className="flex-row items-center gap-2 mt-2">
        <span className="text-xxs">Call Duration</span>
        <InternalHookFormInput
          variableName="durationInSeconds"
          register={register}
          className="w-full text-sm"
          disabled
        />
      </label>
      <div className="flex flex-1 items-end pb-4">
        <div className="flex items-center justify-between gap-2 w-full">
          <Button
            isLoading={isSubmitLoading}
            onClick={handleSubmit(submit)}
            className="bg-primary hover:bg-primary-dark text-white text-xs rounded-sm"
            spinnerSize={10.5}
          >
            Complete Call
          </Button>
          <span className="flex flex-row gap-2">
            <span className="items-center">
              <span className="text-xs">Do not contact</span>
              <InternalHookFormCheckbox control={control} variableName="doNotContact" />
            </span>
            <span className="items-center">
              <span className="text-xs">Call Back</span>
              <InternalHookFormCheckbox control={control} variableName="callBack" />
            </span>
          </span>
        </div>
      </div>
    </form>
  );
};
