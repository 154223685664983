import React, { useMemo, useState } from 'react';
import { FormControlLabel, Checkbox, Card, CardContent } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useQuery } from 'react-query';
import { Spinner } from 'react-activity';
import toast from 'react-hot-toast';
import { Button, SearchField } from '../../../components/atoms';
import {
  FineDetailDto,
  FineStatus,
  FineStatusDisplay,
  PaymentPartnerFineFilterOptions,
} from '../../../types';
import { PaginationButtons } from '../../../components';
import { BasicTable } from '../../../components/table/adminTable/basicTable';
import paymentPartnersApiService from '../../../services/api/payment-partners/payment-partners.api.service';
import { InternalSelect } from '../../../components/atoms/internal-select/internal-select';
import finesApiService from '../../../services/api/fines/fines.api.service';
import { KeyValuePair } from '../../../types/common.types';
import { useDownloadFile } from '../../../hooks/global';
import { dateTimeFormatterGmtZoneOffset } from '../../../helpers';

const availableFilterStatuses = [
  FineStatus.ProvisionallyPaid,
  FineStatus.SettledWithMunicipality,
  FineStatus.PaymentAcknowledgedByServiceProvider,
];

const AdminPaymentPartnersFinesScreen: React.FC = () => {
  const [fineFilterState, setFineFilterState] = useState<PaymentPartnerFineFilterOptions>({
    byNag: false,
    noticeNumber: '',
    pageNum: 1,
    pageEntries: 10,
    invoiceItemCreationReferenceId: null,
    status: FineStatus.ProvisionallyPaid,
  });
  const history = useHistory();

  const fineStatuses = availableFilterStatuses.map((fineStatus) => {
    return {
      key: fineStatus.toString(),
      value: FineStatusDisplay[fineStatus],
    } as KeyValuePair<string>;
  });

  const { data: paymentPartners, isFetching: isFetchingPaymentPartners } = useQuery(
    ['payment-partners'],
    () => paymentPartnersApiService.get(),
    { refetchOnWindowFocus: false },
  );

  const paymentPartnersKeyPairs = paymentPartners?.map((item) => ({
    value: item.displayName,
    key: item.id,
  }));

  const paymentPartnerId = paymentPartners ? paymentPartners[0].id : null;

  if (paymentPartnerId && fineFilterState.invoiceItemCreationReferenceId === null) {
    setFineFilterState({
      ...fineFilterState,
      invoiceItemCreationReferenceId: paymentPartnerId,
    });
  }

  const { data: paginatedFilteredFines, isFetching: isFetchingPaginatedFilteredFines } = useQuery(
    ['payment-partner-fines', fineFilterState],
    () => finesApiService.get(fineFilterState),
    {
      enabled: !!paymentPartnerId,
    },
  );

  const onChangePaymentPartner = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setFineFilterState({
      ...fineFilterState,
      invoiceItemCreationReferenceId: event.target.value,
    });
  };

  const onChangeFineStatus = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setFineFilterState({
      ...fineFilterState,
      status: Number(event.target.value),
    });
  };

  const onChangeNoticeNumber = (value: string): void => {
    setFineFilterState({
      ...fineFilterState,
      noticeNumber: value,
      pageNum: 1,
    });
  };

  const { download, loading } = useDownloadFile({
    apiDefinition: () => finesApiService.getCsv(fineFilterState),
    onError: () => toast.error('Could not download report'),
  });

  const onClickDownloadCsv = (): void => {
    download();
  };

  const setPage = (pageNumber: number): void => {
    setFineFilterState({
      ...fineFilterState,
      pageNum: pageNumber,
    });
  };

  const onClickPayments = (): void => {
    history.push('/admin/payment-partners/payments');
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Notice No',
        accessor: 'noticeNumber',
      },
      {
        Header: 'Reg No',
        accessor: 'vehicleRegistration',
      },
      {
        Header: 'Area',
        accessor: 'area',
      },
      {
        Header: 'Paid Date',
        accessor: (fine: FineDetailDto) =>
          dateTimeFormatterGmtZoneOffset(fine.paidDate.toString(), '/', 2),
      },
      {
        Header: 'Fine Amount',
        accessor: 'fineAmount',
      },
      {
        Header: 'Amount Paid',
        accessor: 'amountPaid',
      },
      {
        Header: 'Discount',
        accessor: 'discount',
      },
      {
        Header: 'Other Fees',
        accessor: 'otherFees',
      },
      {
        Header: 'Total',
        accessor: 'total',
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="mt-2 flex justify-start mx-8 text-xl">Payment Partner Fines</div>
        <div className="flex mr-8 space-x-2 mt-2">
          <Button
            className="bg-tertiary text-white hover:bg-tertiary-dark"
            size="small"
            isLoading={loading}
            onClick={onClickDownloadCsv}
          >
            Download CSV
          </Button>
          <Button
            className="bg-tertiary text-white hover:bg-tertiary-dark"
            size="small"
            onClick={onClickPayments}
          >
            Process Payments
          </Button>
        </div>
      </div>
      <div className="border-b border-black mt-2 flex justify-start mx-8" />
      <div className="flex justify-start mx-8 mt-2 space-x-2">
        <SearchField
          placeholder="Notice Number"
          value={fineFilterState.noticeNumber}
          onChange={onChangeNoticeNumber}
          isLoading={isFetchingPaginatedFilteredFines}
          dependencies={fineFilterState}
        />
        {isFetchingPaymentPartners ? (
          <></>
        ) : (
          <InternalSelect
            className="outline-none rounded-md h-11 shadow-mui"
            options={paymentPartnersKeyPairs}
            onChange={onChangePaymentPartner}
          />
        )}
        <InternalSelect
          className="outline-none rounded-md h-11 shadow-mui"
          options={fineStatuses}
          onChange={onChangeFineStatus}
        />
        <FormControlLabel
          control={
            <Checkbox
              value={fineFilterState.byNag}
              onChange={() =>
                setFineFilterState({
                  ...fineFilterState,
                  byNag: !fineFilterState.byNag,
                  pageNum: 1,
                })
              }
            />
          }
          label="Only NAGs"
        />
      </div>
      <>
        <div className="flex flex-col flex-grow mx-8 mt-2">
          <Card className="flex flex-grow shadow-2xl">
            <CardContent className="flex flex-col flex-grow p">
              {!isFetchingPaginatedFilteredFines && !isFetchingPaymentPartners ? (
                <BasicTable
                  tableData={paginatedFilteredFines.items}
                  tableColumns={columns}
                  path="./payment-partners"
                  isViewable={false}
                />
              ) : (
                <div className="flex items-center justify-center flex-grow">
                  <Spinner />
                </div>
              )}
            </CardContent>
          </Card>
        </div>
        {!isFetchingPaginatedFilteredFines && !isFetchingPaymentPartners ? (
          <PaginationButtons
            page={fineFilterState.pageNum}
            setPage={setPage}
            pageIndex={paginatedFilteredFines.pageIndex}
            totalPages={paginatedFilteredFines.totalPages}
          />
        ) : (
          <div className="h-13" />
        )}
      </>
    </>
  );
};
export default AdminPaymentPartnersFinesScreen;
