import React, { useState, useCallback } from 'react';
import { IconButton, InputBase, Paper, CircularProgress } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import _ from 'lodash';

import { SearchFieldProps } from './type';

export const SearchField: React.FC<SearchFieldProps> = ({
  placeholder,
  onChange,
  className = 'flex mb-1',
  value,
  isLoading,
  isDisabled,
  debounceTime,
  dependencies,
}) => {
  const [search, setSearch] = useState(value);

  const validateInputDebounce = useCallback(
    _.debounce((v) => {
      return onChange(v);
    }, debounceTime || 500),
    [dependencies],
  );

  const onSearchChange = (e: any) => {
    setSearch(e.target.value);
    validateInputDebounce(e.target.value);
  };

  return (
    <Paper className={className}>
      <IconButton disabled={isDisabled}>
        {isLoading ? <CircularProgress size={20} /> : <Search fontSize="small" />}
      </IconButton>
      <InputBase
        value={search}
        placeholder={placeholder}
        className="w-full"
        onChange={onSearchChange}
        disabled={isDisabled}
      />
    </Paper>
  );
};
