import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import _ from 'lodash';
import { InternalInput, InternalInputProps } from '../internal-input/internal-input';
import { FormError } from '../../../types/common.types';

type InternalHookFormInputProps<T> = InternalInputProps & {
  id?: string;
  placeholder?: string;
  register: UseFormRegister<T>;
  variableName: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  className?: string;
  hidden?: boolean;
  errors?: Record<string, unknown>;
};

export const InternalHookFormInput: React.FC<InternalHookFormInputProps<any>> = ({
  id,
  register,
  variableName,
  placeholder,
  onChange,
  onBlur,
  className,
  errors,
  ...rest
}) => {
  const error = _.get(errors, variableName) as FormError;

  return (
    <>
      <InternalInput
        id={id}
        placeholder={placeholder}
        {...register(variableName, {
          onChange: (e) => {
            if (onChange) onChange(e);
          },
          onBlur: (e) => {
            if (onBlur) onBlur(e);
          },
        })}
        className={className}
        {...rest}
      />
      {!!error && <span className="text-xxs text-red-500">{error.message}</span>}
    </>
  );
};
