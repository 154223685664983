import { useQuery, UseQueryResult } from 'react-query';

import { profilesService } from '../../services';
import { AccountProfiles } from '../../types';

export const useAccountProfileDetailData = (
  onSuccess: (result: AccountProfiles) => void = () => null,
): UseQueryResult<AccountProfiles, unknown> => {
  return useQuery(['AccountProfileDetailData'], () => profilesService.profileUserDetail(), {
    onSuccess: (result) => onSuccess(result),
  });
};
