import { ValueType } from 'react-select';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { FineDetailDto } from '../../types';

export interface BusinessProfileState {
  selectedBusinessEntityOption: ValueType<{ label: string; value: string }, false> | null;
  selectedOffenderIdentifiersOptions: ValueType<{ label: string; value: string }, false>[];
  selectedFines: FineDetailDto[] | null;
}

const initialState: BusinessProfileState = {
  selectedBusinessEntityOption: null,
  selectedOffenderIdentifiersOptions: [],
  selectedFines: [],
};

export const businessProfileSlice = createSlice({
  name: 'businessProfileSlice',
  initialState,
  reducers: {
    setSelectedBusinessEntityAction: (
      state,
      action: PayloadAction<ValueType<{ label: string; value: string }, false>>,
    ) => {
      state.selectedBusinessEntityOption = action.payload;
    },
    setSelectedOffenderIdentifiersAction: (
      state,
      action: PayloadAction<ValueType<{ label: string; value: string }, false>[]>,
    ) => {
      state.selectedOffenderIdentifiersOptions = action.payload;
    },
    toggleSelectedFineAction: (state, action: PayloadAction<FineDetailDto>) => {
      if (!action.payload.isPayable) {
        return;
      }
      const index = state.selectedFines.findIndex((fine) => fine.id === action.payload.id);
      if (index !== -1) {
        state.selectedFines.splice(index, 1);
      } else {
        state.selectedFines.push(action.payload);
      }
    },
    setSelectedFinesAction: (state, action: PayloadAction<FineDetailDto[]>) => {
      state.selectedFines = action.payload;
    },
    excludeOffenderIndentifiersFromActiveFines: (state, action: PayloadAction<string[]>) => {
      state.selectedFines = state.selectedFines.filter((s) =>
        action.payload.includes(s.offenderIdentifier),
      );
    },
  },
});

export const {
  setSelectedBusinessEntityAction,
  setSelectedOffenderIdentifiersAction,
  toggleSelectedFineAction,
  setSelectedFinesAction,
  excludeOffenderIndentifiersFromActiveFines,
} = businessProfileSlice.actions;

export const businessProfileSelector = (state: RootState) => state.businessProfileReducer;

export default businessProfileSlice.reducer;
