import React, { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { FormHelperText } from '@material-ui/core';
import { Button, FormTextField } from '../../atoms';
import { BaseFormProps } from '../../../types';
import { editCallCenterAgentSchema } from '../../../validators';
import { ClientNetworkError, ServerNetworkError } from '../../../exceptions';

export type EditCallCenterAgentFormProps = {
  userId: string;
  extension: string;
};

export const EditCallCenterAgentForm: React.FC<BaseFormProps<EditCallCenterAgentFormProps>> = ({
  initialValues,
  submitForm,
  onSuccess,
}) => {
  const [generalError, setGeneralError] = useState(null);

  const _handleFormSubmitError = (
    error: ClientNetworkError | ServerNetworkError,
    actions: FormikHelpers<EditCallCenterAgentFormProps>,
  ) => {
    actions.setSubmitting(false);
    if (error instanceof ClientNetworkError) {
      setGeneralError(error.serviceResultError.message);
    }
  };

  const _handleSubmission = (
    formData: EditCallCenterAgentFormProps,
    actions: FormikHelpers<EditCallCenterAgentFormProps>,
  ) => {
    submitForm(formData)
      .then(() => {
        onSuccess();
      })
      .catch((error: ClientNetworkError | ServerNetworkError) => {
        _handleFormSubmitError(error, actions);
      })
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnBlur
        validateOnChange={false}
        onSubmit={_handleSubmission}
        enableReinitialize
        validationSchema={editCallCenterAgentSchema}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleReset,
        }) => (
          <form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="flex flex-auto flex-col gap-3 py-1 mx-7"
          >
            <p className="flex justify-center auth-heading text-center">Edit Call Center Agent</p>
            {generalError && <FormHelperText error>{generalError}</FormHelperText>}

            <p className="flex justify-start text-left text-xs">Extension :</p>
            <FormTextField
              variableName="extension"
              placeholder="Extension"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />

            <Button
              isLoading={isSubmitting}
              onClick={handleSubmit}
              className="w-4/5 bg-secondary hover:bg-secondary-dark text-white text-base self-center my-7"
            >
              Save
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};
