import _ from 'lodash';

import formUrlEncoded from 'form-urlencoded';
import { AxiosResponse } from 'axios';
import { parsePhoneNumber } from 'libphonenumber-js';
import authUrls from './user-auth.urls';
import authUtils from './user-auth.utils';
import networkService from '../network/network.service';
import {
  ForgotPasswordProps,
  LoginProps,
  OnboardBusinessProps,
  OnboardIndividualProps,
  ResetPasswordProps,
  SignUpProps,
  VerifyAccountProps,
  VerifyNewEmailProps,
  ServiceResult,
  Utm,
  SignUpReferralProps,
} from '../../../types';
import { SignUpV2Dto, SignUpV2FleetDto, SignUpV2IndividualDto } from './types';

const config = {
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const login = (formData: LoginProps) => {
  const loginUrl = authUrls.tokenUrl();
  const oAuthData = authUtils.constructOAuthSignInData(formData);

  return networkService
    .post(loginUrl, formUrlEncoded(oAuthData), config)
    .then(authUtils.storeAccessAndRefreshTokens);
};

const signUp = (formData: SignUpProps & Utm) => {
  const signUpUrl = authUrls.signUpUrl();
  return networkService.post(signUpUrl, formData);
};

const mapFleet = (fleet: OnboardBusinessProps): SignUpV2FleetDto | null => {
  if (fleet.businessEntities.length === 0) {
    return null;
  }
  if (fleet.businessEntities.length !== 0 && fleet.businessEntities[0].entityName === '') {
    return null;
  }

  return {
    fleetEntities: fleet.businessEntities.map((be) => ({
      name: be.entityName,
      address: be.address,
      companyRegistrationNumber: be.companyRegistration,
      offenderIdentifiers: be.links.map((l) => ({
        value: l.value,
        type: l.identifierType,
      })),
      vatNumber: be.vatNo,
    })),
  };
};

const mapIndividual = (individual: OnboardIndividualProps): SignUpV2IndividualDto | null => {
  if (individual.idNumber === '' || individual.idNumber === null) {
    return null;
  }
  return {
    offenderIdentifier: {
      type: individual.identifierType,
      value: individual.idNumber,
    },
    familyMembers: individual.familyMembers
      .filter((s) => s.idNumber !== '' && s.firstName !== '' && s.lastName !== '')
      .map((s) => ({
        firstName: s.firstName,
        lastName: s.lastName,
        offenderIdentifier: {
          type: s.identifierType,
          value: s.idNumber,
        },
      })),
  };
};

const signUpv2 = (
  accountInfo: SignUpProps,
  individual: OnboardIndividualProps,
  fleet: OnboardBusinessProps,
  utm: Utm,
  referral: SignUpReferralProps,
) => {
  const signUpUrl = authUrls.signUpV2();

  const payload: SignUpV2Dto = {
    firstName: accountInfo.firstName,
    lastName: accountInfo.lastName,
    email: accountInfo.email,
    password: accountInfo.password,
    confirmPassword: accountInfo.confirmPassword,
    cellphoneNumber: parsePhoneNumber(
      `+${accountInfo.code}${accountInfo.cellphone}`,
    ).formatInternational(),
    termsChecked: accountInfo.termsChecked,
    whatsAppOptedIn: accountInfo.whatsAppOptedIn,
    profiles: {
      individual: mapIndividual(individual),
      fleet: mapFleet(fleet),
    },
    utmSource: utm.utmSource,
    utmMedium: utm.utmMedium,
    utmCampaign: utm.utmCampaign,
    utmContent: utm.utmContent,
    utmTerm: utm.utmTerm,
    referrerEmail: referral.referrerEmail,
    referralEmail: referral.referralEmail,
  };

  return networkService.post(signUpUrl, payload);
};

const canSignUp = (email: string) => {
  const canSignUpUrl = authUrls.canSignUpUrl();
  return networkService.get(canSignUpUrl, {
    params: {
      email,
    },
  });
};

const verifyAccount = (formData: VerifyAccountProps) => {
  const verifyAccountUrl = authUrls.verifyAccountUrl();
  return networkService.post(verifyAccountUrl, formData);
};

const verifyNewEmail = (formData: VerifyNewEmailProps) => {
  const verifyNewEmailUrl = authUrls.verifyNewEmailUrl();
  return networkService.post(verifyNewEmailUrl, formData);
};

const logout = () => {
  return authUtils.removeAccessAndRefreshTokens();
};

const forgotPassword = (formData: ForgotPasswordProps) => {
  const forgotPasswordUrl = authUrls.forgotPasswordUrl();
  return networkService.post(forgotPasswordUrl, formData).catch((err) => {
    return Promise.reject(err);
  });
};

const resetPassword = (formData: ResetPasswordProps) => {
  const resetPasswordUrl = authUrls.resetPasswordUrl();
  return networkService.post(resetPasswordUrl, formData).catch((err) => {
    return Promise.reject(err);
  });
};

const doTokensExistInStorage = () => {
  const _trueIfBothExist = (accessToken: string | null, refreshToken: string | null) =>
    !_.isNull(accessToken) && !_.isNull(refreshToken);

  return authUtils
    .getAccessAndRefreshTokens()
    .then(([accessToken, refreshToken]) => _trueIfBothExist(accessToken, refreshToken));
};

const unlock = (token: string) => {
  const forgotPasswordUrl = authUrls.unlockAccountUrl();
  return networkService.post(forgotPasswordUrl, token).catch((err) => {
    return Promise.reject(err);
  });
};

const getTokenForInitialPassword = (email: string) => {
  const initialPasswordURL = `${authUrls.initialPasswordUrl()}?Email=${email}`;
  return networkService
    .get(initialPasswordURL)
    .then((apiResponse: AxiosResponse<ServiceResult<string>>) => apiResponse.data.data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  login,
  logout,
  verifyAccount,
  verifyNewEmail,
  signUp,
  forgotPassword,
  resetPassword,
  doTokensExistInStorage,
  unlock,
  getTokenForInitialPassword,
  canSignUp,
  signUpv2,
};
