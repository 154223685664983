import config from '../../../config';
import { Guid } from '../../../types';

const apiV1Url = config.apiUrl();
const baseUrl = `${apiV1Url}/BackOffice/Consultants`;

export default {
  deleteConsultantUrl: (consultantId: Guid): string => `${baseUrl}/${consultantId}`,
  getConsultantUrl: (pageNum, pageEntries, search) =>
    `${baseUrl}?PageNumber=${pageNum}&PageEntries=${pageEntries}&Search=${search}`,
};
