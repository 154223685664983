import { Checkbox } from '@material-ui/core';
import React, { ChangeEvent, FocusEventHandler } from 'react';

type InternalCheckboxProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onBlur: FocusEventHandler<HTMLButtonElement>;
  value: boolean;
  className?: string;
  classNameOverride?: string;
};

export const InternalCheckbox: React.FC<InternalCheckboxProps> = ({
  onChange,
  onBlur,
  value,
  className,
  classNameOverride,
  ...rest
}) => {
  return (
    <Checkbox
      checked={value === true}
      onChange={onChange}
      onBlur={onBlur}
      className={`${classNameOverride || `w-6 h-6 ${className}`}`}
      {...rest}
    />
  );
};
