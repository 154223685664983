import { AxiosResponse } from 'axios';
import * as qs from 'qs';
import { ReportFilterProps } from '../../../types';
import offendersUrls from './offenders.urls';
import authNetworkService from '../auth-network/auth-network.service';

export const downloadSignUpReport = (
  filterProps?: ReportFilterProps,
): Promise<AxiosResponse<Blob>> => {
  const url = offendersUrls.baseContentUrl();

  return authNetworkService.get(url, {
    responseType: 'blob',
    timeout: 0,
    headers: { Accept: 'application/csv' },
    params: {
      fromDate: filterProps.fromDate,
      toDate: filterProps.toDate,
      attributes: ['FirstName', 'LastName', 'IdentificationNumber', 'CreatedDate'],
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  });
};

export default {
  getSignUpsReport: downloadSignUpReport,
};
