import { AxiosResponse } from 'axios';
import { ServiceResult } from '../../../types';
import authNetworkService from '../auth-network/auth-network.service';
import paymentsUrls from './payments.urls';

const getEftPaymentQuote = (fineIds: string[]): Promise<string> => {
  const url = paymentsUrls.eftPaymentUrl();

  return authNetworkService
    .post(url, { fineIds })
    .then((apiResponse: AxiosResponse<ServiceResult<string>>) => apiResponse.data.data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getGatewayPaymentQuote = (fineIds: string[]): Promise<string> => {
  const url = paymentsUrls.gatewayPaymentUrl();

  return authNetworkService
    .post(url, { fineIds })
    .then((apiResponse: AxiosResponse<ServiceResult<string>>) => apiResponse.data.data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getBusinessGatewayPayment = (fineIds: string[]): Promise<string> => {
  const url = paymentsUrls.gatewayBusinessPaymentUrl();
  const dataObj = { identificationNumbers: fineIds };

  return authNetworkService
    .post(url, dataObj)
    .then((apiResponse: AxiosResponse<ServiceResult<string>>) => apiResponse.data.data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getBusinessEftPaymentQuote = (fineIds: string[]): Promise<string> => {
  const url = paymentsUrls.eftBusinessPaymentUrl();

  return authNetworkService
    .post(url, { fineIds })
    .then((apiResponse: AxiosResponse<ServiceResult<string>>) => apiResponse.data.data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getGatewayPayment = (invoiceId: string, source: number): Promise<string> => {
  const url = paymentsUrls.gatewayPaymentRedirectUrl();

  return authNetworkService
    .post(url, { invoiceId, source })
    .then((apiResponse: AxiosResponse<ServiceResult<string>>) => apiResponse.data.data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  getEftPaymentQuote,
  getGatewayPaymentQuote,
  getBusinessGatewayPayment,
  getBusinessEftPaymentQuote,
  getGatewayPayment,
};
