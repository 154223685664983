import React from 'react';

type Props = {
  className?: string;
};
export const FormContainer: React.FC<Props> = ({ children, className }) => {
  return (
    <>
      <div className={`w-full rounded-3xl bg-form-background lg:w-4/12 xs:w-5/12  ${className}`}>
        {children}
      </div>
    </>
  );
};
