import config from '../../../config';

const apiV1Url = config.apiUrl();
const paymentsUrl = `${apiV1Url}/Payments`;

export default {
  eftPaymentUrl: (): string => `${paymentsUrl}/eft-payment-individual`,
  gatewayPaymentUrl: (): string => `${paymentsUrl}/gateway-payment-individual`,
  gatewayBusinessPaymentUrl: (): string => `${paymentsUrl}/gateway-payment-fleet`,
  eftBusinessPaymentUrl: (): string => `${paymentsUrl}/eft-payment-fleet`,
  paymentStatus: (): string => `${paymentsUrl}/order-status`,
  gatewayPaymentRedirectUrl: (): string => `${apiV1Url}/Admin/Payments`,
};
