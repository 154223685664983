import { useMutation, useQueryClient } from 'react-query';

import toast from 'react-hot-toast';
import { adminFineService } from '../../services';

export const useSyncProfileFines = (userId: string) => {
  const queryClient = useQueryClient();
  return useMutation('syncProfileFines', () => adminFineService.syncProfileFinesUrl(userId), {
    onSuccess: () => {
      queryClient.invalidateQueries('finesIndividualLinkedData');
      queryClient.invalidateQueries('finesEntityLinkedData');
      queryClient.invalidateQueries('paged-profile-fines');
    },
    onError: () => {
      toast.error(
        "An error occurred syncing the user's profile. Please try again later and if the issue persists contact your IT administrator.",
      );
    },
  });
};
