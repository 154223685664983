import { profilesService } from '../../services';
import { UserInfo } from '../../types/profile.types';
import { OnboardBusinessProps, OnboardIndividualProps } from '../../types';
import { AppThunk } from '../store';
import { setLoadingAction, setUserAction } from './user.reducer';

export const getUserAction =
  (user: UserInfo): AppThunk =>
  (dispatch) => {
    return dispatch(setUserAction(user));
  };

export const onboardIndividualAction =
  (formData: OnboardIndividualProps): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingAction(true));
    return profilesService
      .onboardIndividual(formData)
      .then(() => dispatch(setLoadingAction(false)));
  };

export const onboardBusinessAction =
  (FormData: OnboardBusinessProps): AppThunk =>
  (dispatch) => {
    dispatch(setLoadingAction(true));
    return profilesService.onboardBusiness(FormData).then(() => dispatch(setLoadingAction(false)));
  };
