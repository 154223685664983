import { useQuery } from 'react-query';

import { profilesService, userAuthService } from '../../services';

export const useUserInfo = () =>
  useQuery('userInfo', profilesService.userInfo, {
    staleTime: Infinity,
  });

export const useInitialPasswordToken = (email) => {
  return useQuery(
    ['initialPasswordToken', email],
    () => userAuthService.getTokenForInitialPassword(email),
    {
      enabled: false,
      retry: false,
    },
  );
};

export const useSyncTmTFines = () => {
  return useQuery(['syncFines'], () => profilesService.syncFines(), {
    enabled: false,
    retry: false,
  });
};
