import React from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useDownloadFile } from '../../../../hooks/global';
import statementRunService from '../../../../services/api/statement-run/statement-run.service';
import { InternalSelect } from '../../../atoms/internal-select/internal-select';
import { KeyValuePair } from '../../../../types/common.types';
import { Button } from '../../../atoms';

type StatementRunStatementsSentReportFormProps = {
  defaultValues: {
    statementRunId: string;
  };
  selectValues: KeyValuePair<string>[];
};

export const StatementRunStatementsSentReportForm: React.FC<StatementRunStatementsSentReportFormProps> =
  ({ defaultValues, selectValues }) => {
    const { statementRunId } = defaultValues;
    const { register, getValues } = useForm({
      defaultValues: { statementRunId },
    });

    const { download, loading } = useDownloadFile({
      apiDefinition: () => {
        const formValues = getValues();
        return statementRunService.downloadStatementRunStatementsCreated(formValues.statementRunId);
      },
      onError: () => toast.error('Could not download report'),
    });

    return (
      <form className="flex flex-col justify-start items-start bg-gray-100 shadow-lg p-4 gap-4">
        <span className="w-full text-xl mb-2">Download Statement Run - Statements</span>

        <InternalSelect
          variableName="statementRunId"
          options={selectValues.map((item) => ({ value: item.value, key: item.key }))}
          register={register}
        />

        <Button
          isLoading={loading}
          onClick={download}
          className="bg-tertiary hover:bg-tertiary-dark text-white text-base self-center w-auto"
        >
          Download
        </Button>
      </form>
    );
  };
