import { EditProfileFleetEntityAndLinkProps } from '../../../types';
import authNetworkService from '../auth-network/auth-network.service';
import fleetEntitiesUrls from './fleet-entities.urls';

const addProfileFleetEntities = (formData: EditProfileFleetEntityAndLinkProps) => {
  const url = fleetEntitiesUrls.addProfileFleetEntitiesUrl();
  return authNetworkService.post(url, formData);
};

const editUserFleetEntities = (
  fleetEntityId: string,
  formData: EditProfileFleetEntityAndLinkProps,
) => {
  const url = fleetEntitiesUrls.editUserFleetEntities(fleetEntityId);
  return authNetworkService.put(url, formData).catch((error) => {
    return Promise.reject(error);
  });
};

export default { addProfileFleetEntities, editUserFleetEntities };
