import axios, { AxiosResponse } from 'axios';
import signUpAttemptUrls from './sign-up-attempt.urls';

export const create = (
  email: string,
  utmSource: string,
  utmMedium?: string,
  utmCampaign?: string,
  utmContent?: string,
  utmTerm?: string,
  gclid?: string,
): Promise<AxiosResponse<void>> => {
  const url = signUpAttemptUrls.baseUrl();
  const dto = {
    email,
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
    utmTerm,
    gclid,
  };

  return axios.post(url, dto);
};

export default { create };
