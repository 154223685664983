import React from 'react';
import { Formik, FormikHelpers } from 'formik';

import { Button, FormTextField } from '../../atoms';
import { resetPasswordValidation } from '../../../validators';
import { ErrorObject, BaseFormProps, ResetPasswordProps } from '../../../types';

export const InitialPasswordForm: React.FC<BaseFormProps<ResetPasswordProps>> = ({
  initialValues,
  submitForm,
  onSuccess,
  onFailure,
}) => {
  const _handleFormSubmitError = (
    error: ErrorObject,
    actions: FormikHelpers<ResetPasswordProps>,
    formData: ResetPasswordProps,
  ) => {
    actions.setSubmitting(false);
    const apiErrors = error.errors;
    actions.resetForm({ values: formData, status: { apiErrors } });
    actions.setFieldError('email', error.message);
  };

  const _handleSubmission = (
    formData: ResetPasswordProps,
    actions: FormikHelpers<ResetPasswordProps>,
  ) => {
    submitForm(formData)
      .then(() => {
        actions.setSubmitting(false);
        onSuccess();
      })
      .catch((error: ErrorObject) => {
        _handleFormSubmitError(error, actions, formData);
        onFailure();
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={resetPasswordValidation}
      validateOnBlur
      validateOnChange={false}
      onSubmit={_handleSubmission}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleReset,
      }) => (
        <form onReset={handleReset} onSubmit={handleSubmit} className="auth-form-body">
          <p className="auth-heading">Set-up Password</p>
          <p className="auth-sub-heading">
            Welcome to FinesSA Portal - Please enter a password that will be used with your account.
          </p>
          <FormTextField
            variableName="password"
            placeholder="Password"
            type="password"
            className="w-4/5 self-center"
            values={values}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched}
            errors={errors}
            icon={<img src="assets/icons/lock-icon.png" alt="mail" />}
          />
          <FormTextField
            variableName="confirmPassword"
            placeholder="Confirm Password"
            type="password"
            className="w-4/5 self-center"
            values={values}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched}
            errors={errors}
            icon={<img src="assets/icons/lock-icon.png" alt="mail" />}
          />
          <Button
            isLoading={isSubmitting}
            onClick={handleSubmit}
            className="auth-form-submit-button"
          >
            Create Password
          </Button>
        </form>
      )}
    </Formik>
  );
};
