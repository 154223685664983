import config from '../../../config';

const baseUrl = `${config.apiUrl()}/Users`;
const baseBackOfficeUrl = `${config.apiUrl()}/admin/Users`;

export default {
  getBaseUrl: () => `${baseUrl}`,
  getDeletePortalUserUrl: (userId?: string) => `${baseUrl}/${userId}`,
  extensionUrl: (userId: string) => `${baseUrl}/${userId}/Extension`,
  getUserResourceUrl: (userId: string) => `${baseUrl}/${userId}`,

  baseContentUrl: (): string => `${baseUrl}/Content`,

  getUserUrl: () => `${baseBackOfficeUrl}/current`,
};
