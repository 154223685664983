import { AxiosResponse } from 'axios';
import * as qs from 'qs';
import authNetworkService from '../auth-network/auth-network.service';
import referralsUrls from './referrals.urls';
import { ServiceResult, CreateReferral, Referral, Guid } from '../../../types';

const createReferral = (formData: CreateReferral) => {
  const url = referralsUrls.referralsUrl();
  return authNetworkService.post(url, formData).catch((error) => {
    return Promise.reject(error);
  });
};

const createBulkReferrals = (referrals: CreateReferral[], utmSource: string) => {
  const url = referralsUrls.referralsBulkUrl();
  return authNetworkService
    .post(url, { createReferralRequestDtos: referrals, utmSource })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const cancelReferrals = (invoiceId: Guid) => {
  const url = referralsUrls.cancelReferralsUrl();
  return authNetworkService.post(url, { invoiceId }).catch((error) => {
    return Promise.reject(error);
  });
};

const getAllReferrals = async (): Promise<Array<Referral>> => {
  const url = referralsUrls.referralsUrl();
  const response = await authNetworkService.get<ServiceResult<Array<Referral>>>(url);
  return response.data.data;
};

export const getAllReferralsContent = (
  fromDate?: string,
  toDate?: string,
): Promise<AxiosResponse<Blob>> => {
  const url = referralsUrls.backofficeGetReferralsContentUrl();

  return authNetworkService.get(url, {
    responseType: 'blob',
    timeout: 0,
    headers: { Accept: 'application/csv' },
    params: {
      fromDate,
      toDate,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  });
};

const referralStatusMapArray = [
  { enum: '0', value: 'Sent', class: 'bg-disabled' },
  { enum: '1', value: 'Pending', class: 'bg-amber' },
  { enum: '2', value: 'Accepted', class: 'bg-total-green' },
  { enum: '3', value: 'Processing', class: 'bg-total-green' },
  { enum: '4', value: 'Claimed', class: 'bg-secondary' },
  { enum: '5', value: 'Referred', class: 'bg-tertiary' },
];

export default {
  createReferral,
  createBulkReferrals,
  cancelReferrals,
  getAllReferrals,
  getAllReferralsContent,
  referralStatusMapArray,
};
