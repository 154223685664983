import { OffenderIdentifier } from '../../../types';
import { OffenderIdentifierDto } from '../../../types/data-transfer-objects/dto.types';

export type SignUpV2Individual = {
  offenderIdentifier: OffenderIdentifier;
  familyMembers: SignUpV2FamilyMember[];
};

export type SignUpV2IndividualDto = {
  offenderIdentifier: OffenderIdentifierDto;
  familyMembers: SignUpV2FamilyMemberDto[];
};

export type SignUpV2FleetEntities = {
  name: string;
  address: string;
  vatNumber: string;
  companyRegistrationNumber: string;
  offenderIdentifiers: OffenderIdentifier[];
};

export type SignUpV2FleetEntitiesDto = {
  name: string;
  address: string;
  vatNumber: string;
  companyRegistrationNumber: string;
  offenderIdentifiers: OffenderIdentifierDto[];
};

export type SignUpV2Fleet = {
  fleetEntities: SignUpV2FleetEntities[];
};

export type SignUpV2FleetDto = {
  fleetEntities: SignUpV2FleetEntitiesDto[];
};

export type SignUpV2FamilyMember = {
  firstName: string;
  lastName: string;
  offenderIdentifier: OffenderIdentifier;
};

export type SignUpV2FamilyMemberDto = {
  firstName: string;
  lastName: string;
  offenderIdentifier: OffenderIdentifierDto;
};

export type SignUpV2Dto = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  cellphoneNumber: string;
  termsChecked: boolean;
  profiles: SignUpV2ProfilesDto;
  whatsAppOptedIn: boolean;
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmContent: string;
  utmTerm: string;
  referrerEmail: string;
  referralEmail: string;
};

export type SignUpV2ProfilesDto = {
  individual: SignUpV2IndividualDto | null;
  fleet: SignUpV2FleetDto | null;
};

export enum CorrespondenceType {
  Email = 0,
  WhatsApp = 1,
}
