import { Button, SwipeableDrawer } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-hot-toast';
import { EditableTable } from '../../table/editableTable/editableTable';
import { AddFleetEntityLinkProps, EditFleetEntityLinksProps, Guid, Links } from '../../../types';
import { AddFleetEntityLinkForm } from '../../forms/add-fleet-entity-link-form/add-fleet-entity-link-form';
import { EditFleetEntityLinkForm } from '../../forms/edit-fleet-entity-links-form/edit-fleet-entity-link-form';
import adminFleetEntityLinksService from '../../../services/api/admin-fleet-entity-links/admin-fleet-entity-links.service';
import { OffenderIdentifierType, OffenderIdentifierTypeDescription } from '../../../enums';

const linksProxyCol = [
  {
    Header: 'Type',
    accessor: (link) => OffenderIdentifierTypeDescription[link.identifierType],
    minWidth: 120,
  },
  {
    Header: 'ID',
    accessor: 'value',
    minWidth: 120,
  },
  {
    Header: 'Sync',
    accessor: 'sync',
    minWidth: 20,
  },
];

export const BusinessEntityLinks = ({
  businessEntityLinks,
  entityCount,
  activeBusinessEntityId,
}: {
  businessEntityLinks: Links[];
  entityCount: number;
  activeBusinessEntityId: Guid;
}) => {
  const queryClient = useQueryClient();
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);

  const _addFleetEntityLink = (formData: AddFleetEntityLinkProps) => {
    return adminFleetEntityLinksService.addFleetEntityLink(formData);
  };

  const addFleetEntityLinkInitialValues: AddFleetEntityLinkProps = {
    fleetEntityId: activeBusinessEntityId,
    identifierType: OffenderIdentifierType.Brn,
    value: '',
  };

  const deleteFleetEntityLink = (fleetEntityLinkId: string) => {
    if (fleetEntityLinkId != null) {
      adminFleetEntityLinksService.deleteFleetEntityLink(fleetEntityLinkId).then(() => {
        queryClient.invalidateQueries('AdminAccountProfileData');
      });
    }
  };

  const editFail = () => {
    toast.error('Error adding family member');
  };

  const [fleetEntityLinkTableData, setFleetEntityLinkTableData] = useState<any>();

  const EditFleetEntitylinkInitialValues: EditFleetEntityLinksProps = {
    id: fleetEntityLinkTableData?.id,
    identifierType: fleetEntityLinkTableData?.identifierType,
    value: fleetEntityLinkTableData?.value,
  };

  const _editFleetEntityLink = (formData: EditFleetEntityLinksProps) => {
    return adminFleetEntityLinksService.editFleetEntityLinks(
      fleetEntityLinkTableData?.id,
      formData,
    );
  };
  const toggleFleetEntityLinkTableData = (open: boolean, rowData) => {
    setEditOpen(true);
    setFleetEntityLinkTableData(rowData);
  };

  return (
    <>
      <div className="flex flex-col rounded-md h-56 shadow-md">
        <div className="flex bg-secondary rounded-t-md h-16 p-4 flex-row justify-between items-center font-bold text-white">
          <p className="font-bold text-white font-sans text-base">BRN / Proxy</p>
          <Button
            className={
              entityCount >= 1 ? 'bg-tertiary text-white hover:bg-tertiary-dark' : 'bg-gray-400'
            }
            size="small"
            startIcon={<AddCircleOutline />}
            onClick={() => setAddOpen(true)}
            disabled={entityCount <= 0}
          >
            Add
          </Button>
        </div>

        {businessEntityLinks.length > 0 ? (
          <div className="flex p-1 overflow-auto text-xs">
            <EditableTable
              columns={linksProxyCol}
              data={businessEntityLinks}
              deleteData={deleteFleetEntityLink}
              isViewable
              isDeleteAble
              isFleetEntityLinkTable
              toggleDrawer={toggleFleetEntityLinkTableData}
            />
          </div>
        ) : (
          <div className="p-1 overflow-auto text-xs">No linked entities were found</div>
        )}
      </div>

      <SwipeableDrawer
        anchor="right"
        open={addOpen}
        onClose={() => setAddOpen(false)}
        onOpen={() => setAddOpen(true)}
        className="w-96"
      >
        <AddFleetEntityLinkForm
          initialValues={addFleetEntityLinkInitialValues}
          submitForm={_addFleetEntityLink}
          onSuccess={() => {
            queryClient.invalidateQueries('AdminAccountProfileData');
            setAddOpen(false);
          }}
          onFailure={editFail}
        />
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="right"
        open={editOpen}
        onClose={() => setEditOpen(false)}
        onOpen={() => setEditOpen(true)}
        className="w-96"
      >
        <EditFleetEntityLinkForm
          initialValues={EditFleetEntitylinkInitialValues}
          submitForm={_editFleetEntityLink}
          onSuccess={() => {
            queryClient.invalidateQueries('AdminAccountProfileData');
            setEditOpen(false);
          }}
          onFailure={editFail}
        />
      </SwipeableDrawer>
    </>
  );
};
